import type { RouteProps } from "react-router-dom"
import { lazyWithPreload as lazy } from "@phonero/common-ux"

import { RouteKeys } from "../routes"
const costTransferPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'CostTransferPage' */ "../pages/usage/CostTransferPage"
    )
)

export const routeComponents: Record<
  RouteKeys,
  RouteProps["component"] & {
    preload?: () => Promise<RouteProps["component"]>
  }
> = {
  paymentCallbackVipps: lazy(
    () =>
      import(
        /* webpackChunkName: 'paymentCallbackVipps' */ "../pages/paymentCallback/Vipps/PaymentCallbackVipps"
      )
  ),
  contact: lazy(
    () => import(/* webpackChunkName: 'Contact' */ "../pages/contact")
  ),
  playground: lazy(
    () =>
      import(
        /* webpackChunkName: 'playground' */ "../pages/playground/PlagroundPage"
      )
  ),
  welcome: lazy(
    () =>
      import(
        /* webpackChunkName: 'SplashScreen' */ "../pages/welcome/SplashScreen"
      )
  ),
  subscription: lazy(
    () =>
      import(
        /* webpackChunkName: 'SubscriptionPage' */ "../pages/subscription/SubscriptionPage"
      )
  ),
  subscriptionPrimarySimCardPage: lazy(
    () =>
      import(
        /* webpackChunkName: 'SubscriptionPrimarySimCardPage' */ "../pages/subscription/SubscriptionPrimarySimCardPage"
      )
  ),
  subscriptionDataSimCardPage: lazy(
    () =>
      import(
        /* webpackChunkName: 'SubscriptionDataSimCardPage' */ "../pages/subscription/SubscriptionDataSimCardPage"
      )
  ),
  subscriptionTwinSimCardPage: lazy(
    () =>
      import(
        /* webpackChunkName: 'SubscriptionTwinSimCardPage' */ "../pages/subscription/SubscriptionTwinSimCardPage"
      )
  ),
  settings: lazy(
    () =>
      import(
        /* webpackChunkName: 'SettingsPage' */ "../pages/settings/SettingsPage"
      )
  ),
  subscriptionServices: lazy(
    () =>
      import(
        /* webpackChunkName: 'ServicesPage' */ "../pages/services/ServicesPage"
      )
  ),
  subscriptionUsage: lazy(
    () => import(/* webpackChunkName: 'UsagePage' */ "../pages/usage/UsagePage")
  ),
  costTransferPage,
  costTransferArchive: costTransferPage,
  costTransferArchiveMonthPage: lazy(
    () =>
      import(
        /* webpackChunkName: 'CostTransferArchiveMonthPage' */ "../pages/usage/CostTransferArchive/CostTransferArchiveMonth/CostTransferArchiveMonthPage"
      )
  ),

  costTransferArchiveMonthDetailPage: lazy(
    () =>
      import(
        /* webpackChunkName: 'CostTransferArchiveMonthDetailPage' */ "../pages/usage/CostTransferArchive/CostTransferArchiveMonth/CostTransferArchiveMonthDetailPage"
      )
  ),

  costTransferPeriodMonthDetailPage: lazy(
    () =>
      import(
        /* webpackChunkName: 'CostTransferPeriodMonthDetailPage' */ "../pages/usage/CostTransferPeriodMonthDetailPage"
      )
  ),

  subscriptionActivity: lazy(
    () =>
      import(
        /* webpackChunkName: 'ActivityPage' */ "../pages/activity/ActivityPage"
      )
  ),
  myReceipts: lazy(
    () =>
      import(
        /* webpackChunkName: 'ActivityPage' */ "../pages/receipts/MyReceiptsPage"
      )
  ),
  receipt: lazy(
    () =>
      import(
        /* webpackChunkName: 'ActivityPage' */ "../pages/receipts/ReceiptPage"
      )
  ),
  subscriptionRequests: lazy(
    () =>
      import(
        /* webpackChunkName: 'RequestsPage' */ "../pages/requests/ApprovalPage"
      )
  ),
  home: lazy(
    () => import(/* webpackChunkName: 'HomePage' */ "../pages/home/HomePage")
  ),
  noSubscriptionPage: lazy(
    () =>
      import(
        /* webpackChunkName: 'SubscriptionPage' */ "../pages/welcome/NoSubscriptionPage"
      )
  ),
}

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string | number; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string | Date; output: string | Date; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: number; output: number; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
  /** The multiplier path scalar represents a valid GraphQL multiplier path string. */
  MultiplierPath: { input: any; output: any; }
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: { input: any; output: any; }
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: { input: any; output: any; }
  Url: { input: any; output: any; }
  Uuid: { input: any; output: any; }
};

export type ActivateSimCardInput = {
  id: Scalars['ID']['input'];
};

export type AddBarringInput = {
  productId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type AddCostControlInput = {
  productId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type AddCostSharingUserInput = {
  /** ContractProductId for CostSharingProfile that the Employee will be related to. */
  contractProductProfileId: Scalars['ID']['input'];
  /** This relates to 'Lønnsart', known as SalaryCode in Portal */
  costCode?: InputMaybe<Scalars['String']['input']>;
  /** This relates to 'Ansatt nr', known as EmployeeCode in Portal */
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  /** ContractProductId for MobileSubscription product */
  subscriptionId: Scalars['ID']['input'];
};

export type AddDataRolloverInput = {
  productId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};

export type AddDataUsageNotificationInput = {
  emailAddress: Scalars['String']['input'];
  productId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};

export type AddExtraDataPackageInput = {
  payUsingVipps: Scalars['Boolean']['input'];
  productId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type AddFamilyBucketSubscriptionInput = {
  customerId: Scalars['ID']['input'];
  dataProductId: Scalars['ID']['input'];
  familyDescription: Scalars['String']['input'];
  mobileSubscriptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  notificationEmail?: InputMaybe<Array<Scalars['String']['input']>>;
  offeringId: Scalars['Int']['input'];
};

export type AddFamilyBucketUserInput = {
  familyBucketSubscriptionId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type AddMbnUserInput = {
  mbnSolutionContractId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type AddMobileInsuranceInput = {
  productId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};

export type AddMobileSubscriptionInput = {
  address?: InputMaybe<SimCardAddressInput>;
  alias: Scalars['String']['input'];
  bindingLengthId?: InputMaybe<BindingLength>;
  customerId: Scalars['ID']['input'];
  dataCards?: InputMaybe<Array<AddMobileSubscriptionSimCardInput>>;
  embeddedSimEmail?: InputMaybe<Scalars['String']['input']>;
  keepExistingSubscriptionSimCard: Scalars['Boolean']['input'];
  mbnSolutionContractId?: InputMaybe<Scalars['Int']['input']>;
  msisdn?: InputMaybe<Scalars['String']['input']>;
  msisdnLockKey?: InputMaybe<Scalars['String']['input']>;
  numberType: NumberType;
  offeringId: Scalars['ID']['input'];
  opplysningenReservation: Scalars['Boolean']['input'];
  portingBirthday?: InputMaybe<Scalars['DateTime']['input']>;
  portingOrganizatonNumber?: InputMaybe<Scalars['String']['input']>;
  portingUsername?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<MobileSubscriptionProductInput>>;
  requestedActivationDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  simWithoutPin?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionSimCard?: InputMaybe<AddMobileSubscriptionSimCardInput>;
  twinCard?: InputMaybe<AddMobileSubscriptionSimCardInput>;
};

export type AddMobileSubscriptionSimCardInput = {
  embeddedSim?: InputMaybe<Scalars['Boolean']['input']>;
  iccid?: InputMaybe<Scalars['String']['input']>;
};

export type AddRoamLikeAtHomeExtensionInput = {
  productId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};

export type AddSimCardInput = {
  address: SimCardAddressInput;
  cardType: SimCardType2;
  embeddedSim?: InputMaybe<Scalars['Boolean']['input']>;
  embeddedSimEmail?: InputMaybe<Scalars['String']['input']>;
  iccid?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type AddUnlimitedMinutesInput = {
  productId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};

export type Address = {
  __typename: 'Address';
  billing?: Maybe<CustomerAddress>;
  equipment?: Maybe<CustomerAddress>;
  location?: Maybe<CustomerAddress>;
};

export type Agreement = {
  __typename: 'Agreement';
  bindingLength: BindingLength;
  bindingMonths: Scalars['Int']['output'];
  /** Identifies the customer that owns the agreement, available for stitching purposes. */
  customerId: Scalars['ID']['output'];
  customerSet?: Maybe<FilteredCustomerLiteSet>;
  flagActive: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isPublicAgreement: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  offerings?: Maybe<Array<Offering>>;
};


export type AgreementOfferingsArgs = {
  filter?: InputMaybe<OfferingFilterInput>;
};

export enum ApiContractType {
  Costsharing = 'COSTSHARING',
  Familybucket = 'FAMILYBUCKET',
  Mbnnordicom = 'MBNNORDICOM',
  Mobile = 'MOBILE',
  Mobilebroadband = 'MOBILEBROADBAND',
  Siptrunkmetaswitch = 'SIPTRUNKMETASWITCH'
}

export type ApiContractTypeOperationFilterInput = {
  eq?: InputMaybe<ApiContractType>;
  in?: InputMaybe<Array<ApiContractType>>;
  neq?: InputMaybe<ApiContractType>;
  nin?: InputMaybe<Array<ApiContractType>>;
};

export enum ApiProductCategory {
  Autopackage = 'AUTOPACKAGE',
  Barring = 'BARRING',
  Costcontrol = 'COSTCONTROL',
  Costsharinguser = 'COSTSHARINGUSER',
  Data = 'DATA',
  Datarollover = 'DATAROLLOVER',
  Datausagenotification = 'DATAUSAGENOTIFICATION',
  Debit = 'DEBIT',
  Extradata = 'EXTRADATA',
  Familybucketdata = 'FAMILYBUCKETDATA',
  Familybucketsubscription = 'FAMILYBUCKETSUBSCRIPTION',
  Familybucketupgradefee = 'FAMILYBUCKETUPGRADEFEE',
  Familybucketuser = 'FAMILYBUCKETUSER',
  Mbnuser = 'MBNUSER',
  Mobileinsurance = 'MOBILEINSURANCE',
  Mobilesubscription = 'MOBILESUBSCRIPTION',
  None = 'NONE',
  Roamlikeathomeextensions = 'ROAMLIKEATHOMEEXTENSIONS',
  Sim = 'SIM',
  Siptrunkcallforwardnumber = 'SIPTRUNKCALLFORWARDNUMBER',
  Siptrunkcallforwardprofile = 'SIPTRUNKCALLFORWARDPROFILE',
  Siptrunkmbnuser = 'SIPTRUNKMBNUSER',
  Siptrunknumberseries = 'SIPTRUNKNUMBERSERIES',
  Siptrunksubscription = 'SIPTRUNKSUBSCRIPTION',
  Trafficincludedonsubscription = 'TRAFFICINCLUDEDONSUBSCRIPTION',
  Unlimitedminutes = 'UNLIMITEDMINUTES',
  Voicemail = 'VOICEMAIL'
}

export enum ApiProductState {
  Activating = 'ACTIVATING',
  Active = 'ACTIVE',
  Available = 'AVAILABLE',
  Deactivating = 'DEACTIVATING',
  Deactive = 'DEACTIVE',
  Error = 'ERROR',
  Ordered = 'ORDERED',
  Startactivation = 'STARTACTIVATION'
}

export type AppState = {
  __typename: 'AppState';
  i18nextLng?: Maybe<Scalars['String']['output']>;
  rememberCustomer?: Maybe<Scalars['String']['output']>;
  splitPaneDisabled: Scalars['Boolean']['output'];
};

export type ApplicationLink = {
  __typename: 'ApplicationLink';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type Approval = {
  __typename: 'Approval';
  approved?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use Approved */
  approvedByDate: Scalars['DateTime']['output'];
  /** @deprecated Use Approved */
  approvedDate: Scalars['DateTime']['output'];
  assigned?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use Assigned */
  assignedByDate: Scalars['DateTime']['output'];
  /** @deprecated Use Assigned */
  assignedDate: Scalars['DateTime']['output'];
  cancelled?: Maybe<Scalars['DateTime']['output']>;
  contact?: Maybe<CustomerContact>;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  order: OrderDetail;
  person?: Maybe<Person>;
  processed?: Maybe<Scalars['DateTime']['output']>;
  processedBy?: Maybe<Scalars['String']['output']>;
  rejected?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use Rejected */
  rejectedByDate: Scalars['DateTime']['output'];
  /** @deprecated Use Rejected */
  rejectedDate: Scalars['DateTime']['output'];
  reminderSent?: Maybe<Scalars['DateTime']['output']>;
  requestMessage?: Maybe<Scalars['String']['output']>;
  responseMessage?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ApprovalStatusId>;
};


export type ApprovalPersonArgs = {
  where?: InputMaybe<PersonFilterInput>;
};

/** A connection to a list of items. */
export type ApprovalConnection = {
  __typename: 'ApprovalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ApprovalEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Approval>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApprovalEdge = {
  __typename: 'ApprovalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Approval>;
};

export type ApprovalFilterInput = {
  and?: InputMaybe<Array<ApprovalFilterInput>>;
  approved?: InputMaybe<StringFilterTypeFilterInput>;
  assigned?: InputMaybe<StringFilterTypeFilterInput>;
  cancelled?: InputMaybe<StringFilterTypeFilterInput>;
  id?: InputMaybe<StringFilterTypeFilterInput>;
  or?: InputMaybe<Array<ApprovalFilterInput>>;
  rejected?: InputMaybe<StringFilterTypeFilterInput>;
  /** Filter by status of the order */
  status?: InputMaybe<ApprovalStatusIdOperationFilterInput>;
};

export type ApprovalSortInput = {
  approved?: InputMaybe<SortEnumType>;
  approvedByDate?: InputMaybe<SortEnumType>;
  approvedDate?: InputMaybe<SortEnumType>;
  assigned?: InputMaybe<SortEnumType>;
  assignedByDate?: InputMaybe<SortEnumType>;
  assignedDate?: InputMaybe<SortEnumType>;
  cancelled?: InputMaybe<SortEnumType>;
  contactId?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  personId?: InputMaybe<SortEnumType>;
  processed?: InputMaybe<SortEnumType>;
  processedBy?: InputMaybe<SortEnumType>;
  rejected?: InputMaybe<SortEnumType>;
  rejectedByDate?: InputMaybe<SortEnumType>;
  rejectedDate?: InputMaybe<SortEnumType>;
  reminderSent?: InputMaybe<SortEnumType>;
  requestMessage?: InputMaybe<SortEnumType>;
  responseMessage?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export enum ApprovalStatusId {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type ApprovalStatusIdOperationFilterInput = {
  eq?: InputMaybe<ApprovalStatusId>;
  in?: InputMaybe<Array<ApprovalStatusId>>;
  neq?: InputMaybe<ApprovalStatusId>;
  nin?: InputMaybe<Array<ApprovalStatusId>>;
};

export type ApproveOrderInput = {
  approvalId: Scalars['Int']['input'];
  responseMessage?: InputMaybe<Scalars['String']['input']>;
};

export type Approver = {
  __typename: 'Approver';
  contact?: Maybe<CustomerContact>;
  contactId?: Maybe<Scalars['ID']['output']>;
  customerId: Scalars['ID']['output'];
  msisdn?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['ID']['output']>;
};

export type AutoPackage = IProduct & IService & {
  __typename: 'AutoPackage';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureActivationDate?: Maybe<Scalars['DateTime']['output']>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  size: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type AutoPackageOfferingProduct = IProduct & OfferingProduct & {
  __typename: 'AutoPackageOfferingProduct';
  activeOn?: Maybe<RelatedSubscriptionConnection>;
  available?: Maybe<RelatedSubscriptionConnection>;
  billingProductId: Scalars['Int']['output'];
  canDeactivateOn?: Maybe<RelatedSubscriptionConnection>;
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  flagUsageModify: Scalars['Boolean']['output'];
  flagUsageNew: Scalars['Boolean']['output'];
  freeOccurrences: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  maxOccurrences?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  offeringId: Scalars['ID']['output'];
  packageSize?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
  upload: Scalars['Int']['output'];
};


export type AutoPackageOfferingProductActiveOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AutoPackageOfferingProductAvailableArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AutoPackageOfferingProductCanDeactivateOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AutoPackageProduct = IProduct & {
  __typename: 'AutoPackageProduct';
  billingProductId: Scalars['Int']['output'];
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
  subscriptions?: Maybe<Scalars['String']['output']>;
  upload: Scalars['Int']['output'];
};

export type Barring = IProduct & IService & {
  __typename: 'Barring';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type BarringProduct = IProduct & {
  __typename: 'BarringProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export enum BarringZone {
  Africa = 'AFRICA',
  Asia = 'ASIA',
  Caribbean = 'CARIBBEAN',
  Europe = 'EUROPE',
  Mcp = 'MCP',
  NorthAmerica = 'NORTH_AMERICA',
  Oceania = 'OCEANIA',
  /** @deprecated rSone is only valid for SAPC */
  RSone1 = 'R_SONE1',
  /** @deprecated rSone is only valid for SAPC */
  RSone2 = 'R_SONE2',
  /** @deprecated rSone is only valid for SAPC */
  RSone3 = 'R_SONE3',
  /** @deprecated rSone is only valid for SAPC */
  RSone4 = 'R_SONE4',
  SouthAmerica = 'SOUTH_AMERICA',
  Usa = 'USA'
}

export type BatchConfirmation = {
  __typename: 'BatchConfirmation';
  batchId: Scalars['Uuid']['output'];
  orderConfirmations?: Maybe<Array<Maybe<OrderConfirmation>>>;
};

/** Represents the customer that is billed for a subscription. */
export type BillingCustomer = {
  __typename: 'BillingCustomer';
  /** The ID of the customer that is billed for the subscription. */
  id?: Maybe<Scalars['String']['output']>;
  /** Whether the billing customer is identical to the customer who owns the contract. */
  isOwner: Scalars['Boolean']['output'];
  /** The name of the customer that is billed for the subscription. */
  name?: Maybe<Scalars['String']['output']>;
};

export enum BindingLength {
  Eightteenmonths = 'EIGHTTEENMONTHS',
  Fourtyeightmonths = 'FOURTYEIGHTMONTHS',
  Ninemonths = 'NINEMONTHS',
  None = 'NONE',
  Onemonth = 'ONEMONTH',
  Seventytwomonths = 'SEVENTYTWOMONTHS',
  Sixmonths = 'SIXMONTHS',
  Sixtymonths = 'SIXTYMONTHS',
  Thirtysixmonths = 'THIRTYSIXMONTHS',
  Threemonths = 'THREEMONTHS',
  Twelvemonths = 'TWELVEMONTHS',
  Twentyfivemonths = 'TWENTYFIVEMONTHS',
  Twentyfourmonths = 'TWENTYFOURMONTHS',
  Twentysixmonths = 'TWENTYSIXMONTHS',
  Twomonths = 'TWOMONTHS'
}

export type BoolFilterTypeFilterInput = {
  and?: InputMaybe<Array<BoolFilterTypeFilterInput>>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<BoolFilterTypeFilterInput>>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrregEntry = {
  __typename: 'BrregEntry';
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  organizationNumber: Scalars['ID']['output'];
  zipCode: Scalars['String']['output'];
};

export type ByteUsage = {
  __typename: 'ByteUsage';
  remainingBytes: Scalars['Decimal']['output'];
  remainingGigaBytes: Scalars['Decimal']['output'];
  remainingMegaBytes: Scalars['Decimal']['output'];
  reservedBytes: Scalars['Decimal']['output'];
  reservedGigaBytes: Scalars['Decimal']['output'];
  reservedMegaBytes: Scalars['Decimal']['output'];
  sizeBytes: Scalars['Decimal']['output'];
  sizeGigaBytes: Scalars['Decimal']['output'];
  sizeMegaBytes: Scalars['Decimal']['output'];
  usedBytes: Scalars['Decimal']['output'];
  usedGigaBytes: Scalars['Decimal']['output'];
  usedMegaBytes: Scalars['Decimal']['output'];
};

export enum CallForwardSource {
  Allintrunk = 'ALLINTRUNK',
  None = 'NONE',
  Profile = 'PROFILE',
  Single = 'SINGLE'
}

export enum CallForwardTrigger {
  Always = 'ALWAYS',
  Noanswer = 'NOANSWER'
}

export type CancelDeactivateServiceInput = {
  id: Scalars['ID']['input'];
};

export type CancelPaymentError = ECommerceError | FieldRequiredError | InvalidArgumentError | InvalidVippsIdError | NotFoundOrNoAccessError | PreconditionNotMetError;

export type CancelPaymentInput = {
  paymentId: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
};

export type CancelPaymentPayload = {
  __typename: 'CancelPaymentPayload';
  errors?: Maybe<Array<CancelPaymentError>>;
  paymentDetails?: Maybe<PaymentDetails>;
};

export type ChangeAliasInput = {
  newAlias?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeCostSharingUserInput = {
  /** ContractProductId for existing CostSharingUser product. */
  contractProductId: Scalars['ID']['input'];
  /** ContractProductId for CostSharingProfile that the Employee will be related to. */
  contractProductProfileId: Scalars['ID']['input'];
  /** This relates to 'Lønnsart', known as SalaryCode in Portal. */
  costCode?: InputMaybe<Scalars['String']['input']>;
  /** This relates to 'Ansatt nr', known as EmployeeCode in Portal. */
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  /** ContractProductId for MobileSubscription product. */
  subscriptionId: Scalars['ID']['input'];
};

export type ChangeDataPackageInput = {
  approverId?: InputMaybe<Scalars['Int']['input']>;
  contactId?: InputMaybe<Scalars['Int']['input']>;
  currentContractProductId?: InputMaybe<Scalars['Int']['input']>;
  currentProductId: Scalars['ID']['input'];
  requestMessage?: InputMaybe<Scalars['String']['input']>;
  requestedOfferingId?: InputMaybe<Scalars['ID']['input']>;
  requestedProductId: Scalars['ID']['input'];
  requestedProductPriceId: Scalars['Int']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type ChangeDataUsageNotificationInput = {
  contractProductId: Scalars['Int']['input'];
  emailAddress?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeDataUsageNotificationsInput = {
  emailAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ChangeDirectoryInformationInput = {
  company?: InputMaybe<CompanyDirectoryInput>;
  kind: DirectoryEntryType;
  overrideCustomerSetting: Scalars['Boolean']['input'];
  personal?: InputMaybe<PersonalDirectoryInput>;
  reservation: DirectoryEntryReservationScope;
  subscriptionId: Scalars['ID']['input'];
};

export type ChangeExpenseInput = {
  /** List of items to change */
  changeExpenseItems: Array<ChangeExpenseItemInput>;
  mobileSubscriptionId?: InputMaybe<Scalars['String']['input']>;
};

/** Update expense from 'DittPhonero' or 'DinBedrift', expense can't be ReadOnly */
export type ChangeExpenseItemInput = {
  /** From 'DittPhonero' this updated comment (from Owner). From 'DinBedrift' this updated CompanyComment. MAX 500 characters. */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Change who covers this expense, covered by 'PRIVATE' or 'COMPANY' */
  coveredBy?: InputMaybe<CoveredBy>;
  id: Scalars['ID']['input'];
};

export type ChangeExpenseItemsResponse = {
  __typename: 'ChangeExpenseItemsResponse';
  /** Returns employee expenses by Ids */
  employeeExpenses?: Maybe<Array<Maybe<CostSharingEmployeeExpense>>>;
  /** Returns own expenses by Ids */
  expensesByIds?: Maybe<Array<Maybe<Expense>>>;
  /** Number of items that has been updated */
  modifiedCount: Scalars['Int']['output'];
};


export type ChangeExpenseItemsResponseEmployeeExpensesArgs = {
  keys: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type ChangeExpenseItemsResponseExpensesByIdsArgs = {
  expenseIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type ChangeFamilyBucketDataInput = {
  familyBucketSubscriptionId: Scalars['ID']['input'];
  newDataProductId: Scalars['ID']['input'];
};

export type ChangeFamilyBucketSubscriptionInput = {
  dataUsageNotifications?: InputMaybe<ChangeDataUsageNotificationsInput>;
  familyBucketSubscriptionId: Scalars['ID']['input'];
  familyName?: InputMaybe<ChangeFamilyNameInput>;
};

export type ChangeFamilyNameInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeOfferingInput = {
  approverId?: InputMaybe<Scalars['Int']['input']>;
  contactId?: InputMaybe<Scalars['Int']['input']>;
  currentContractProductId?: InputMaybe<Scalars['Int']['input']>;
  currentProductId: Scalars['Int']['input'];
  offeringId: Scalars['ID']['input'];
  requestMessage?: InputMaybe<Scalars['String']['input']>;
  requestedProductId: Scalars['ID']['input'];
  requestedProductPriceId: Scalars['Int']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type ChangeSipTrunkInput = {
  /** Unique identifier (ContractProductId) for the SipTrunk contract product */
  sipTrunkId: Scalars['ID']['input'];
  /** Name of the SipTrunk */
  sipTrunkName: Scalars['String']['input'];
};

export type ChangeSubscriptionInput = {
  aliasChange?: InputMaybe<ChangeAliasInput>;
  dataUsageNotificationChange?: InputMaybe<ChangeDataUsageNotificationInput>;
  subscriptionId: Scalars['Int']['input'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type CompanyCost = {
  __typename: 'CompanyCost';
  mobileSubscriptionId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  type: CostMapping;
  when: Scalars['DateTime']['output'];
};

export type CompanyDirectoryEntry = IStreetAddress & {
  __typename: 'CompanyDirectoryEntry';
  companyName: Scalars['String']['output'];
  houseLetter?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  organizationNumber: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  postalPlace?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userLastName?: Maybe<Scalars['String']['output']>;
  userMiddleName?: Maybe<Scalars['String']['output']>;
};

export type CompanyDirectoryInput = {
  address: StreetAddressInput;
  companyName: Scalars['String']['input'];
  organizationNumber: Scalars['String']['input'];
  userFirstName?: InputMaybe<Scalars['String']['input']>;
  userLastName?: InputMaybe<Scalars['String']['input']>;
  userMiddleName?: InputMaybe<Scalars['String']['input']>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComparableDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ComparableFilterTypeFilterInput = {
  and?: InputMaybe<Array<ComparableFilterTypeFilterInput>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nlte?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<ComparableFilterTypeFilterInput>>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<Scalars['Int']['input']>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComparableOrderStatusIdOperationFilterInput = {
  eq?: InputMaybe<OrderStatusId>;
  gt?: InputMaybe<OrderStatusId>;
  gte?: InputMaybe<OrderStatusId>;
  in?: InputMaybe<Array<OrderStatusId>>;
  lt?: InputMaybe<OrderStatusId>;
  lte?: InputMaybe<OrderStatusId>;
  neq?: InputMaybe<OrderStatusId>;
  ngt?: InputMaybe<OrderStatusId>;
  ngte?: InputMaybe<OrderStatusId>;
  nin?: InputMaybe<Array<OrderStatusId>>;
  nlt?: InputMaybe<OrderStatusId>;
  nlte?: InputMaybe<OrderStatusId>;
};

export type ComparableOrderTypeOperationFilterInput = {
  eq?: InputMaybe<OrderType>;
  gt?: InputMaybe<OrderType>;
  gte?: InputMaybe<OrderType>;
  in?: InputMaybe<Array<OrderType>>;
  lt?: InputMaybe<OrderType>;
  lte?: InputMaybe<OrderType>;
  neq?: InputMaybe<OrderType>;
  ngt?: InputMaybe<OrderType>;
  ngte?: InputMaybe<OrderType>;
  nin?: InputMaybe<Array<OrderType>>;
  nlt?: InputMaybe<OrderType>;
  nlte?: InputMaybe<OrderType>;
};

export type ConsumptionGroup = {
  __typename: 'ConsumptionGroup';
  activated?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  isSelected: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ContractProductModelDataLoaderKey = {
  __typename: 'ContractProductModelDataLoaderKey';
  contractPriceTypeId: Scalars['Short']['output'];
  contractTypeId: ContractType;
  customerAgreementOfferingId?: Maybe<Scalars['Int']['output']>;
  productSetId: ProductSetId;
};

export enum ContractType {
  Audioconferencing = 'AUDIOCONFERENCING',
  Carrierpreselect = 'CARRIERPRESELECT',
  Costsharing = 'COSTSHARING',
  Cpa = 'CPA',
  Customerspecific = 'CUSTOMERSPECIFIC',
  /** @deprecated No longer supported. */
  Directaccess = 'DIRECTACCESS',
  Externalservices = 'EXTERNALSERVICES',
  Familybucket = 'FAMILYBUCKET',
  Fixedwirelessaccess = 'FIXEDWIRELESSACCESS',
  Frameworkagreement = 'FRAMEWORKAGREEMENT',
  Freephone = 'FREEPHONE',
  Gdprretention = 'GDPRRETENTION',
  Insurance = 'INSURANCE',
  Internet = 'INTERNET',
  Ipnordicom = 'IPNORDICOM',
  Ipsip = 'IPSIP',
  Mbn = 'MBN',
  Mbnnordicom = 'MBNNORDICOM',
  Mobile = 'MOBILE',
  Mobilebroadband = 'MOBILEBROADBAND',
  Mobilepresence = 'MOBILEPRESENCE',
  Mobileresale = 'MOBILERESALE',
  Numberporting = 'NUMBERPORTING',
  Portreserved = 'PORTRESERVED',
  Resale = 'RESALE',
  /** @deprecated Gammel SIP-trunk løsning som benyttet CS2K */
  Siptrunk = 'SIPTRUNK',
  Siptrunkmetaswitch = 'SIPTRUNKMETASWITCH',
  Siptrunknordicom = 'SIPTRUNKNORDICOM',
  Teamsvoicenordicom = 'TEAMSVOICENORDICOM',
  Telemetri = 'TELEMETRI',
  Virtualcallforwarding = 'VIRTUALCALLFORWARDING',
  Voicevpn = 'VOICEVPN',
  Voip = 'VOIP',
  Warehouse = 'WAREHOUSE',
  Webshop = 'WEBSHOP'
}

export type ContractUnionType = CostSharingContract | FamilyBucketContract | GeneralContract | MbnNordicomContract | MobileBroadbandContract | MobileContract | SipTrunkContract;

/** A connection to a list of items. */
export type ContractUnionTypeConnection = {
  __typename: 'ContractUnionTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContractUnionTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ContractUnionType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ContractUnionTypeEdge = {
  __typename: 'ContractUnionTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ContractUnionType>;
};

export type CostControl = IProduct & IService & {
  __typename: 'CostControl';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  /**
   * If subscription is configured for CostSharing this the identifier for the profile.
   * @deprecated CostSharing products should use the cost sharing service/product classes.
   */
  costSharingProfileId?: Maybe<Scalars['ID']['output']>;
  /**
   * If subscription is configured for CostSharing this the name of the profile.
   * @deprecated CostSharing products should use the cost sharing service/product classes.
   */
  costSharingProfileName?: Maybe<Scalars['String']['output']>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  /** @deprecated Use IsMandatory */
  isRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type CostControlProduct = IProduct & {
  __typename: 'CostControlProduct';
  category: ApiProductCategory;
  /** If subscription is configured for CostSharing this the identifier for the profile. */
  costSharingProfileId?: Maybe<Scalars['ID']['output']>;
  /** If subscription is configured for CostSharing this the name of the profile. */
  costSharingProfileName?: Maybe<Scalars['String']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type CostDirective = {
  __typename: 'CostDirective';
  complexity: Scalars['Int']['output'];
  defaultMultiplier?: Maybe<Scalars['Int']['output']>;
  multipliers?: Maybe<Array<Scalars['MultiplierPath']['output']>>;
};

export enum CostMapping {
  All = 'ALL',
  Product = 'PRODUCT',
  Traffic = 'TRAFFIC'
}

export type CostSet = {
  __typename: 'CostSet';
  /** Returns a list of costs */
  costs?: Maybe<Array<Maybe<CompanyCost>>>;
  /** Returns a list of costs */
  costsIncludingPrivate?: Maybe<Array<Maybe<ICost>>>;
  customerIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  customerSet?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  from: Scalars['DateTime']['output'];
  mappingType: CostMapping;
  paidBy?: Maybe<Array<PaidBy>>;
  /** Returns the sum object for all costs */
  sum?: Maybe<Price>;
  to: Scalars['DateTime']['output'];
};

export type CostSetInterval = {
  __typename: 'CostSetInterval';
  costSets?: Maybe<Array<Maybe<CostSet>>>;
  customerId?: Maybe<Scalars['String']['output']>;
  whens?: Maybe<Array<Scalars['DateTime']['output']>>;
};

export type CostSetMobileSubscription = {
  __typename: 'CostSetMobileSubscription';
  amount: Scalars['Decimal']['output'];
  amountIncVAT: Scalars['Decimal']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  mobileSubscription?: Maybe<MobileSubscription>;
  mobileSubscriptionId: Scalars['Int']['output'];
  number?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CostSetMobileSubscriptionConnection = {
  __typename: 'CostSetMobileSubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CostSetMobileSubscriptionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CostSetMobileSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CostSetMobileSubscriptionEdge = {
  __typename: 'CostSetMobileSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CostSetMobileSubscription>;
};

export type CostSetMobileSubscriptionFilterInput = {
  amount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  amountIncVAT?: InputMaybe<ComparableDecimalOperationFilterInput>;
  and?: InputMaybe<Array<CostSetMobileSubscriptionFilterInput>>;
  customerId?: InputMaybe<StringOperationFilterInput>;
  mobileSubscriptionId?: InputMaybe<ComparableInt32OperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CostSetMobileSubscriptionFilterInput>>;
};

export type CostSetMobileSubscriptionSortInput = {
  amount?: InputMaybe<SortEnumType>;
  amountIncVAT?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  mobileSubscriptionId?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
};

export type CostSharingAddProfileInput = {
  /** CompanyId is also known as Id on Solution (Company = Solution) */
  companyId: Scalars['ID']['input'];
  costSharingRules: CostSharingCostSharingRulesInput;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationSettingsInput>;
  /** Whether or not employees are disallowed reporting on content services expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyContentServicesExpenses: Scalars['Boolean']['input'];
  /** Whether or not employees are disallowed reporting on extradata expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyExtraDataExpenses: Scalars['Boolean']['input'];
  /** Whether or not employees are disallowed reporting on traffic expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyTrafficExpenses: Scalars['Boolean']['input'];
};

export type CostSharingChangeProfileInput = {
  costSharingRules: CostSharingCostSharingRulesInput;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationSettingsInput>;
  /** Whether or not employees are disallowed reporting on content services expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyContentServicesExpenses: Scalars['Boolean']['input'];
  /** Whether or not employees are disallowed reporting on extradata expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyExtraDataExpenses: Scalars['Boolean']['input'];
  /** Whether or not employees are disallowed reporting on traffic expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyTrafficExpenses: Scalars['Boolean']['input'];
};

export enum CostSharingCompareOperator {
  /** Greater than */
  GreaterThan = 'GREATER_THAN',
  /** Greater than or equal */
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  /** Less than */
  LessThan = 'LESS_THAN',
  /** Less than or equal */
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL'
}

export enum CostSharingContentCoverage {
  CompanyCoversAll = 'COMPANY_COVERS_ALL',
  CompanyCoversNothing = 'COMPANY_COVERS_NOTHING',
  CompanyCoversTax = 'COMPANY_COVERS_TAX',
  CompanyCoversX = 'COMPANY_COVERS_X'
}

export type CostSharingContentService = {
  __typename: 'CostSharingContentService';
  number: Scalars['String']['output'];
};

export type CostSharingContentServiceInput = {
  number?: InputMaybe<Scalars['String']['input']>;
};

export type CostSharingContentServices = {
  __typename: 'CostSharingContentServices';
  coverage: CostSharingContentCoverage;
  customerId: Scalars['String']['output'];
  limit: Scalars['Decimal']['output'];
  limitRange: CostSharingLimitRange;
  services: Array<Maybe<CostSharingContentService>>;
};

export type CostSharingContentServicesInput = {
  coverage: CostSharingContentCoverage;
  limit: Scalars['Decimal']['input'];
  services: Array<InputMaybe<CostSharingContentServiceInput>>;
};

export type CostSharingContract = IContract & {
  __typename: 'CostSharingContract';
  contractId: Scalars['Int']['output'];
  contractType: ApiContractType;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainContractProductId?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<CostSharingSubscription>;
};

export type CostSharingCostSharingRulesInput = {
  contentServices: CostSharingContentServicesInput;
  extraDataDomestic: CostSharingExtraDataInput;
  extraDataInternational: CostSharingExtraDataInput;
  trafficRules: CostSharingTrafficRulesInput;
};

export type CostSharingCountry = {
  __typename: 'CostSharingCountry';
  alpha2: Scalars['String']['output'];
  alpha3: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomer = {
  __typename: 'CostSharingCustomer';
  /** Search and sort employees to find related expenses */
  customerExpenses: CostSharingCustomerExpenseSet;
  /** Returns list of department (current and children) relative to this customer/department */
  departments: Array<CostSharingDepartmentLite>;
  /** Returns active employee that belongs to this customer or it children. */
  employee?: Maybe<CostSharingEmployee>;
  /** Expenses available from a company context (can't see detailed private expenses) as a list */
  employeeExpenses?: Maybe<CostSharingEmployeeExpenseCollectionSegment>;
  employeeSalaryCodes: Array<Scalars['String']['output']>;
  employees?: Maybe<EmployeesConnection>;
  /** Employees by offset, this is a temporary implementation and should replace employees */
  employeesByOffset?: Maybe<CostSharingEmployeeCollectionSegment>;
  /** Gives a list of expenses and how they are distributed between 'below limit', 'covered by company' or 'covered by private owner'. Currently this is mock data! */
  expenseDistribution: Array<Maybe<CostSharingExpenseDistributionPeriod>>;
  /** Gives a list of categories and how expenses are distributed between the categories. */
  expenseDistributionByCategory: Array<Maybe<CostSharingExpenseDistributionCategoryPeriod>>;
  id: Scalars['ID']['output'];
  report?: Maybe<CostSharingDataFile>;
  reportGraph?: Maybe<CostSharingReportContainer>;
  /** List of months that have been reported on, limited to last three years */
  reportableMonths?: Maybe<Array<Maybe<Month>>>;
  solution?: Maybe<CostSharingSolution>;
  solutions?: Maybe<SolutionsConnection>;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerCustomerExpensesArgs = {
  input: CostSharingCustomerExpenseInput;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerDepartmentsArgs = {
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  withMobileSubscriptions?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerEmployeeArgs = {
  input: EmployeeKeyInput;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerEmployeeExpensesArgs = {
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Array<EmployeeExpenseSortInput>>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: CostSharingEmployeeExpenseWhereInput;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerEmployeeSalaryCodesArgs = {
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CostSharingEmployeeSort>>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  solutionId: Scalars['ID']['input'];
  where?: InputMaybe<CostSharingEmployeeWhereInput>;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerEmployeesByOffsetArgs = {
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Array<CostSharingEmployeeSort>>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  solutionId: Scalars['ID']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CostSharingEmployeeWhereInput>;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerExpenseDistributionArgs = {
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  inputParams: CostSharingExpenseDistributionInput;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerExpenseDistributionByCategoryArgs = {
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  inputParams: CostSharingExpenseDistributionInput;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerReportArgs = {
  exportParams: CostSharingExportParams;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerReportGraphArgs = {
  exportParams: CostSharingReportGraphParams;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerSolutionArgs = {
  solutionId: Scalars['ID']['input'];
};


/** This object provides customer level queries for CostSharing data. */
export type CostSharingCustomerSolutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Parameters for search and filter on related employees */
export type CostSharingCustomerExpenseInput = {
  /** Refers to start of Invoice month, (refers to first day in month at UTC 0, example '2022-06-01T00:00:00.000Z') */
  fromDate: Scalars['DateTime']['input'];
  /** Include employees from sub customers */
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search filter for employees */
  searchString?: InputMaybe<Scalars['String']['input']>;
  /** Refers to end of Invoice month, (refers to last day in month at UTC 0, example '2022-06-30T23:59:59.999Z') */
  toDate: Scalars['DateTime']['input'];
  /** Conditional filter for employees */
  where?: InputMaybe<CostSharingEmployeeWhereInput>;
};

/** Expenses for a set of employee */
export type CostSharingCustomerExpenseSet = {
  __typename: 'CostSharingCustomerExpenseSet';
  /** Sum of private and company expenses covered by company due to being below profile limit */
  belowLimitSum: Scalars['Decimal']['output'];
  /** Sum of expenses covered by company (in addition to expenses below limit) */
  companySum: Scalars['Decimal']['output'];
  /** List of employees with expenses */
  employeeExpenses?: Maybe<CostSharingEmployeeExpenseSetCollectionSegment>;
  isModifiedByCompany: Scalars['Boolean']['output'];
  /** Sum of expenses covered by private */
  privateSum: Scalars['Decimal']['output'];
};


/** Expenses for a set of employee */
export type CostSharingCustomerExpenseSetEmployeeExpensesArgs = {
  order?: InputMaybe<Array<EmployeeExpenseSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Cost sharing report file. */
export type CostSharingDataFile = {
  __typename: 'CostSharingDataFile';
  /** Mime-type */
  contentType?: Maybe<Scalars['String']['output']>;
  /** Base64-encoded string containing the binary file */
  data?: Maybe<Scalars['String']['output']>;
  /** URL to file, currently this functionality isn't implemented */
  dataUrl?: Maybe<Scalars['Url']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  /** Store as attachment or open in browser */
  isAttachment?: Maybe<Scalars['Boolean']['output']>;
};

export type CostSharingDateTimeCondition = {
  /** Compare to value */
  compareTo: Scalars['DateTime']['input'];
  /** Compare to operator */
  operator: CostSharingCompareOperator;
};

export type CostSharingDeleteProfileInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Provides basic information about a department, with a map between Id and name. */
export type CostSharingDepartmentLite = {
  __typename: 'CostSharingDepartmentLite';
  /** Unique identifier for department, reference to Boss.Customer.CustomerId */
  id: Scalars['ID']['output'];
  /** Name of department */
  name: Scalars['String']['output'];
};

export type CostSharingEmployee = {
  __typename: 'CostSharingEmployee';
  /** Activation date */
  activationDate: Scalars['DateTime']['output'];
  activeProfile?: Maybe<CostSharingProfile>;
  activeProfileByMonth?: Maybe<CostSharingProfile>;
  companyId: Scalars['ID']['output'];
  /** ContractProductId for the CostSharingUser associated with this employee. */
  costSharingUserContractProductId?: Maybe<Scalars['ID']['output']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['ID']['output'];
  /** Deactivation date, null if not deactivated */
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  departmentAccountId: Scalars['Long']['output'];
  departmentCustomerId: Scalars['String']['output'];
  departmentName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  employeeCode?: Maybe<Scalars['ID']['output']>;
  /** Expenses visible for company user (administrator) */
  employeeExpenseSet: CostSharingEmployeeExpenseSet;
  fullName: Scalars['String']['output'];
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** When the associated mobile subscription was activated */
  mobileSubscriptionActivationDate?: Maybe<Scalars['DateTime']['output']>;
  /** MobileSubscriptionId (ContractProductId) associated with the employee. */
  mobileSubscriptionId?: Maybe<Scalars['ID']['output']>;
  modifiedFullName?: Maybe<Scalars['String']['output']>;
  notifyEmail: Scalars['Boolean']['output'];
  notifySms: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
  salaryCode?: Maybe<Scalars['ID']['output']>;
  state: CostSharingEmployeeState;
  stateSortOrder: Scalars['Int']['output'];
};


export type CostSharingEmployeeActiveProfileByMonthArgs = {
  month: CostSharingMonthInput;
};


export type CostSharingEmployeeEmployeeExpenseSetArgs = {
  where: CostSharingEmployeeExpenseWhereInput;
};

export type CostSharingEmployeeCollectionSegment = {
  __typename: 'CostSharingEmployeeCollectionSegment';
  items?: Maybe<Array<Maybe<CostSharingEmployee>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Expense item for an employee in context of a company (hides expenses not relevant to the company) */
export type CostSharingEmployeeExpense = {
  __typename: 'CostSharingEmployeeExpense';
  bNumber: Scalars['String']['output'];
  category: ExpenseCategory;
  /** Last comment from Employee (end user), null if no comments */
  comment?: Maybe<Scalars['String']['output']>;
  /**
   * Last comment from administrator, null if no comments
   * @deprecated Renamed to CompanyDeclinedComment
   */
  companyComment?: Maybe<Scalars['String']['output']>;
  /** Who declined the Company flagged expense, null if not declined */
  companyDeclinedByUser?: Maybe<CostSharingUser>;
  /** Comment for why a company representative (administrator) declined a Company flagged expense */
  companyDeclinedComment?: Maybe<Scalars['String']['output']>;
  /** When a Company flagged expense was declined, null if not declined */
  companyDeclinedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Who is covering the cost */
  coveredBy: CoveredBy;
  /** Name of the employee that caused this expense. */
  employeeName: Scalars['String']['output'];
  /** Phone number for the employee that caused this expense. */
  employeePhoneNumber: Scalars['String']['output'];
  /** When the event that caused the expense took place */
  eventTime: Scalars['DateTime']['output'];
  /** This relates to CallId an external unique identifier */
  id: Scalars['ID']['output'];
  /** True if a company representative (administrator) has declined a Company flagged expense */
  isCompanyDeclined: Scalars['Boolean']['output'];
  /**
   * CoveredBy has been modified by company representative.
   * @deprecated Use IsCompanyDeclined
   */
  isCompanyModified: Scalars['Boolean']['output'];
  /** CoveredBy has been modified after original import */
  isModified: Scalars['Boolean']['output'];
  /** Description of the expense */
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  profileRuleType: CostSharingProfileRuleType;
  /** When the expense is ReadOnly the expense can't be modified by the end user. */
  readOnly: Scalars['Boolean']['output'];
  /** When the expense was registered by phonero (calls can be placed some time before the expense is registered. */
  registrationTime: Scalars['DateTime']['output'];
  subCategory: ExpenseSubCategory;
  /** @deprecated Is replaced by RegistrationTime */
  when: Scalars['DateTime']['output'];
};

export type CostSharingEmployeeExpenseCollectionSegment = {
  __typename: 'CostSharingEmployeeExpenseCollectionSegment';
  items?: Maybe<Array<Maybe<CostSharingEmployeeExpense>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Expenses for one employee that are visible for an administrator */
export type CostSharingEmployeeExpenseSet = {
  __typename: 'CostSharingEmployeeExpenseSet';
  /** Sum of private and company expenses covered by company due to being below profile limit */
  belowLimitSum: Scalars['Decimal']['output'];
  /** Sum of expenses covered by company (in addition to expenses below limit) */
  companySum: Scalars['Decimal']['output'];
  /** Employee associated with expenses */
  employee: CostSharingEmployee;
  /** Expenses available from a company context (can't see detailed private expenses) */
  employeeExpenseItems?: Maybe<CostSharingEmployeeExpenseCollectionSegment>;
  isModifiedByCompany: Scalars['Boolean']['output'];
  /** Sum of expenses covered by private */
  privateSum: Scalars['Decimal']['output'];
};


/** Expenses for one employee that are visible for an administrator */
export type CostSharingEmployeeExpenseSetEmployeeExpenseItemsArgs = {
  order?: InputMaybe<Array<EmployeeExpenseSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CostSharingEmployeeExpenseSetCollectionSegment = {
  __typename: 'CostSharingEmployeeExpenseSetCollectionSegment';
  items?: Maybe<Array<Maybe<CostSharingEmployeeExpenseSet>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type CostSharingEmployeeExpenseWhereInput = {
  /** Category filter for expenses */
  categories?: InputMaybe<Array<InputMaybe<ExpenseCategory>>>;
  /** CoveredBy filter for expenses */
  coveredBy?: InputMaybe<Array<InputMaybe<CoveredBy>>>;
  /** Refers to start of Invoice month, (refers to first day in month at UTC 0, example '2022-06-01T00:00:00.000Z') */
  fromDate: Scalars['DateTime']['input'];
  /** Detailed category filter for expenses */
  subCategories?: InputMaybe<Array<InputMaybe<ExpenseSubCategory>>>;
  /** Refers to end of Invoice month (refers to last day in month at UTC 0, example '2022-06-30T23:59:59.999Z') */
  toDate: Scalars['DateTime']['input'];
};

export type CostSharingEmployeeSort = {
  /** Activation date */
  activationDate?: InputMaybe<SortEnumType>;
  costSharingUserContractProductId?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  /** Deactivation date, null if not deactivated */
  deactivationDate?: InputMaybe<SortEnumType>;
  departmentAccountId?: InputMaybe<SortEnumType>;
  departmentCustomerId?: InputMaybe<SortEnumType>;
  departmentName?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  employeeCode?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  /** Unique identifier */
  id?: InputMaybe<SortEnumType>;
  mobileSubscriptionActivationDate?: InputMaybe<SortEnumType>;
  mobileSubscriptionId?: InputMaybe<SortEnumType>;
  modifiedFullName?: InputMaybe<SortEnumType>;
  notifyEmail?: InputMaybe<SortEnumType>;
  notifySms?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  salaryCode?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  stateSortOrder?: InputMaybe<SortEnumType>;
};

export enum CostSharingEmployeeState {
  Assigned = 'ASSIGNED',
  AssignedButModified = 'ASSIGNED_BUT_MODIFIED',
  Excluded = 'EXCLUDED',
  NewUnassigned = 'NEW_UNASSIGNED'
}

export type CostSharingEmployeeWhereInput = {
  departmentAccountId?: InputMaybe<Array<Scalars['String']['input']>>;
  departmentAccountId_not?: InputMaybe<Array<Scalars['String']['input']>>;
  employeeCode?: InputMaybe<Array<Scalars['String']['input']>>;
  employeeCode_not?: InputMaybe<Array<Scalars['String']['input']>>;
  employeeId?: InputMaybe<Array<Scalars['String']['input']>>;
  employeeState?: InputMaybe<Array<CostSharingEmployeeState>>;
  employeeState_not?: InputMaybe<Array<CostSharingEmployeeState>>;
  mobileSubscriptionActivationDate?: InputMaybe<Array<InputMaybe<CostSharingDateTimeCondition>>>;
  phoneNumber?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber_not?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId_not?: InputMaybe<Array<Scalars['String']['input']>>;
  salaryCode?: InputMaybe<Array<Scalars['String']['input']>>;
  salaryCode_not?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CostSharingExpenseDistributionCategory = {
  __typename: 'CostSharingExpenseDistributionCategory';
  /** Expense sub category. */
  expenseCategory: ExpenseCategory;
  /** Total sum of expenses. */
  sumExpenses: Scalars['Decimal']['output'];
};

export type CostSharingExpenseDistributionCategoryPeriod = {
  __typename: 'CostSharingExpenseDistributionCategoryPeriod';
  /** List of expenses and categories. */
  expensesByCategories: Array<Maybe<CostSharingExpenseDistributionCategory>>;
  /** Start of expense period */
  fromDate: Scalars['DateTime']['output'];
  /** Sum of expenses across all categories. */
  sumExpenses: Scalars['Decimal']['output'];
  /** End of expense period */
  toDate: Scalars['DateTime']['output'];
};

export type CostSharingExpenseDistributionInput = {
  /** Include expenses from start of this month. */
  fromMonth?: InputMaybe<CostSharingMonthInput>;
  /** Period for ExpenseDistribution. When set to Yearly return last 12 months, while set to Range the From and To parameters are expected. */
  period: CostSharingExpenseDistributionPeriodSelector;
  /** Include expenses until the end of this month. */
  toMonth?: InputMaybe<CostSharingMonthInput>;
};

export type CostSharingExpenseDistributionPeriod = {
  __typename: 'CostSharingExpenseDistributionPeriod';
  /** Start of expense period */
  fromDate: Scalars['DateTime']['output'];
  /** Sum of expenses that exceed expense rule limit and are assigned to company. */
  sumCompany: Scalars['Decimal']['output'];
  /** Sum of expenses that exceed expense rule limit and are assigned to company. */
  sumPrivate: Scalars['Decimal']['output'];
  /** Total sum of expenses. */
  sumTotal: Scalars['Decimal']['output'];
  /** Sum of expenses that falls under expense rule limit. */
  sumUnderLimit: Scalars['Decimal']['output'];
  /** End of expense period */
  toDate: Scalars['DateTime']['output'];
};

/** Yearly returns last 12 months, while Range allows for manual selection of period. */
export enum CostSharingExpenseDistributionPeriodSelector {
  Range = 'RANGE',
  Yearly = 'YEARLY'
}

/** Preliminary and Archive uses default periods, while Range allows for manual selection of period. */
export enum CostSharingExpensePeriodSelector {
  Archive = 'ARCHIVE',
  Preliminary = 'PRELIMINARY',
  Range = 'RANGE'
}

export type CostSharingExpensePeriode = IExpenseSet & {
  __typename: 'CostSharingExpensePeriode';
  /** List of categories expenses consists of */
  categories?: Maybe<Array<Maybe<ExpenseCategory>>>;
  /** Sum of expenses covered by company */
  companySum: Scalars['Decimal']['output'];
  /** Returns a list of expenses */
  expenses?: Maybe<ExpensesConnection>;
  /** Aggregated expenses by category for the list of categories that is supplied */
  expensesByCategory: Array<Maybe<ExpenseSetByCategory>>;
  /** Start of expense period */
  fromDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Cost Sharing is enabled on this mobile subscription */
  isEnabled: Scalars['Boolean']['output'];
  /** Number of company expenses */
  numberOfCompanyItems: Scalars['Int']['output'];
  /** Number of private expenses */
  numberOfPrivateItems: Scalars['Int']['output'];
  /** Sum of expenses covered by private */
  privateSum: Scalars['Decimal']['output'];
  /** End of expense period */
  toDate: Scalars['DateTime']['output'];
};


export type CostSharingExpensePeriodeExpensesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ExpenseSortInput>>;
  where?: InputMaybe<ExpenseFilterInput>;
};


export type CostSharingExpensePeriodeExpensesByCategoryArgs = {
  categories?: InputMaybe<Array<ExpenseSubCategory>>;
};

export type CostSharingExpenseSummaryInput = {
  /** Include expenses from start of this month. */
  fromMonth?: InputMaybe<CostSharingMonthInput>;
  /** Period for ExpenseSummary. When set to Preliminary and Archive the period is calculated, while set to UserDefind the From and To parameters are expected */
  period?: InputMaybe<CostSharingExpensePeriodSelector>;
  /** Include expenses until the end of this month. */
  toMonth?: InputMaybe<CostSharingMonthInput>;
};

export type CostSharingExpenseSummaryPeriod = {
  __typename: 'CostSharingExpenseSummaryPeriod';
  /** Accumulated company expenses for the current period (month) */
  companySum: Scalars['Decimal']['output'];
  /** Accumulated company expenses for the current period (month), modified by employee */
  companySumModifiedByEmployee: Scalars['Decimal']['output'];
  /** Start of expense period */
  fromDate: Scalars['DateTime']['output'];
  /** Indicates if the company has declined, any request by the employee, regarding who is responsible/covers for an expense. Typically this means that the employee must cover those expenses. */
  hasCompanyDeclinedExpenses: Scalars['Boolean']['output'];
  /** Fixed (recurring) expenses and variable (one-time, e.g. ExtraData) expenses, minus profile rule limit for rule MaximumAmount. */
  otherExpensesExceedingMaximumAmount: Scalars['Decimal']['output'];
  /** Accumulated private expenses for the current period (month) */
  privateSum: Scalars['Decimal']['output'];
  /** Profile rules valid for current period (month) */
  profileRules: CostSharingRules;
  /** Reporting deadline for ExpenseSummaryPeriod, null if reporting deadline is expired (i.e archived). */
  reportingDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** List of rules for current period (month). */
  summaryByRules: Array<Maybe<CostSharingExpenseSummaryRule>>;
  /** End of expense period */
  toDate: Scalars['DateTime']['output'];
};

export type CostSharingExpenseSummaryRule = {
  __typename: 'CostSharingExpenseSummaryRule';
  /** Accumulated expenses already spent since start of year and up to (not including) current period (month). */
  alreadySpent: Scalars['Decimal']['output'];
  /** Accumulated company expenses for the current period (month) */
  companySum: Scalars['Decimal']['output'];
  /** Accumulated company expenses for the current period (month), modified by employee */
  companySumModifiedByEmployee: Scalars['Decimal']['output'];
  /** Indicates if the company has declined, any request by the employee, regarding who is responsible/covers for an expense. Typically this means that the employee must cover those expenses. */
  hasCompanyDeclinedExpenses: Scalars['Boolean']['output'];
  /** Accumulated expenses for current month that exceeds the limit covered by company. */
  overDraftSum: Scalars['Decimal']['output'];
  /** Accumulated private expenses for the current period (month) */
  privateSum: Scalars['Decimal']['output'];
  /** Limit for what is covered by company in ProfileRulePeriod. */
  profileRuleLimit?: Maybe<Scalars['Decimal']['output']>;
  /** Time period for how long the ProfileRuleLimit is valid, null if not applicable */
  profileRulePeriod?: Maybe<CostSharingProfilePeriod>;
  /** Identifier of the profile rule. */
  profileRuleType: CostSharingProfileRuleType;
  summaryBySubCategories?: Maybe<Array<Maybe<ExpenseSummarySubCategory>>>;
};

export type CostSharingExpensesByPeriodInput = {
  /** Include expenses from start of this month. */
  fromMonth: CostSharingMonthInput;
  /** List of RuleTypes to be included in search result, ignore filter if null or empty. */
  ruleTypes?: InputMaybe<Array<InputMaybe<CostSharingProfileRuleType>>>;
  /** Include expenses until the end of this month. */
  toMonth: CostSharingMonthInput;
};

/** Parameters for specifying the report to be generate. */
export type CostSharingExportParams = {
  agressoParams?: InputMaybe<ExportFormatAgressoParamsInput>;
  format: ExportFormat;
  /** Refers to first day in month at UTC 0, example '2022-06-01T00:00:00.000Z' */
  fromDate: Scalars['DateTime']['input'];
  kind: ExportKind;
  sapParams?: InputMaybe<ExportFormatSapParamsInput>;
  /** Refers to last day in month at UTC 0, example '2022-06-30T23:59:59.999Z' */
  toDate: Scalars['DateTime']['input'];
};

export type CostSharingExtraData = {
  __typename: 'CostSharingExtraData';
  /** Defines who is covering the cost, only applicable when rule IsEnabled */
  coverage: CostSharingExtraDataCoverage;
  customerId: Scalars['String']['output'];
  /** When Enabled this rule applies to extra data) */
  isEnabled: Scalars['Boolean']['output'];
  /** Defines X when Coverage is 'company covers X', only applicable when rule IsEnabled and Coverage is 'company covers X' */
  limit?: Maybe<Scalars['Decimal']['output']>;
};

export enum CostSharingExtraDataCoverage {
  /** Company covers the complete cost */
  CompanyCoversAll = 'COMPANY_COVERS_ALL',
  /** Employee covers the complete cost */
  CompanyCoversNothing = 'COMPANY_COVERS_NOTHING',
  /** Company covers cost up to limit, employee covers cost above limit */
  CompanyCoversX = 'COMPANY_COVERS_X',
  /** The employee may only purchase extra data that is paid directly by the employee, hence no extra data cost is expected for the company. */
  EmployeePaysPrivately = 'EMPLOYEE_PAYS_PRIVATELY'
}

export type CostSharingExtraDataInput = {
  /** How this rules covers expenses (when rule is enabled). Required if rule is enabled. */
  coverage?: InputMaybe<CostSharingExtraDataCoverage>;
  /** Is rule enabled. When enabled expenses are evaluated and assigned to this rule. When disabled expenses are evaluated against the next enabled rule */
  isEnabled: Scalars['Boolean']['input'];
  /** The 'X' limit used when 'company covers X'. Required if rule is enabled. */
  limit?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CostSharingInfoInput = {
  /** Portal: TaxAndSalaryEmployee.CostCode. */
  costCode?: InputMaybe<Scalars['String']['input']>;
  /** ContractProductId of the 24703 Kostnadsdeling profil to use for the contract after the move. If the contract being moved has an active 24704 user already using the given profile, the product is retained. If it has 24704, but with a different profile, the existing product is deactivated, the contract moved, and a new cost sharing user added with the specified profile. */
  costSharingProfileId: Scalars['ID']['input'];
  /** Portal: TaxAndSalaryEmployee.EmployeeNumber */
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CostSharingLimitRange = {
  __typename: 'CostSharingLimitRange';
  defaultValue: Scalars['Decimal']['output'];
  maxValue: Scalars['Decimal']['output'];
  minValue: Scalars['Decimal']['output'];
};

export type CostSharingMonthInput = {
  /** Month, between 1 and 12 */
  month: Scalars['Int']['input'];
  /** Year, between 0 and 9999. Format YYYY */
  year: Scalars['Int']['input'];
};

/** Provides data from report OverviewByUser on an individual user level. */
export type CostSharingOverviewByUser = {
  __typename: 'CostSharingOverviewByUser';
  /** CostCode, column name 'Lønnsart' */
  costCode: Scalars['String']['output'];
  /** CreditContentService, column name 'Krediterte innholdstjenester' */
  creditContentService: Scalars['Decimal']['output'];
  /** Customer name, column name 'Kundenavn' */
  customerName: Scalars['String']['output'];
  /** Salary deduction content service, column name 'Lønnstrekk innholdstjenester' */
  deductionContentService: Scalars['Decimal']['output'];
  /** Salary deduction total sum, column name 'Sum lønnstrekk' */
  deductionTotal: Scalars['Decimal']['output'];
  /** Salary deduction traffic and subscription, column name 'Lønnstrekk trafikk og abonnement' */
  deductionTrafficAndSubscription: Scalars['Decimal']['output'];
  /** Employee name, column name 'Navn' */
  employeeName: Scalars['String']['output'];
  /** EmployeeNumber, column name 'Ansattnr' */
  employeeNumber: Scalars['String']['output'];
  /** End of period, column name 'Fakturaperiode til' */
  endDate: Scalars['String']['output'];
  /** PhoneNumber name, column name 'Mobilnummer' */
  phoneNumber: Scalars['String']['output'];
  /** Salary for benefit tax, column name 'Tillegg lønn fordelsbeskatning' */
  salaryForBenefitTaxation: Scalars['Decimal']['output'];
  /** Start of period, column name 'Fakturaperiode fra' */
  startDate: Scalars['String']['output'];
};

export type CostSharingOverviewByUserCollectionSegment = {
  __typename: 'CostSharingOverviewByUserCollectionSegment';
  items?: Maybe<Array<Maybe<CostSharingOverviewByUser>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Provides data from report OverviewByUser on an aggregated level. */
export type CostSharingOverviewByUserSet = {
  __typename: 'CostSharingOverviewByUserSet';
  /** Sum salary deduction content service, column name 'Lønnstrekk innholdstjenester' */
  deductionContentService: Scalars['Decimal']['output'];
  /** Sum salary deduction total sum, column name 'Sum lønnstrekk' */
  deductionTotal: Scalars['Decimal']['output'];
  /** Sum salary deduction traffic and subscription, column name 'Lønnstrekk trafikk og abonnement' */
  deductionTrafficAndSubscription: Scalars['Decimal']['output'];
  /** Detailed records for each employee. */
  employees?: Maybe<CostSharingOverviewByUserCollectionSegment>;
  /** Salary for benefit tax, column name 'Tillegg lønn fordelsbeskatning' */
  salaryForBenefitTaxation: Scalars['Decimal']['output'];
  title: Scalars['String']['output'];
};


/** Provides data from report OverviewByUser on an aggregated level. */
export type CostSharingOverviewByUserSetEmployeesArgs = {
  order?: InputMaybe<Array<CostSharingOverviewByUserSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides data from report OverviewByUser on an individual user level. */
export type CostSharingOverviewByUserSortInput = {
  /** CostCode, column name 'Lønnsart' */
  costCode?: InputMaybe<SortEnumType>;
  /** CreditContentService, column name 'Krediterte innholdstjenester' */
  creditContentService?: InputMaybe<SortEnumType>;
  /** Customer name, column name 'Kundenavn' */
  customerName?: InputMaybe<SortEnumType>;
  /** Salary deduction content service, column name 'Lønnstrekk innholdstjenester' */
  deductionContentService?: InputMaybe<SortEnumType>;
  /** Salary deduction total sum, column name 'Sum lønnstrekk' */
  deductionTotal?: InputMaybe<SortEnumType>;
  /** Salary deduction traffic and subscription, column name 'Lønnstrekk trafikk og abonnement' */
  deductionTrafficAndSubscription?: InputMaybe<SortEnumType>;
  /** Employee name, column name 'Navn' */
  employeeName?: InputMaybe<SortEnumType>;
  /** EmployeeNumber, column name 'Ansattnr' */
  employeeNumber?: InputMaybe<SortEnumType>;
  /** End of period, column name 'Fakturaperiode til' */
  endDate?: InputMaybe<SortEnumType>;
  /** PhoneNumber name, column name 'Mobilnummer' */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** Salary for benefit tax, column name 'Tillegg lønn fordelsbeskatning' */
  salaryForBenefitTaxation?: InputMaybe<SortEnumType>;
  /** Start of period, column name 'Fakturaperiode fra' */
  startDate?: InputMaybe<SortEnumType>;
};

export type CostSharingProfile = {
  __typename: 'CostSharingProfile';
  /** Activation date */
  activationDate: Scalars['DateTime']['output'];
  /** Rules as specified at start of selected month, reflect rules that are applied to expenses that occurred in that month. */
  activeRulesByMonth: CostSharingRules;
  /** Current set of rules as they are specified just now. This is the last edited and most updated set of rules. Only the rules active at start of month will be applied to expenses. */
  costSharingRules: CostSharingRules;
  customerId: Scalars['ID']['output'];
  /** Deactivation date, null if not deactivated */
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** Numbers of employees that are assigned to this profile */
  employeeCount?: Maybe<Scalars['Int']['output']>;
  /** List of employees that are assigned to this profile */
  employees?: Maybe<EmployeesConnection>;
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** ReadOnly when object can be viewed but not modified. */
  isReadOnly: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notifications?: Maybe<NotificationSettings>;
  /** ContractProductId for the CostSharingProfile in Boss */
  profileContractProductId?: Maybe<Scalars['ID']['output']>;
  /** Solution that owns this profile */
  solution?: Maybe<CostSharingSolution>;
  /** ContractProductId for the CostSharingSolution in Boss */
  solutionContractProductId?: Maybe<Scalars['ID']['output']>;
};


export type CostSharingProfileActiveRulesByMonthArgs = {
  month: CostSharingMonthInput;
};


export type CostSharingProfileEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CostSharingEmployeeSort>>;
};

/** The period for witch the profile rule is applied. */
export enum CostSharingProfilePeriod {
  /** Profile rule is applied per month. */
  Monthly = 'MONTHLY',
  /** Profile rule is applied per year. */
  Yearly = 'YEARLY'
}

export enum CostSharingProfileRuleType {
  ContentServices = 'CONTENT_SERVICES',
  CountriesAlwaysCoveredByCompany = 'COUNTRIES_ALWAYS_COVERED_BY_COMPANY',
  DataTraffic = 'DATA_TRAFFIC',
  DataTrafficWeekday = 'DATA_TRAFFIC_WEEKDAY',
  DataTrafficWeekend = 'DATA_TRAFFIC_WEEKEND',
  ExtraDataAbroad = 'EXTRA_DATA_ABROAD',
  ExtraDataDomestic = 'EXTRA_DATA_DOMESTIC',
  MaximumAmount = 'MAXIMUM_AMOUNT',
  TrafficToAbroad = 'TRAFFIC_TO_ABROAD',
  TrafficToAbroadOrWhenAbroad = 'TRAFFIC_TO_ABROAD_OR_WHEN_ABROAD',
  TrafficWhenAbroad = 'TRAFFIC_WHEN_ABROAD'
}

export type CostSharingProfileSort = {
  /** Activation date */
  activationDate?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  /** Deactivation date, null if not deactivated */
  deactivationDate?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  /** Unique identifier */
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notifications?: InputMaybe<NotificationSettingsSortInput>;
};

/** Provides report data as a graph, currently only report data from 'OverviewByUser' is supported. */
export type CostSharingReportContainer = {
  __typename: 'CostSharingReportContainer';
  fromDate: Scalars['DateTime']['output'];
  kind: ExportKind;
  overviewByUser?: Maybe<CostSharingOverviewByUserSet>;
  toDate: Scalars['DateTime']['output'];
};

/** Parameters for specifying the report to be generate as a graph. */
export type CostSharingReportGraphParams = {
  agressoParams?: InputMaybe<ExportFormatAgressoParamsInput>;
  /** Refers to first day in month at UTC 0, example '2022-06-01T00:00:00.000Z' */
  fromDate: Scalars['DateTime']['input'];
  kind: ExportKind;
  sapParams?: InputMaybe<ExportFormatSapParamsInput>;
  /** Refers to last day in month at UTC 0, example '2022-06-30T23:59:59.999Z' */
  toDate: Scalars['DateTime']['input'];
};

export type CostSharingRules = {
  __typename: 'CostSharingRules';
  /** Activation date */
  activationDate: Scalars['DateTime']['output'];
  /** Date for when this rule becomes active. Rules becomes active at start of month after the rule was last modified. */
  activeFrom: Scalars['DateTime']['output'];
  /** Rules for handling phone calls to content services (payed services) */
  contentServices: CostSharingContentServices;
  customerId: Scalars['String']['output'];
  /** Deactivation date, null if not deactivated */
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Rules for handling domestic extra data */
  extraDataDomestic: CostSharingExtraData;
  /** Rules for handling international extra data */
  extraDataInternational: CostSharingExtraData;
  /** Date for when this rule last was modified. */
  lastModified: Scalars['DateTime']['output'];
  /** User that last modified the profile. */
  lastModifiedBy?: Maybe<CostSharingUser>;
  /** Whether or not employees are disallowed reporting on content services expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyContentServicesExpenses: Scalars['Boolean']['output'];
  /** Whether or not employees are disallowed reporting on extradata expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyExtraDataExpenses: Scalars['Boolean']['output'];
  /** Whether or not employees are disallowed reporting on traffic expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyTrafficExpenses: Scalars['Boolean']['output'];
  /** Rules for handling traffic (phone calls and SMS) that exceeds what is covered by the subscription */
  trafficRules: CostSharingTrafficRules;
};

export type CostSharingSolution = {
  __typename: 'CostSharingSolution';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  customerId: Scalars['ID']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use Employees query from Customer/CostSharing level */
  employees?: Maybe<EmployeesConnection>;
  id: Scalars['ID']['output'];
  /** ReadOnly when object can be viewed but not modified. */
  isReadOnly: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profiles?: Maybe<ProfilesConnection>;
  /** @deprecated Report functionality is moved to CostSharingCustomer */
  report?: Maybe<CostSharingDataFile>;
  settings: CostSharingSolutionSettings;
};


export type CostSharingSolutionEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CostSharingEmployeeSort>>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  whereInput?: InputMaybe<CostSharingEmployeeWhereInput>;
};


export type CostSharingSolutionProfilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CostSharingProfileSort>>;
};


export type CostSharingSolutionReportArgs = {
  exportParams: CostSharingExportParams;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CostSharingSolutionSettings = {
  __typename: 'CostSharingSolutionSettings';
  /** The deadline (day of month, between 1 and 28) for declaring costs as personal or business costs. Declarations must be made *before* the deadline. */
  costAllocationDeadline: Scalars['Int']['output'];
  /** The text appended to the end of system-generated email notification when an employee must declare cost allocation (personal or work). */
  emailSignature: Scalars['String']['output'];
  /** Unique identifier */
  id: Scalars['Int']['output'];
  /** ReadOnly when object can be viewed but not modified. */
  isReadOnly: Scalars['Boolean']['output'];
  /** The text appended to the end of system-generated SMS notification when an employee must declare cost allocation (personal or work). */
  smsSignature: Scalars['String']['output'];
};

export type CostSharingSolutionSettingsInput = {
  /** The deadline (day of month, between 1 and 28) for declaring costs as personal or business costs. Declarations must be made *before* the deadline. */
  costAllocationDeadline: Scalars['Int']['input'];
  /** The text appended to the end of system-generated email notification when an employee must declare cost allocation (personal or work). */
  emailSignature?: InputMaybe<Scalars['String']['input']>;
  /** The text appended to the end of system-generated SMS notification when an employee must declare cost allocation (personal or work). */
  smsSignature?: InputMaybe<Scalars['String']['input']>;
  /** Identifies the CostSharingSolution (Id) which settings should be updated. */
  solutionId: Scalars['ID']['input'];
};

export type CostSharingSubscription = IProduct & IService & {
  __typename: 'CostSharingSubscription';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractCategory?: Maybe<OfferingContractCategory>;
  contractPriceType: Scalars['Short']['output'];
  contractPriceTypeId: Scalars['Short']['output'];
  contractType: ContractType;
  customerAgreementOfferingId: Scalars['ID']['output'];
  customerId: Scalars['ID']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  demo: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  lastUpdated: Scalars['DateTime']['output'];
  monthlyPrice: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  /** @deprecated Use name */
  productName?: Maybe<Scalars['String']['output']>;
  profiles?: Maybe<Array<Subscription_CostSharingProfile>>;
  registrationDate?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type CostSharingTraffic = {
  __typename: 'CostSharingTraffic';
  /** Defines who is covering the cost, only applicable when rule IsEnabled */
  coverage: CostSharingTrafficCoverage;
  customerId: Scalars['String']['output'];
  /** When Enabled this rule applies to traffic) */
  isEnabled: Scalars['Boolean']['output'];
  /** Defines X when Coverage is 'company covers X', only applicable when rule IsEnabled and Coverage is 'company covers X' */
  limit?: Maybe<Scalars['Decimal']['output']>;
};

export enum CostSharingTrafficCoverage {
  /** Company covers the complete cost */
  CompanyCoversAll = 'COMPANY_COVERS_ALL',
  /** Employee covers the complete cost */
  CompanyCoversNothing = 'COMPANY_COVERS_NOTHING',
  /** Company covers cost up to limit, employee covers cost above limit */
  CompanyCoversX = 'COMPANY_COVERS_X'
}

export type CostSharingTrafficFreeAmount = {
  __typename: 'CostSharingTrafficFreeAmount';
  /** Defines who is covering the cost, only applicable when rule IsEnabled */
  coverage: CostSharingTrafficFreeAmountCoverage;
  customerId: Scalars['String']['output'];
  /** When Enabled this rule applies to free amount) */
  isEnabled: Scalars['Boolean']['output'];
  /** Defines X when Coverage is 'company covers X', only applicable when rule IsEnabled and Coverage is 'company covers X' */
  limit?: Maybe<Scalars['Decimal']['output']>;
  /** Period for witch the limit applies, only applicable when rule IsEnabled */
  period: CostSharingTrafficFreeAmountPeriod;
  ruleLimit?: Maybe<Scalars['Decimal']['output']>;
};

export enum CostSharingTrafficFreeAmountCoverage {
  /**
   * Company covers all expenses. Since this is the last applied rule company covers all expenses by disabling this rule
   * @deprecated It is better to disable the rule
   */
  CompanyCoversAll = 'COMPANY_COVERS_ALL',
  /** Company covers expenses up to limit. Employee covers expenses that exceed limit. */
  CompanyCoversX = 'COMPANY_COVERS_X'
}

export type CostSharingTrafficFreeAmountInput = {
  /** How this rules covers expenses (when rule is enabled). Required if rule is enabled */
  coverage?: InputMaybe<CostSharingTrafficFreeAmountCoverage>;
  /** Is rule enabled. When enabled expenses are evaluated and assigned to this rule. When disabled expenses are evaluated against the next enabled rule. */
  isEnabled: Scalars['Boolean']['input'];
  /** The 'X' limit used when 'company covers X'. Required if rule is enabled. */
  limit?: InputMaybe<Scalars['Decimal']['input']>;
  /** The time span (yearly, monthly) that the rule is evaluated over. Required if rule is enabled */
  period?: InputMaybe<CostSharingTrafficFreeAmountPeriod>;
};

export enum CostSharingTrafficFreeAmountPeriod {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type CostSharingTrafficInput = {
  /** How this rules covers expenses (when rule is enabled). Required if rule is enabled. */
  coverage?: InputMaybe<CostSharingTrafficCoverage>;
  /** Is rule enabled. When enabled expenses are evaluated and assigned to this rule. When disabled expenses are evaluated against the next enabled rule */
  isEnabled: Scalars['Boolean']['input'];
  /** The 'X' limit used when 'company covers X'. Required if rule is enabled. */
  limit?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CostSharingTrafficRules = {
  __typename: 'CostSharingTrafficRules';
  coveredCountries: Array<CostSharingCountry>;
  customerId: Scalars['String']['output'];
  /** Optional free amount for incurred traffic (monthly or yearly) */
  freeAmount: CostSharingTrafficFreeAmount;
  /** Traffic rule when subscription is used abroad. This option exclude traffic rules for 'RoamingAndToAbroad'. */
  roaming: CostSharingTraffic;
  /** Traffic rule when subscription is used abroad or traffic is to foreign countries.This option exclude traffic rules for 'Roaming' or 'ToAbroad'. */
  roamingAndToAbroad: CostSharingTraffic;
  /** Traffic rule when traffic is to foreign countries. This option exclude traffic rules for 'RoamingAndToAbroad'. */
  toAbroad: CostSharingTraffic;
};

export type CostSharingTrafficRulesInput = {
  /** List of CountryId; the set of countries the company always covers traffic costs for. */
  coveredCountryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Optional free amount for incurred traffic (monthly or yearly) */
  freeAmount: CostSharingTrafficFreeAmountInput;
  /** Traffic rule when subscription is used abroad. This option exclude traffic rules for 'RoamingAndToAbroad'. */
  roaming: CostSharingTrafficInput;
  /**
   * Traffic rule when subscription is used abroad or traffic is to foreign countries.
   * This option exclude traffic rules for 'Roaming' or 'ToAbroad'.
   */
  roamingAndToAbroad: CostSharingTrafficInput;
  /** Traffic rule when traffic is to foreign countries. This option exclude traffic rules for 'RoamingAndToAbroad'. */
  toAbroad: CostSharingTrafficInput;
};

export type CostSharingUser = {
  __typename: 'CostSharingUser';
  /** Activation date */
  activationDate: Scalars['DateTime']['output'];
  /** Associated Solution (or Company) */
  company?: Maybe<CostSharingSolution>;
  customerId: Scalars['String']['output'];
  /** Deactivation date, null if not deactivated */
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  /** Unique identifier */
  id: Scalars['ID']['output'];
  mobileNumber: Scalars['String']['output'];
};

export type CostSharingUserProduct = IProduct & {
  __typename: 'CostSharingUserProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type CostSharingUserService = IProduct & IService & {
  __typename: 'CostSharingUserService';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  /** @deprecated Use cost sharing profile to get to profile Id */
  costSharingProfileId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use cost sharing profile to get to profile name */
  costSharingProfileName?: Maybe<Scalars['String']['output']>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  /** External unique employments number also known a 'AnsattNummer'. Used in Cost Sharing when exporting to third party accounting/salary systems. Value is also available on CostSharingEmployee */
  employeeCode?: Maybe<Scalars['ID']['output']>;
  /** Primary key for related CostSharingEmployee */
  employeeId?: Maybe<Scalars['ID']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  /** Related CostSharingProfileService (represent a ContractProduct instance). For more detailed profile info lookup CostSharingProfile */
  profileService: Subscription_CostSharingProfile;
  /** External unique employments number also known a 'Lønnsart'. Used in Cost Sharing when exporting to third party accounting/salary systems. Value is also available on CostSharingEmployee */
  salaryCode?: Maybe<Scalars['ID']['output']>;
  state: ApiProductState;
};

export type Country = {
  __typename: 'Country';
  continent?: Maybe<Scalars['String']['output']>;
  inEEA: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type CountryFilterInput = {
  and?: InputMaybe<Array<CountryFilterInput>>;
  inEEA?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringFilterTypeFilterInput>;
  or?: InputMaybe<Array<CountryFilterInput>>;
};

export enum CoveredBy {
  Company = 'COMPANY',
  Private = 'PRIVATE'
}

export type CoveredByOperationFilterInput = {
  eq?: InputMaybe<CoveredBy>;
  in?: InputMaybe<Array<CoveredBy>>;
  neq?: InputMaybe<CoveredBy>;
  nin?: InputMaybe<Array<CoveredBy>>;
};

export type Customer = {
  __typename: 'Customer';
  address?: Maybe<Address>;
  administrators?: Maybe<Array<Person>>;
  agreements?: Maybe<Array<Agreement>>;
  availableDataPackages?: Maybe<Array<Maybe<DataPackageProduct>>>;
  availableExtraDataPackages?: Maybe<Array<Maybe<ExtraDataPackageProduct>>>;
  /** @deprecated Use BillToCustomer functionality on Subscription */
  billToCustomer?: Maybe<CustomerLite>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  children?: Maybe<Array<Maybe<Customer>>>;
  contracts?: Maybe<ContractUnionTypeConnection>;
  costSharing: CostSharingCustomer;
  costs?: Maybe<CostSet>;
  costsByInterval?: Maybe<CostSetInterval>;
  costsBySubscripton?: Maybe<CostSetMobileSubscriptionConnection>;
  customerType?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<CustomerFeature>>;
  findMobileSubscriptions?: Maybe<MobileSubscriptionLiteConnection>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  hasChildren: Scalars['Boolean']['output'];
  hasFeature: Scalars['Boolean']['output'];
  hasRole: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceControlUrl?: Maybe<Scalars['String']['output']>;
  invoices?: Maybe<InvoiceConnection>;
  isActive: Scalars['Boolean']['output'];
  mbnSolutions?: Maybe<Array<MbnSolution>>;
  mobileSubscriptions?: Maybe<MobileSubscriptionConnection>;
  mobileSubscriptions2?: Maybe<MobileSubscriptionConnection>;
  name?: Maybe<Scalars['String']['output']>;
  officialRegistryEntry: BrregEntry;
  orders?: Maybe<OrderDetailConnection>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  reportUrl?: Maybe<Scalars['String']['output']>;
  reservedNumbers?: Maybe<ReservedNumberConnection>;
  roles?: Maybe<Array<CustomerRole>>;
  services?: Maybe<Array<CustomerProduct>>;
  settings?: Maybe<CustomerSettings>;
  usageBySubscription?: Maybe<Array<Maybe<UsageSetMobileSubscription>>>;
};


export type CustomerAgreementsArgs = {
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  validForSubscription?: InputMaybe<Scalars['String']['input']>;
};


export type CustomerChildrenArgs = {
  where?: InputMaybe<CustomerFilterInput>;
};


export type CustomerContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contractTypes?: InputMaybe<Array<ApiContractType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeCustomers?: InputMaybe<Array<CustomerRelation>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IContractFilterInput>;
};


export type CustomerCostsArgs = {
  from: Scalars['DateTime']['input'];
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  to: Scalars['DateTime']['input'];
  type?: InputMaybe<Array<CostMapping>>;
};


export type CustomerCostsByIntervalArgs = {
  from: Scalars['DateTime']['input'];
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  to: Scalars['DateTime']['input'];
  type?: InputMaybe<CostMapping>;
};


export type CustomerCostsBySubscriptonArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CostSetMobileSubscriptionSortInput>>;
  to: Scalars['DateTime']['input'];
  type: CostMapping;
};


export type CustomerFindMobileSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userQuery?: InputMaybe<Scalars['String']['input']>;
};


export type CustomerHasFeatureArgs = {
  f: CustomerFeature;
};


export type CustomerHasRoleArgs = {
  f: CustomerRole;
};


export type CustomerInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InvoiceSortInput>>;
  where?: InputMaybe<InvoiceFilterInput>;
};


export type CustomerMbnSolutionsArgs = {
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CustomerMobileSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contractTypes?: InputMaybe<Array<MobileSubscriptionContractType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeNewlyDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MobileSubscriptionSortInput>>;
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};


export type CustomerMobileSubscriptions2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MobileSubscriptionSortInput>>;
  queryParams?: InputMaybe<MobileSubscriptionsByCustomerParamsInput>;
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};


export type CustomerOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderDetailFilterInput>;
};


export type CustomerReservedNumbersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReservedNumberFilterInput>;
};


export type CustomerServicesArgs = {
  filter?: InputMaybe<CustomerProductFilterInput>;
  order?: InputMaybe<Array<CustomerProductSortInput>>;
};


export type CustomerUsageBySubscriptionArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type CustomerAddress = {
  __typename: 'CustomerAddress';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
  zipCodeId: Scalars['Int']['output'];
};

/** A connection to a list of items. */
export type CustomerConnection = {
  __typename: 'CustomerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Customer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CustomerContact = {
  __typename: 'CustomerContact';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** An edge in a connection. */
export type CustomerEdge = {
  __typename: 'CustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Customer>;
};

export enum CustomerFeature {
  AddMobileSubscription = 'ADD_MOBILE_SUBSCRIPTION',
  CannotChangeOfferingInSelfService = 'CANNOT_CHANGE_OFFERING_IN_SELF_SERVICE',
  /** @deprecated This feature flag is not in use */
  CanDeactivateSimCard = 'CAN_DEACTIVATE_SIM_CARD',
  CostReporting = 'COST_REPORTING',
  CostSharing = 'COST_SHARING',
  DataPackageHack = 'DATA_PACKAGE_HACK',
  ExtraDataPackageCostReporting = 'EXTRA_DATA_PACKAGE_COST_REPORTING',
  FamilyBucketCanModify = 'FAMILY_BUCKET_CAN_MODIFY',
  FamilyBucketMobileSupport = 'FAMILY_BUCKET_MOBILE_SUPPORT',
  HasBreachFeeAccess = 'HAS_BREACH_FEE_ACCESS',
  HasCustomerTreeAccess = 'HAS_CUSTOMER_TREE_ACCESS',
  HasDirectoryInformationAccess = 'HAS_DIRECTORY_INFORMATION_ACCESS',
  HasReleaseNumberAccess = 'HAS_RELEASE_NUMBER_ACCESS',
  HasRestrictedDirectoryInformationAccess = 'HAS_RESTRICTED_DIRECTORY_INFORMATION_ACCESS',
  /** @deprecated This feature flag is not in use */
  HideSimCardDeviceInfo = 'HIDE_SIM_CARD_DEVICE_INFO',
  InternalPortingAddMobileSubscription = 'INTERNAL_PORTING_ADD_MOBILE_SUBSCRIPTION',
  ProfileReport = 'PROFILE_REPORT',
  ReportedCostsArchive = 'REPORTED_COSTS_ARCHIVE',
  ShowSimCardDeviceInfo = 'SHOW_SIM_CARD_DEVICE_INFO',
  TrafficCostCoveredCountriesRule = 'TRAFFIC_COST_COVERED_COUNTRIES_RULE',
  TrafficCostReporting = 'TRAFFIC_COST_REPORTING'
}

export type CustomerFilterInput = {
  and?: InputMaybe<Array<CustomerFilterInput>>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFilterInput>>;
};

export type CustomerLite = {
  __typename: 'CustomerLite';
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CustomerLiteConnection = {
  __typename: 'CustomerLiteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerLiteEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CustomerLite>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerLiteEdge = {
  __typename: 'CustomerLiteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerLite>;
};

export type CustomerProduct = {
  __typename: 'CustomerProduct';
  activeOnSubscriptions?: Maybe<RelatedSubscriptionConnection>;
  activeOnSubscriptionsCount?: Maybe<Scalars['Int']['output']>;
  availableOnSubscriptions?: Maybe<RelatedSubscriptionConnection>;
  availableOnSubscriptionsCount?: Maybe<Scalars['Int']['output']>;
  canDeactivateOnSubscriptions?: Maybe<RelatedSubscriptionConnection>;
  canDeactivateOnSubscriptionsCount?: Maybe<Scalars['Int']['output']>;
  category: ApiProductCategory;
  customerId: Scalars['ID']['output'];
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  excludedOnSubscriptionsCount?: Maybe<ExcludedCount>;
  futureDeactivationOnSubscriptionCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  mandatoryOnSubscriptions?: Maybe<RelatedSubscriptionConnection>;
  mandatoryOnSubscriptionsCount?: Maybe<Scalars['Int']['output']>;
  maxPrice?: Maybe<Scalars['Decimal']['output']>;
  minPrice?: Maybe<Scalars['Decimal']['output']>;
  name: Scalars['String']['output'];
  productId: Scalars['Int']['output'];
};


export type CustomerProductActiveOnSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerProductAvailableOnSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerProductCanDeactivateOnSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerProductMandatoryOnSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerProductFilterInput = {
  categories?: InputMaybe<Array<ApiProductCategory>>;
  productId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CustomerProductSortInput = {
  name?: InputMaybe<SortEnumType>;
  productId?: InputMaybe<SortEnumType>;
};

export enum CustomerRelation {
  Childcustomers = 'CHILDCUSTOMERS',
  Parentcustomers = 'PARENTCUSTOMERS'
}

export enum CustomerRole {
  Administrator = 'ADMINISTRATOR',
  Approver = 'APPROVER',
  CostSharing = 'COST_SHARING'
}

export type CustomerSet = {
  __typename: 'CustomerSet';
  costs: CostSet;
  id: Scalars['ID']['output'];
};


export type CustomerSetCostsArgs = {
  from: Scalars['DateTime']['input'];
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  to: Scalars['DateTime']['input'];
  type?: InputMaybe<Array<CostMapping>>;
};

export type CustomerSettings = {
  __typename: 'CustomerSettings';
  directoryReservation?: Maybe<DirectoryReservation>;
};

export type CustomerSortInput = {
  birthDate?: InputMaybe<SortEnumType>;
  customerType?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organizationNumber?: InputMaybe<SortEnumType>;
  parentId?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
};

export type DataConsumptionGroup = {
  __typename: 'DataConsumptionGroup';
  /** GeneratedID is generated based on the content. */
  _contentHash: Scalars['String']['output'];
};

export type DataPackage = IProduct & IService & {
  __typename: 'DataPackage';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  packageSize?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  rollover: Scalars['Boolean']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  state: ApiProductState;
};

export type DataPackageOfferingProduct = IProduct & OfferingProduct & {
  __typename: 'DataPackageOfferingProduct';
  activeOn?: Maybe<RelatedSubscriptionConnection>;
  available?: Maybe<RelatedSubscriptionConnection>;
  canDeactivateOn?: Maybe<RelatedSubscriptionConnection>;
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  flagUsageModify: Scalars['Boolean']['output'];
  flagUsageNew: Scalars['Boolean']['output'];
  freeOccurrences: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  maxOccurrences?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  offeringId: Scalars['ID']['output'];
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
  upload: Scalars['Int']['output'];
};


export type DataPackageOfferingProductActiveOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DataPackageOfferingProductAvailableArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DataPackageOfferingProductCanDeactivateOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DataPackageProduct = IProduct & {
  __typename: 'DataPackageProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
  subscriptions?: Maybe<Scalars['String']['output']>;
  upload: Scalars['Int']['output'];
};

export enum DataPackageSizeMetadataFlag {
  Payperbyte = 'PAYPERBYTE',
  Unlimited = 'UNLIMITED'
}

export type DataRollover = IProduct & IService & {
  __typename: 'DataRollover';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type DataRolloverProduct = IProduct & {
  __typename: 'DataRolloverProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type DataUsage = {
  __typename: 'DataUsage';
  /** accumulatedUsage: AccumulatedUsage */
  dataConsumptionGroups?: Maybe<Array<Maybe<DataConsumptionGroup>>>;
  msisdn?: Maybe<Scalars['String']['output']>;
  throttling: Throttling;
};

export type DataUsageNotification = IProduct & IService & {
  __typename: 'DataUsageNotification';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type DataUsageNotificationProduct = IProduct & {
  __typename: 'DataUsageNotificationProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type DeactivateCostSharingUserInput = {
  deactivationDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  endStates?: InputMaybe<Array<InputMaybe<DesiredProductStateInput>>>;
  id: Scalars['Int']['input'];
};

export type DeactivateProductInput = {
  productId: Scalars['ID']['input'];
  subscriptionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DeactivateServiceInput = {
  deactivationDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
};

export type DeactivateServiceOrderConfirmation = {
  __typename: 'DeactivateServiceOrderConfirmation';
  deactivationDateTime?: Maybe<Scalars['DateTime']['output']>;
  orderId: Scalars['Uuid']['output'];
};

export type DeactivateSubscriptionCancellationInput = {
  subscriptionId: Scalars['ID']['input'];
};

export type DeactivateSubscriptionInput = {
  deactivationDate: Scalars['DateTime']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type Deactivation = {
  __typename: 'Deactivation';
  forecastDate: Scalars['DateTime']['output'];
  value: DeactivationRule;
};

export enum DeactivationRule {
  Endofmonth = 'ENDOFMONTH',
  Immediate = 'IMMEDIATE'
}

export type DeactivationSortInput = {
  forecastDate?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type DesiredProductStateInput = {
  desiredState: ProductState;
  productId: Scalars['Int']['input'];
};

export type DeviceInfo = {
  __typename: 'DeviceInfo';
  deviceName?: Maybe<Scalars['String']['output']>;
  lastUsed?: Maybe<Scalars['DateTime']['output']>;
};

export type DirectoryEntryReservation = {
  __typename: 'DirectoryEntryReservation';
  reason?: Maybe<DirectoryEntryReservationReason>;
  scope: DirectoryEntryReservationScope;
};

export enum DirectoryEntryReservationReason {
  CustomerSetting = 'CUSTOMER_SETTING',
  SecretNumber = 'SECRET_NUMBER',
  Subscription = 'SUBSCRIPTION'
}

export enum DirectoryEntryReservationScope {
  Address = 'ADDRESS',
  Full = 'FULL',
  None = 'NONE'
}

export enum DirectoryEntryType {
  Company = 'COMPANY',
  Hybrid = 'HYBRID',
  Personal = 'PERSONAL'
}

export type DirectoryInformation = {
  __typename: 'DirectoryInformation';
  canEdit: Scalars['Boolean']['output'];
  company?: Maybe<CompanyDirectoryEntry>;
  kind: DirectoryEntryType;
  msisdn: Scalars['ID']['output'];
  personal?: Maybe<PersonalDirectoryEntry>;
  reservation: DirectoryEntryReservation;
};

export type DirectoryInformationQueryInput = {
  customerId: Scalars['String']['input'];
  msisdn: Scalars['String']['input'];
};

export type DirectoryReservation = {
  __typename: 'DirectoryReservation';
  isReserved: Scalars['Boolean']['output'];
};

export type DomesticExtraDataGroup = {
  __typename: 'DomesticExtraDataGroup';
  activated?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  isSelected: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  usage?: Maybe<ByteUsage>;
};

export type DomesticGroup = {
  __typename: 'DomesticGroup';
  activated?: Maybe<Scalars['DateTime']['output']>;
  autoExtraDataUsage?: Maybe<ByteUsage>;
  autoTopup: Scalars['Boolean']['output'];
  autoTopupCount: Scalars['Int']['output'];
  blocked: Scalars['Boolean']['output'];
  expires?: Maybe<Scalars['DateTime']['output']>;
  extraDataGroups?: Maybe<Array<Maybe<DomesticExtraDataGroup>>>;
  extraDataUsageAccumulated?: Maybe<ByteUsage>;
  has1GBUnrestrictedOverusage: Scalars['Boolean']['output'];
  hasDomesticDataControl: Scalars['Boolean']['output'];
  isRolloverFirst: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  monthlyUsage?: Maybe<ByteUsage>;
  name?: Maybe<Scalars['String']['output']>;
  overusage?: Maybe<ByteUsage>;
  rolloverUsage?: Maybe<ByteUsage>;
  sharingUsage?: Maybe<ByteUsage>;
  throttleSpeed?: Maybe<Speed>;
  throttled: Scalars['Boolean']['output'];
  total?: Maybe<ByteUsage>;
  unlimited: Scalars['Boolean']['output'];
};

export type ECommerceError = Error & {
  __typename: 'ECommerceError';
  errors?: Maybe<Array<Maybe<ErrorInfo>>>;
  httpStatusCode: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  orderId?: Maybe<Scalars['String']['output']>;
  reasonPhrase?: Maybe<Scalars['String']['output']>;
  toFriendlyErrorMessage?: Maybe<Scalars['String']['output']>;
};

export type EmployeeExpenseSetSortInput = {
  belowLimitSum?: InputMaybe<SortEnumType>;
  companySum?: InputMaybe<SortEnumType>;
  employee?: InputMaybe<CostSharingEmployeeSort>;
  isModifiedByCompany?: InputMaybe<SortEnumType>;
  privateSum?: InputMaybe<SortEnumType>;
};

export type EmployeeExpenseSortInput = {
  bNumber?: InputMaybe<SortEnumType>;
  category?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  companyComment?: InputMaybe<SortEnumType>;
  /** Comment for why a company representative (administrator) declined a Company flagged expense */
  companyDeclinedComment?: InputMaybe<SortEnumType>;
  /** When a Company flagged expense was declined, null if not declined */
  companyDeclinedDate?: InputMaybe<SortEnumType>;
  coveredBy?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  /** Name of the employee that caused this expense. */
  employeeName?: InputMaybe<SortEnumType>;
  /** Phone number for the employee that caused this expense. */
  employeePhoneNumber?: InputMaybe<SortEnumType>;
  /** When the event that caused the expense took place */
  eventTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** True if a company representative (administrator) has declined a Company flagged expense */
  isCompanyDeclined?: InputMaybe<SortEnumType>;
  isCompanyModified?: InputMaybe<SortEnumType>;
  isModified?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  profileRuleType?: InputMaybe<SortEnumType>;
  /** When the expense was registered by phonero (calls can be placed some time before the expense is registered. */
  registrationTime?: InputMaybe<SortEnumType>;
  subCategory?: InputMaybe<SortEnumType>;
  when?: InputMaybe<SortEnumType>;
};

export enum EmployeeKeyIdentifier {
  /** When Key is the Id of the Employee object in cost sharing. Also known as CallID'. */
  EmployeeId = 'EMPLOYEE_ID',
  /** When Key is the Id of the MobileSubscriptionId. Also known as 'ContractProductId'. */
  SubscriptionId = 'SUBSCRIPTION_ID'
}

/** Facilitates a single interface for selecting an Employee by means of different KeyTypes. */
export type EmployeeKeyInput = {
  /** When Key is the Id of the MobileSubscriptionId. Also known as 'ContractProductId'. */
  key: Scalars['ID']['input'];
  keyType: EmployeeKeyIdentifier;
};

/** A connection to a list of items. */
export type EmployeesConnection = {
  __typename: 'EmployeesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EmployeesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CostSharingEmployee>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EmployeesEdge = {
  __typename: 'EmployeesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CostSharingEmployee>;
};

export type Error = {
  message: Scalars['String']['output'];
};

export type ErrorInfo = {
  __typename: 'ErrorInfo';
  errorCode?: Maybe<Scalars['String']['output']>;
  errorGroup?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  toShortString?: Maybe<Scalars['String']['output']>;
};

export type ExcludedByProduct = {
  __typename: 'ExcludedByProduct';
  isHidden: Scalars['Boolean']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
};

export type ExcludedCount = {
  __typename: 'ExcludedCount';
  excludedBy?: Maybe<Array<Maybe<ExcludedByProduct>>>;
  total: Scalars['Int']['output'];
};

export type Expense = {
  __typename: 'Expense';
  bNumber: Scalars['String']['output'];
  category?: Maybe<ExpenseCategory>;
  /** Last comment from Employee (end user), null if no comments */
  comment?: Maybe<Scalars['String']['output']>;
  /** Last comment from administrator, null if no comments */
  companyComment?: Maybe<Scalars['String']['output']>;
  /** Who declined the Company flagged expense, null if not declined */
  companyDeclinedByUser?: Maybe<CostSharingUser>;
  /** Comment for why a company representative (administrator) declined a Company flagged expense */
  companyDeclinedComment?: Maybe<Scalars['String']['output']>;
  /** When a Company flagged expense was declined, null if not declined */
  companyDeclinedDate?: Maybe<Scalars['DateTime']['output']>;
  coveredBy?: Maybe<CoveredBy>;
  /** When the event that caused the expense took place */
  eventTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** True if a company representative (administrator) has declined a Company flagged expense */
  isCompanyDeclined: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  profileRuleType: CostSharingProfileRuleType;
  /** When the expense is ReadOnly the expense can't be modified by the end user. */
  readOnly: Scalars['Boolean']['output'];
  /** When the expense was registered by phonero (calls can be placed some time before the expense is registered. */
  registrationTime: Scalars['DateTime']['output'];
  subCategory: ExpenseSubCategory;
  /** @deprecated Is replaced by RegistrationTime */
  when: Scalars['DateTime']['output'];
};

export enum ExpenseCategory {
  ContentService = 'CONTENT_SERVICE',
  ExtraData = 'EXTRA_DATA',
  ForeignTraffic = 'FOREIGN_TRAFFIC',
  /** Other uncategorized expenses, containing subscriptions with monthly and establishment fees */
  Other = 'OTHER'
}

export type ExpenseCategoryOperationFilterInput = {
  eq?: InputMaybe<ExpenseCategory>;
  in?: InputMaybe<Array<ExpenseCategory>>;
  neq?: InputMaybe<ExpenseCategory>;
  nin?: InputMaybe<Array<ExpenseCategory>>;
};

export type ExpenseFilterInput = {
  and?: InputMaybe<Array<ExpenseFilterInput>>;
  bNumber?: InputMaybe<StringOperationFilterInput>;
  category?: InputMaybe<ExpenseCategoryOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  companyComment?: InputMaybe<StringOperationFilterInput>;
  /** Comment for why a company representative (administrator) declined a Company flagged expense */
  companyDeclinedComment?: InputMaybe<StringOperationFilterInput>;
  /** When a Company flagged expense was declined, null if not declined */
  companyDeclinedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  coveredBy?: InputMaybe<CoveredByOperationFilterInput>;
  /** When the event that caused the expense took place */
  eventTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  /** True if a company representative (administrator) has declined a Company flagged expense */
  isCompanyDeclined?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ExpenseFilterInput>>;
  price?: InputMaybe<ComparableDecimalOperationFilterInput>;
  profileRuleType?: InputMaybe<ProfileRuleIdEnumOperationFilterInput>;
  /** When the expense was registered by phonero (calls can be placed some time before the expense is registered. */
  registrationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  subCategory?: InputMaybe<ExpenseSubCategoryOperationFilterInput>;
  when?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type ExpensePeriodSortInput = {
  /** Sum of expenses covered by company */
  companySum?: InputMaybe<SortEnumType>;
  /** Start of expense period */
  fromDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Number of company expenses */
  numberOfCompanyItems?: InputMaybe<SortEnumType>;
  /** Number of private expenses */
  numberOfPrivateItems?: InputMaybe<SortEnumType>;
  /** Sum of expenses covered by private */
  privateSum?: InputMaybe<SortEnumType>;
  /** End of expense period */
  toDate?: InputMaybe<SortEnumType>;
};

/** Set of Expenses */
export type ExpenseSet = IExpenseSet & {
  __typename: 'ExpenseSet';
  /** List of categories expenses consists of */
  categories?: Maybe<Array<Maybe<ExpenseCategory>>>;
  /** Sum of expenses covered by company */
  companySum: Scalars['Decimal']['output'];
  /** Returns a list of expenses */
  expenses?: Maybe<ExpensesConnection>;
  /** Aggregated expenses by category for the list of categories that is supplied */
  expensesByCategory: Array<Maybe<ExpenseSetByCategory>>;
  /** Cost Sharing is enabled on this mobile subscription */
  isEnabled: Scalars['Boolean']['output'];
  /** Number of company expenses */
  numberOfCompanyItems: Scalars['Int']['output'];
  /** Number of private expenses */
  numberOfPrivateItems: Scalars['Int']['output'];
  /** Sum of expenses covered by private */
  privateSum: Scalars['Decimal']['output'];
};


/** Set of Expenses */
export type ExpenseSetExpensesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ExpenseSortInput>>;
  where?: InputMaybe<ExpenseFilterInput>;
};


/** Set of Expenses */
export type ExpenseSetExpensesByCategoryArgs = {
  categories?: InputMaybe<Array<ExpenseSubCategory>>;
};

/** Sum of expenses for Category */
export type ExpenseSetByCategory = {
  __typename: 'ExpenseSetByCategory';
  /** Detailed expense categories */
  category?: Maybe<ExpenseSubCategory>;
  /** Sum of expenses assigned to company */
  companySum?: Maybe<Scalars['Decimal']['output']>;
  /** Returns a list of expenses */
  expenses?: Maybe<ExpensesConnection>;
  /** Sum of expenses assigned to owner of subscription */
  privateSum?: Maybe<Scalars['Decimal']['output']>;
};


/** Sum of expenses for Category */
export type ExpenseSetByCategoryExpensesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ExpenseSortInput>>;
  where?: InputMaybe<ExpenseFilterInput>;
};

export type ExpenseSortInput = {
  bNumber?: InputMaybe<SortEnumType>;
  category?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  companyComment?: InputMaybe<SortEnumType>;
  /** Comment for why a company representative (administrator) declined a Company flagged expense */
  companyDeclinedComment?: InputMaybe<SortEnumType>;
  /** When a Company flagged expense was declined, null if not declined */
  companyDeclinedDate?: InputMaybe<SortEnumType>;
  coveredBy?: InputMaybe<SortEnumType>;
  /** When the event that caused the expense took place */
  eventTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** True if a company representative (administrator) has declined a Company flagged expense */
  isCompanyDeclined?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  profileRuleType?: InputMaybe<SortEnumType>;
  /** When the expense was registered by phonero (calls can be placed some time before the expense is registered. */
  registrationTime?: InputMaybe<SortEnumType>;
  subCategory?: InputMaybe<SortEnumType>;
  when?: InputMaybe<SortEnumType>;
};

export enum ExpenseSubCategory {
  ContentService = 'CONTENT_SERVICE',
  /** Credit expenses that reduces cost. */
  Credit = 'CREDIT',
  DataWhenAbroad = 'DATA_WHEN_ABROAD',
  /** @deprecated Use ExtraDataDomestic or ExtraDataInternational  */
  ExtraData = 'EXTRA_DATA',
  ExtraDataDomestic = 'EXTRA_DATA_DOMESTIC',
  ExtraDataInternational = 'EXTRA_DATA_INTERNATIONAL',
  ForeignTrafficOther = 'FOREIGN_TRAFFIC_OTHER',
  MessageFromAbroad = 'MESSAGE_FROM_ABROAD',
  MessageToAbroad = 'MESSAGE_TO_ABROAD',
  /** Other uncategorized expenses, containing subscriptions with monthly and establishment fees */
  Other = 'OTHER',
  VoiceFromAbroad = 'VOICE_FROM_ABROAD',
  VoiceToAbroad = 'VOICE_TO_ABROAD'
}

export type ExpenseSubCategoryOperationFilterInput = {
  eq?: InputMaybe<ExpenseSubCategory>;
  in?: InputMaybe<Array<ExpenseSubCategory>>;
  neq?: InputMaybe<ExpenseSubCategory>;
  nin?: InputMaybe<Array<ExpenseSubCategory>>;
};

export type ExpenseSummarySubCategory = {
  __typename: 'ExpenseSummarySubCategory';
  /** Accumulated company expenses for the current period (month) */
  companySum: Scalars['Decimal']['output'];
  /** Accumulated company expenses for the current period (month), modified by employee */
  companySumModifiedByEmployee: Scalars['Decimal']['output'];
  /** Indicates if the company has declined, any request by the employee, regarding who is responsible/covers for an expense. Typically this means that the employee must cover those expenses. */
  hasCompanyDeclinedExpenses: Scalars['Boolean']['output'];
  /** Accumulated private expenses for the current period (month) */
  privateSum: Scalars['Decimal']['output'];
  subCategory: ExpenseSubCategory;
};

/** A connection to a list of items. */
export type ExpensesConnection = {
  __typename: 'ExpensesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ExpensesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Expense>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExpensesEdge = {
  __typename: 'ExpensesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Expense>;
};

export enum ExportFormat {
  Agresso = 'AGRESSO',
  Csv = 'CSV',
  Excel = 'EXCEL',
  HuldtLillevik = 'HULDT_LILLEVIK',
  Sap = 'SAP',
  Visma = 'VISMA',
  VismaHrm = 'VISMA_HRM',
  VismaNetPayroll = 'VISMA_NET_PAYROLL'
}

export type ExportFormatAgressoParamsInput = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
};

export type ExportFormatSapParamsInput = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  costPlace?: InputMaybe<Scalars['String']['input']>;
  kElement4?: InputMaybe<Scalars['String']['input']>;
  kElement5?: InputMaybe<Scalars['String']['input']>;
  kElement6?: InputMaybe<Scalars['String']['input']>;
  kElement7?: InputMaybe<Scalars['String']['input']>;
  overTurn?: InputMaybe<Scalars['String']['input']>;
};

export enum ExportKind {
  ContentByUser = 'CONTENT_BY_USER',
  ContentDetailByUser = 'CONTENT_DETAIL_BY_USER',
  OverviewByUser = 'OVERVIEW_BY_USER',
  Profile = 'PROFILE',
  TrafficByUser = 'TRAFFIC_BY_USER',
  TrafficDetailByUser = 'TRAFFIC_DETAIL_BY_USER'
}

export type ExtraDataPackage = IProduct & IService & {
  __typename: 'ExtraDataPackage';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  activationDateTime?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  codeWord?: Maybe<Scalars['String']['output']>;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  size?: Maybe<ExtraDataSize>;
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  state: ApiProductState;
  type?: Maybe<ExtraDataType>;
  vippsPayment: Scalars['Boolean']['output'];
};

export type ExtraDataPackageOfferingProduct = IProduct & OfferingProduct & {
  __typename: 'ExtraDataPackageOfferingProduct';
  activeOn?: Maybe<RelatedSubscriptionConnection>;
  available?: Maybe<RelatedSubscriptionConnection>;
  billingProductId: Scalars['Int']['output'];
  campaign?: Maybe<Scalars['String']['output']>;
  canDeactivateOn?: Maybe<RelatedSubscriptionConnection>;
  category: ApiProductCategory;
  countries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  extraDataRollover: Scalars['Boolean']['output'];
  flagUsageModify: Scalars['Boolean']['output'];
  flagUsageNew: Scalars['Boolean']['output'];
  freeOccurrences: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  maxOccurrences?: Maybe<Scalars['Int']['output']>;
  mbb: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  offeringId: Scalars['ID']['output'];
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  roamingDurationDays?: Maybe<Scalars['Int']['output']>;
  separator: Scalars['Boolean']['output'];
  showVipps: Scalars['Boolean']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  sortOrder: Scalars['Int']['output'];
  state: ApiProductState;
  theme?: Maybe<Scalars['String']['output']>;
  type: Scalars['Int']['output'];
  upload: Scalars['Int']['output'];
  vippsOnly: Scalars['Boolean']['output'];
};


export type ExtraDataPackageOfferingProductActiveOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ExtraDataPackageOfferingProductAvailableArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ExtraDataPackageOfferingProductCanDeactivateOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ExtraDataPackageProduct = IProduct & {
  __typename: 'ExtraDataPackageProduct';
  campaign?: Maybe<Scalars['String']['output']>;
  category: ApiProductCategory;
  countries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  extraDataRollover: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  mbb: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  roamingDurationDays?: Maybe<Scalars['Int']['output']>;
  separator: Scalars['Boolean']['output'];
  showVipps: Scalars['Boolean']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  sortOrder: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
  subscriptions?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  type: Scalars['Int']['output'];
  upload: Scalars['Int']['output'];
  vippsOnly: Scalars['Boolean']['output'];
};

export type ExtraDataSize = {
  __typename: 'ExtraDataSize';
  bytes?: Maybe<Scalars['Decimal']['output']>;
  gigaBytes?: Maybe<Scalars['Decimal']['output']>;
  hours?: Maybe<Scalars['Long']['output']>;
  kiloBytes?: Maybe<Scalars['Decimal']['output']>;
  megaBytes?: Maybe<Scalars['Decimal']['output']>;
  type: ExtraDataSizeType;
  value: Scalars['Long']['output'];
};

export enum ExtraDataSizeType {
  Gb = 'GB',
  Hour = 'HOUR',
  Mb = 'MB'
}

export enum ExtraDataType {
  Extraboost = 'EXTRABOOST',
  Extradataeea = 'EXTRADATAEEA',
  Extradatainternational = 'EXTRADATAINTERNATIONAL',
  Speedboost = 'SPEEDBOOST'
}

export type FamilyBucketContract = IContract & {
  __typename: 'FamilyBucketContract';
  contractId: Scalars['Int']['output'];
  contractType: ApiContractType;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainContractProductId?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<FamilyBucketSubscription>;
};

export type FamilyBucketData = IProduct & IService & {
  __typename: 'FamilyBucketData';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  capacity: Scalars['Decimal']['output'];
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureActivationDate?: Maybe<Scalars['DateTime']['output']>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  size: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type FamilyBucketDataOfferingProduct = IProduct & OfferingProduct & {
  __typename: 'FamilyBucketDataOfferingProduct';
  activeOn?: Maybe<RelatedSubscriptionConnection>;
  available?: Maybe<RelatedSubscriptionConnection>;
  canDeactivateOn?: Maybe<RelatedSubscriptionConnection>;
  capacity: Scalars['Decimal']['output'];
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  flagUsageModify: Scalars['Boolean']['output'];
  flagUsageNew: Scalars['Boolean']['output'];
  freeOccurrences: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  maxOccurrences?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  offeringId: Scalars['ID']['output'];
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
  upload: Scalars['Int']['output'];
};


export type FamilyBucketDataOfferingProductActiveOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyBucketDataOfferingProductAvailableArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyBucketDataOfferingProductCanDeactivateOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FamilyBucketDataProduct = IProduct & {
  __typename: 'FamilyBucketDataProduct';
  capacity: Scalars['Decimal']['output'];
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
  subscriptions?: Maybe<Scalars['String']['output']>;
  upload: Scalars['Int']['output'];
};

export type FamilyBucketSubscription = IProduct & IService & {
  __typename: 'FamilyBucketSubscription';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  activeSubscriptions?: Maybe<MobileSubscriptionConnection>;
  category: ApiProductCategory;
  contractCategory?: Maybe<OfferingContractCategory>;
  contractPriceTypeId: Scalars['Short']['output'];
  contractType: ContractType;
  costsByInterval?: Maybe<CostSetInterval>;
  /** Total data capacity for bucket as of now, will increase as fee increases. */
  currentDataCapacity: Scalars['Long']['output'];
  customer?: Maybe<Customer>;
  customerAgreementOfferingId: Scalars['ID']['output'];
  customerId: Scalars['ID']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  demo: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  familyId: Scalars['String']['output'];
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  /** Restrict modifications to users that are administrators at the level this subscription resides */
  isReadOnly: Scalars['Boolean']['output'];
  lastUpdated: Scalars['DateTime']['output'];
  monthlyPrice: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  pcrfSharingGroupDataUsage?: Maybe<PcrfDataUsage>;
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  /** @deprecated Use name */
  productName?: Maybe<Scalars['String']['output']>;
  /** Returns a set of products present or available for the given subscription */
  products?: Maybe<Array<ProductUnionType>>;
  registrationDate?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};


export type FamilyBucketSubscriptionActiveSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  rootCustomerId?: InputMaybe<Scalars['ID']['input']>;
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};


export type FamilyBucketSubscriptionCostsByIntervalArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  type?: InputMaybe<CostMapping>;
};


export type FamilyBucketSubscriptionProductsArgs = {
  categories?: InputMaybe<Array<InputMaybe<ApiProductCategory>>>;
  order?: InputMaybe<Array<IProductSortInput>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  states?: InputMaybe<Array<InputMaybe<ApiProductState>>>;
};

/** A connection to a list of items. */
export type FamilyBucketSubscriptionConnection = {
  __typename: 'FamilyBucketSubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FamilyBucketSubscriptionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<FamilyBucketSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FamilyBucketSubscriptionEdge = {
  __typename: 'FamilyBucketSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FamilyBucketSubscription>;
};

export type FamilyBucketSubscriptionFilterInput = {
  activationDate?: InputMaybe<StringFilterTypeFilterInput>;
  and?: InputMaybe<Array<FamilyBucketSubscriptionFilterInput>>;
  id?: InputMaybe<StringFilterTypeFilterInput>;
  name?: InputMaybe<StringFilterTypeFilterInput>;
  or?: InputMaybe<Array<FamilyBucketSubscriptionFilterInput>>;
};

export type FamilyBucketUpgradeFee = IProduct & IService & {
  __typename: 'FamilyBucketUpgradeFee';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  capacity: Scalars['Decimal']['output'];
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  feeActivationTimestamp?: Maybe<Scalars['DateTime']['output']>;
  futureActivationDate?: Maybe<Scalars['DateTime']['output']>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  isPartOfCurrentBucketPeriod?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  size: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type FamilyBucketUpgradeFeeProduct = IProduct & {
  __typename: 'FamilyBucketUpgradeFeeProduct';
  capacity: Scalars['Decimal']['output'];
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  download: Scalars['Int']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  priceId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  size: Scalars['Decimal']['output'];
  /** Provides additional information related to package data size */
  sizeFlags: Array<Maybe<DataPackageSizeMetadataFlag>>;
  sizeInBytes: Scalars['Decimal']['output'];
  sizeType?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
  subscriptions?: Maybe<Scalars['String']['output']>;
  upload: Scalars['Int']['output'];
};

export type FamilyBucketUser = IProduct & IService & {
  __typename: 'FamilyBucketUser';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  familyBucketSubscription?: Maybe<FamilyBucketSubscription>;
  familyBucketSubscriptionId?: Maybe<Scalars['ID']['output']>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type FamilyBucketUserProduct = IProduct & {
  __typename: 'FamilyBucketUserProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export enum Feature {
  Addmobilesubscription = 'ADDMOBILESUBSCRIPTION',
  Datapackagehack = 'DATAPACKAGEHACK',
  Hasbreachfeeaccess = 'HASBREACHFEEACCESS',
  Hascustomertreeaccess = 'HASCUSTOMERTREEACCESS',
  Hasdirectoryinformationaccess = 'HASDIRECTORYINFORMATIONACCESS',
  Hasreleasenumberaccess = 'HASRELEASENUMBERACCESS',
  Hasrestricteddirectoryinformationaccess = 'HASRESTRICTEDDIRECTORYINFORMATIONACCESS'
}

export type FieldRequiredError = Error & {
  __typename: 'FieldRequiredError';
  fieldName: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** A set of customers represented with the CustomerLite model, filtered so that models the current user is not authorized to retrieve are removed from the response, instead of resulting in an authorization error. */
export type FilteredCustomerLiteSet = {
  __typename: 'FilteredCustomerLiteSet';
  /** Whether any members of the set were removed because the current user is not authorized to retrieve them. */
  isComplete: Scalars['Boolean']['output'];
  /** The CustomerLite models the current user is authorized to retrieve. */
  members?: Maybe<Array<Maybe<CustomerLite>>>;
};

export type GeneralContract = IContract & {
  __typename: 'GeneralContract';
  contractId: Scalars['Int']['output'];
  contractType: ApiContractType;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainContractProductId?: Maybe<Scalars['Int']['output']>;
};

export type GeneralOfferingProduct = IProduct & OfferingProduct & {
  __typename: 'GeneralOfferingProduct';
  activeOn?: Maybe<RelatedSubscriptionConnection>;
  available?: Maybe<RelatedSubscriptionConnection>;
  canDeactivateOn?: Maybe<RelatedSubscriptionConnection>;
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  flagUsageModify: Scalars['Boolean']['output'];
  flagUsageNew: Scalars['Boolean']['output'];
  freeOccurrences: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  maxOccurrences?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  offeringId: Scalars['ID']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
};


export type GeneralOfferingProductActiveOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type GeneralOfferingProductAvailableArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type GeneralOfferingProductCanDeactivateOnArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type GeneralProduct = IProduct & {
  __typename: 'GeneralProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type GenericSecondaryState = InternalPortingSecondaryState | MoveSecondaryState | PortingInSecondaryState | ReleaseSubscriptionSecondaryState | SubscriptionStatusSecondaryState;

export enum GroupTypeEnum {
  Abroad = 'ABROAD',
  Databoost = 'DATABOOST',
  Eu = 'EU'
}

export type HiddenProduct = IProduct & {
  __typename: 'HiddenProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
};

export type IContract = {
  contractId: Scalars['Int']['output'];
  contractType: ApiContractType;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainContractProductId?: Maybe<Scalars['Int']['output']>;
};

export type IContractFilterInput = {
  and?: InputMaybe<Array<IContractFilterInput>>;
  contractType?: InputMaybe<ApiContractTypeOperationFilterInput>;
  id?: InputMaybe<StringFilterTypeFilterInput>;
  or?: InputMaybe<Array<IContractFilterInput>>;
};

export type ICost = CompanyCost | PrivateCost;

export type IExpenseSet = {
  /** List of categories expenses consists of */
  categories?: Maybe<Array<Maybe<ExpenseCategory>>>;
  /** Sum of expenses covered by company */
  companySum: Scalars['Decimal']['output'];
  /** Returns a list of expenses */
  expenses?: Maybe<ExpensesConnection>;
  /** Aggregated expenses by category for the list of categories that is supplied */
  expensesByCategory: Array<Maybe<ExpenseSetByCategory>>;
  /** Cost Sharing is enabled on this mobile subscription */
  isEnabled: Scalars['Boolean']['output'];
  /** Number of company expenses */
  numberOfCompanyItems: Scalars['Int']['output'];
  /** Number of private expenses */
  numberOfPrivateItems: Scalars['Int']['output'];
  /** Sum of expenses covered by private */
  privateSum: Scalars['Decimal']['output'];
};


export type IExpenseSetExpensesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type IExpenseSetExpensesByCategoryArgs = {
  categories?: InputMaybe<Array<ExpenseSubCategory>>;
};

export type IOfferingProduct = AutoPackageOfferingProduct | DataPackageOfferingProduct | ExtraDataPackageOfferingProduct | FamilyBucketDataOfferingProduct | GeneralOfferingProduct;

export type IOfferingProductSortInput = {
  category?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  establishmentPrice?: InputMaybe<SortEnumType>;
  flagUsageModify?: InputMaybe<SortEnumType>;
  flagUsageNew?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isMandatory?: InputMaybe<SortEnumType>;
  isRecommended?: InputMaybe<SortEnumType>;
  maxOccurrences?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  offeringId?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  productId?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
};

export type IProduct = {
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
};

export type IProductSortInput = {
  category?: InputMaybe<SortEnumType>;
  deactivationRule?: InputMaybe<DeactivationSortInput>;
  description?: InputMaybe<SortEnumType>;
  establishmentPrice?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isLocked?: InputMaybe<SortEnumType>;
  isMandatory?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  productId?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
};

export type IService = {
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Info about when the product can be deactivated if the product is active. */
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
};

export type IStreetAddress = {
  houseLetter?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  postalPlace?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
};

export type InternalPortingSecondaryState = {
  __typename: 'InternalPortingSecondaryState';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  newAlias?: Maybe<Scalars['String']['output']>;
  portingError?: Maybe<PortingError>;
  priority?: Maybe<Scalars['Int']['output']>;
  state: SecondaryState;
};

export type IntervalUsageSet = {
  __typename: 'IntervalUsageSet';
  customerIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  from: Scalars['DateTime']['output'];
  payload?: Maybe<Array<Maybe<UsageSet>>>;
  to: Scalars['DateTime']['output'];
  whens?: Maybe<Array<Scalars['DateTime']['output']>>;
};

export type InvalidArgumentError = Error & {
  __typename: 'InvalidArgumentError';
  message: Scalars['String']['output'];
};

export type InvalidVippsIdError = Error & {
  __typename: 'InvalidVippsIDError';
  message: Scalars['String']['output'];
};

export type Invoice = {
  __typename: 'Invoice';
  accountId?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  dateString?: Maybe<Scalars['String']['output']>;
  /** @deprecated Not in use; depends on current time; use ExpiryDate to determine if the invoice has expired. */
  expired: Scalars['Boolean']['output'];
  expiryDate: Scalars['DateTime']['output'];
  expiryDateString?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPaid: Scalars['Boolean']['output'];
  kid?: Maybe<Scalars['String']['output']>;
  paidAmount: Scalars['Decimal']['output'];
  paymentCompany: Scalars['Int']['output'];
  restSum: Scalars['Decimal']['output'];
  status?: Maybe<Scalars['String']['output']>;
  sum: Scalars['Decimal']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type InvoiceConnection = {
  __typename: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InvoiceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export type InvoiceFilterInput = {
  accountId?: InputMaybe<StringOperationFilterInput>;
  accountNumber?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<InvoiceFilterInput>>;
  companyName?: InputMaybe<StringOperationFilterInput>;
  customerId?: InputMaybe<StringOperationFilterInput>;
  date?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  dateString?: InputMaybe<StringOperationFilterInput>;
  expired?: InputMaybe<BooleanOperationFilterInput>;
  expiryDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  expiryDateString?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  isPaid?: InputMaybe<BooleanOperationFilterInput>;
  kid?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InvoiceFilterInput>>;
  paidAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  paymentCompany?: InputMaybe<ComparableInt32OperationFilterInput>;
  restSum?: InputMaybe<ComparableDecimalOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  sum?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export type InvoiceSortInput = {
  accountId?: InputMaybe<SortEnumType>;
  accountNumber?: InputMaybe<SortEnumType>;
  companyName?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  dateString?: InputMaybe<SortEnumType>;
  expired?: InputMaybe<SortEnumType>;
  expiryDate?: InputMaybe<SortEnumType>;
  expiryDateString?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isPaid?: InputMaybe<SortEnumType>;
  kid?: InputMaybe<SortEnumType>;
  paidAmount?: InputMaybe<SortEnumType>;
  paymentCompany?: InputMaybe<SortEnumType>;
  restSum?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  sum?: InputMaybe<SortEnumType>;
};

export type ListFilterInputTypeOfRefundFilterInput = {
  all?: InputMaybe<RefundFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RefundFilterInput>;
  some?: InputMaybe<RefundFilterInput>;
};

export enum LockNumberSource {
  Available = 'AVAILABLE',
  Reserved = 'RESERVED'
}

export type LockNumbersInput = {
  count: Scalars['Int']['input'];
  customerId: Scalars['ID']['input'];
  source?: InputMaybe<Array<LockNumberSource>>;
};

export type LockReservedNumbersInput = {
  customerId: Scalars['ID']['input'];
  numbers?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MaintenanceMessage = {
  __typename: 'MaintenanceMessage';
  bannerSummary?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  messageStartAt: Scalars['DateTime']['output'];
};

export type MbnNordicomContract = IContract & {
  __typename: 'MbnNordicomContract';
  contractId: Scalars['Int']['output'];
  contractType: ApiContractType;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainContractProductId?: Maybe<Scalars['Int']['output']>;
};

export type MbnSolution = {
  __typename: 'MbnSolution';
  contractProductId: Scalars['ID']['output'];
  /** Owner of the MbnSolution */
  customerId: Scalars['ID']['output'];
  /** Owner of the MbnSolution */
  customerName: Scalars['String']['output'];
  mbnSolutionContractId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The number of MbnUsers on the requested customer which is connected to the MbnSolution. */
  numberOfSubscriptions: Scalars['Int']['output'];
  /** @deprecated Returns the price of the mbn user product on the Mobilt Bedriftsnett Nordicom offering. The mbn user products depends on the price on the offering the mobile subscription has.  */
  price: Scalars['Decimal']['output'];
  productDescription?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
};

export type MbnUser = IProduct & IService & {
  __typename: 'MbnUser';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  mbnSolutionContractId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type MbnUserProduct = IProduct & {
  __typename: 'MbnUserProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type Me = {
  __typename: 'Me';
  applicationLinks?: Maybe<Array<Maybe<ApplicationLink>>>;
  approvals?: Maybe<ApprovalConnection>;
  approver: Scalars['Boolean']['output'];
  claims?: Maybe<Array<Maybe<MeClaim>>>;
  customers?: Maybe<Array<Customer>>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manages?: Maybe<Array<Customer>>;
  name?: Maybe<Scalars['String']['output']>;
  orders?: Maybe<OrderDetailConnection>;
  personId: Scalars['ID']['output'];
  phonerNumber?: Maybe<Scalars['String']['output']>;
  receipt?: Maybe<Receipt>;
  receipts?: Maybe<ReceiptConnection>;
  subscriptionId?: Maybe<Array<Scalars['ID']['output']>>;
  subscriptions?: Maybe<Array<MobileSubscription>>;
  userName?: Maybe<Scalars['String']['output']>;
};


export type MeApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ApprovalSortInput>>;
  where?: InputMaybe<ApprovalFilterInput>;
};


export type MeCustomersArgs = {
  where?: InputMaybe<CustomerFilterInput>;
};


export type MeManagesArgs = {
  order?: InputMaybe<Array<CustomerSortInput>>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type MeOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrderDetailSortInput>>;
  where?: InputMaybe<OrderDetailFilterInput>;
};


export type MeReceiptArgs = {
  contractProductId: Scalars['ID']['input'];
};


export type MeReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
  where?: InputMaybe<ReceiptFilterInput>;
};

export type MeClaim = {
  __typename: 'MeClaim';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MobileBroadbandContract = IContract & {
  __typename: 'MobileBroadbandContract';
  contractId: Scalars['Int']['output'];
  contractType: ApiContractType;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainContractProductId?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<MobileSubscription>;
};


export type MobileBroadbandContractSubscriptionArgs = {
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};

export type MobileContract = IContract & {
  __typename: 'MobileContract';
  contractId: Scalars['Int']['output'];
  contractType: ApiContractType;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainContractProductId?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<MobileSubscription>;
};


export type MobileContractSubscriptionArgs = {
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};

export type MobileInsurance = IProduct & IService & {
  __typename: 'MobileInsurance';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type MobileInsuranceProduct = IProduct & {
  __typename: 'MobileInsuranceProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type MobileSubscription = IProduct & IService & {
  __typename: 'MobileSubscription';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  agreements?: Maybe<Array<Maybe<Agreement>>>;
  approvers?: Maybe<Array<Approver>>;
  /** @deprecated use Products([Available], [ExtraDataPackage]) */
  availableExtraDataPackages?: Maybe<Array<ExtraDataPackageProduct>>;
  billToCustomer?: Maybe<CustomerLite>;
  /** Id for the Customer that receives the invoice for this MobileSubscriptions. Access to this Customer might be restricted. */
  billToCustomerId: Scalars['ID']['output'];
  billingCustomer?: Maybe<BillingCustomer>;
  bindingExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  changeDataPackageImmediately: Scalars['Boolean']['output'];
  changeOrderApprovalRequired: Scalars['Boolean']['output'];
  contentServicesReportUrl?: Maybe<Scalars['Url']['output']>;
  contractCategory?: Maybe<OfferingContractCategory>;
  contractPriceTypeId: Scalars['Short']['output'];
  contractType: ContractType;
  costSharingEmployee?: Maybe<CostSharingEmployee>;
  costs: CostSet;
  costsByInterval?: Maybe<CostSetInterval>;
  customer?: Maybe<Customer>;
  customerAgreementOfferingId: Scalars['ID']['output'];
  customerId: Scalars['ID']['output'];
  /** @deprecated use Products([Active], [DataPackage]) */
  dataPackage?: Maybe<DataPackage>;
  /** @deprecated This field is no longer in use */
  dataPackageSize: Scalars['Int']['output'];
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  /** @deprecated Replaced by ExpectedActivation */
  delayedActivation?: Maybe<Scalars['DateTime']['output']>;
  demo: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  directoryInformation?: Maybe<DirectoryInformation>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  expectedActivation?: Maybe<Scalars['DateTime']['output']>;
  expenseSummary?: Maybe<Array<Maybe<CostSharingExpenseSummaryPeriod>>>;
  expensesByPeriod?: Maybe<CostSharingExpensePeriode>;
  expensesByType?: Maybe<ExpenseSet>;
  expensesForBedriftsPortalen?: Maybe<ExpenseSet>;
  familyBucketSubscription?: Maybe<FamilyBucketSubscription>;
  familyBucketSubscriptionId?: Maybe<Scalars['Int']['output']>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  hasEmployee: Scalars['Boolean']['output'];
  /** User associated with subscription has person roles on the customer tree the agreement is connected to. */
  hasRolesOnCustomerThatOwnsTheAgreement: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  includedDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use getSecondaryState */
  internalPorting?: Maybe<Porting>;
  /** Is the customer that receive the invoice same customer as the owner of this MobileSubscription. */
  isBillToCustomerAlsoOwningCustomer: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  lastUpdated: Scalars['DateTime']['output'];
  monthlyPrice: Scalars['Decimal']['output'];
  msisdn?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offeringProducts?: Maybe<Array<IOfferingProduct>>;
  orderEvents?: Maybe<OrderEventConnection>;
  orders?: Maybe<OrderDetailConnection>;
  pcrfDataUsage?: Maybe<PcrfDataUsage>;
  /** @deprecated Use getSecondaryState */
  porting?: Maybe<Porting>;
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  /** @deprecated Use name */
  productName?: Maybe<Scalars['String']['output']>;
  /** Returns a set of products present or available for the given subscription */
  products?: Maybe<Array<ProductUnionType>>;
  registrationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use getSecondaryState */
  releaseSubscription?: Maybe<ReleaseSubscription>;
  /** Roaming information like prices. */
  roaming: Roaming;
  rolesOnAgreementAndSubCustomer?: Maybe<Array<Maybe<PersonRole>>>;
  rolesOnAgreementAndSubCustomersOld?: Maybe<Array<Maybe<PersonRole>>>;
  runningCost: Scalars['Decimal']['output'];
  /** Informasjon om 90% varsling er sendt ut for nåværende måned */
  sapcNotification: Scalars['Boolean']['output'];
  secondaryStates?: Maybe<Array<GenericSecondaryState>>;
  simCardAddress?: Maybe<SimCardAddress>;
  state: ApiProductState;
  /** @deprecated Use SecondaryStates */
  subStates?: Maybe<Array<Maybe<MobileSubscriptionSubState>>>;
  subscriptionCosts: SubscriptionCostSet;
  subscriptionCostsByInterval?: Maybe<SubscriptionCostSetInterval>;
  userCannotChangeSubscription: Scalars['Boolean']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};


export type MobileSubscriptionCostsArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  type?: InputMaybe<Array<CostMapping>>;
};


export type MobileSubscriptionCostsByIntervalArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  type?: InputMaybe<CostMapping>;
};


export type MobileSubscriptionExpenseSummaryArgs = {
  input: CostSharingExpenseSummaryInput;
};


export type MobileSubscriptionExpensesByPeriodArgs = {
  input: CostSharingExpensesByPeriodInput;
  order?: InputMaybe<Array<ExpensePeriodSortInput>>;
};


export type MobileSubscriptionExpensesByTypeArgs = {
  expenseCategories?: InputMaybe<Array<ExpenseCategory>>;
  expenseSubCategories?: InputMaybe<Array<ExpenseSubCategory>>;
};


export type MobileSubscriptionOfferingProductsArgs = {
  filter?: InputMaybe<OfferingProductFilterInput>;
  order?: InputMaybe<Array<IOfferingProductSortInput>>;
};


export type MobileSubscriptionOrderEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MobileSubscriptionOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrderDetailSortInput>>;
  where?: InputMaybe<OrderDetailFilterInput>;
};


export type MobileSubscriptionProductsArgs = {
  categories?: InputMaybe<Array<InputMaybe<ApiProductCategory>>>;
  order?: InputMaybe<Array<IProductSortInput>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  states?: InputMaybe<Array<InputMaybe<ApiProductState>>>;
};


export type MobileSubscriptionSubscriptionCostsArgs = {
  from: Scalars['DateTime']['input'];
  paidBy?: InputMaybe<Array<PaidBy>>;
  to: Scalars['DateTime']['input'];
  type?: InputMaybe<Array<CostMapping>>;
};


export type MobileSubscriptionSubscriptionCostsByIntervalArgs = {
  from: Scalars['DateTime']['input'];
  paidBy?: InputMaybe<Array<PaidBy>>;
  to: Scalars['DateTime']['input'];
  type?: InputMaybe<CostMapping>;
};

/** A connection to a list of items. */
export type MobileSubscriptionConnection = {
  __typename: 'MobileSubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<MobileSubscriptionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<MobileSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum MobileSubscriptionContractType {
  Mobile = 'MOBILE',
  Mobilebroadband = 'MOBILEBROADBAND'
}

/** An edge in a connection. */
export type MobileSubscriptionEdge = {
  __typename: 'MobileSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MobileSubscription>;
};

export type MobileSubscriptionFilterInput = {
  activationDate?: InputMaybe<StringFilterTypeFilterInput>;
  and?: InputMaybe<Array<MobileSubscriptionFilterInput>>;
  contractProductId?: InputMaybe<StringFilterTypeFilterInput>;
  msisdn?: InputMaybe<StringFilterTypeFilterInput>;
  name?: InputMaybe<StringFilterTypeFilterInput>;
  or?: InputMaybe<Array<MobileSubscriptionFilterInput>>;
  productId?: InputMaybe<StringFilterTypeFilterInput>;
  /** Informasjon om 90% varsling er sendt ut for nåværende måned */
  sapcNotification?: InputMaybe<BoolFilterTypeFilterInput>;
};

export type MobileSubscriptionLite = {
  __typename: 'MobileSubscriptionLite';
  customerId: Scalars['ID']['output'];
  customerName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  msisdn?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type MobileSubscriptionLiteConnection = {
  __typename: 'MobileSubscriptionLiteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<MobileSubscriptionLiteEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<MobileSubscriptionLite>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MobileSubscriptionLiteEdge = {
  __typename: 'MobileSubscriptionLiteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MobileSubscriptionLite>;
};

export type MobileSubscriptionLiteSortInput = {
  customerId?: InputMaybe<SortEnumType>;
  customerName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  msisdn?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type MobileSubscriptionProductInput = {
  contractProductProfileIdForCostSharingUserProduct?: InputMaybe<Scalars['String']['input']>;
  costCodeForCostSharingUserProduct?: InputMaybe<Scalars['String']['input']>;
  employeeNumberForCostSharingUserProduct?: InputMaybe<Scalars['String']['input']>;
  familyBucketIdForFamilyBucketProducts?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
};

export type MobileSubscriptionSortInput = {
  dataPackageSize?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  msisdn?: InputMaybe<SortEnumType>;
  registrationDate?: InputMaybe<SortEnumType>;
  /** Informasjon om 90% varsling er sendt ut for nåværende måned */
  sapcNotification?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type MobileSubscriptionSubState = {
  __typename: 'MobileSubscriptionSubState';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  metadata?: Maybe<Scalars['Any']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  state: SecondaryState;
};

export type MobileSubscriptionsByCustomerParamsInput = {
  /** Filter subscriptions on bill to customer is identical to owning customer */
  billToCustomerIsOwningCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  contractTypes?: InputMaybe<Array<MobileSubscriptionContractType>>;
  /** Also include deactivated subscriptions within the lase week */
  includeNewlyDeactivated?: Scalars['Boolean']['input'];
  /** Include Subscriptions from sub customers */
  includeSubCustomers?: Scalars['Boolean']['input'];
};

export type Money = {
  __typename: 'Money';
  currency?: Maybe<Scalars['String']['output']>;
  value: Scalars['Decimal']['output'];
  valueAsString?: Maybe<Scalars['String']['output']>;
  vat: Scalars['Decimal']['output'];
};

export type MoneyFilterInput = {
  and?: InputMaybe<Array<MoneyFilterInput>>;
  currency?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MoneyFilterInput>>;
  value?: InputMaybe<ComparableDecimalOperationFilterInput>;
  valueAsString?: InputMaybe<StringOperationFilterInput>;
  vat?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export type Month = {
  __typename: 'Month';
  /** Month in range 1 to 12 where 1 is January */
  month: Scalars['Int']['output'];
  /** Year in format 'yyyy' like 2023 */
  year: Scalars['Int']['output'];
};

export type MoveSecondaryState = {
  __typename: 'MoveSecondaryState';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  moveFromCustomer?: Maybe<CustomerLite>;
  moveFromCustomerId?: Maybe<Scalars['String']['output']>;
  moveToCustomer?: Maybe<CustomerLite>;
  moveToCustomerId?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  state: SecondaryState;
};

export type MoveSubscriptionCancellationInput = {
  /** MobileSubscriptionId (ContractProductId) for subscription to cancel the move */
  subscriptionId: Scalars['ID']['input'];
};

export type MoveSubscriptionInput = {
  /** Specifies how cost sharing should be after the move. NULL means do not use cost sharing. If the contract being moved has a 24704 user product, it will be deactivated. */
  costSharing?: InputMaybe<CostSharingInfoInput>;
  /** ContractProductId of the 41000 Databøtte abonnement to use for the contract after the move. */
  familyBucketSubscriptionId?: InputMaybe<Scalars['ID']['input']>;
  /** True if you want to keep the subscriptions roles in the current agreement (and below in customer tree) after the move. */
  keepRolesInCustomerTreeBelowAgreement: Scalars['Boolean']['input'];
  /** ContractProductId of the 24100 Mobilt Bedriftsnett to use for the contract after the move. */
  mbnNordicomSubscriptionId?: InputMaybe<Scalars['ID']['input']>;
  /** Date and time for when the move is to done. If null the move is done mediately. */
  moveDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Destination customer for move operation */
  moveToCustomerId: Scalars['ID']['input'];
  /** MobileSubscriptionId (ContractProductId) for subscription to move */
  subscriptionId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename: 'Mutation';
  activateSimCard?: Maybe<OrderConfirmation>;
  addBarring?: Maybe<OrderConfirmation>;
  addCostControl?: Maybe<OrderConfirmation>;
  /** Add new cost sharing profile */
  addCostSharingProfile?: Maybe<CostSharingProfile>;
  addDataRollover?: Maybe<OrderConfirmation>;
  addDataUsageNotification?: Maybe<OrderConfirmation>;
  addExtraDataPackage?: Maybe<OrderConfirmation>;
  addFamilyBucketSubscription?: Maybe<OrderConfirmation>;
  addFamilyBucketUser?: Maybe<OrderConfirmation>;
  addMbnUser?: Maybe<OrderConfirmation>;
  addMobileSubscription?: Maybe<OrderConfirmation>;
  addSimCard?: Maybe<OrderConfirmation>;
  /** @deprecated Use submitBatch. This method has no support for reactivating future deactivated products.  */
  addUnlimitedMinutes?: Maybe<OrderConfirmation>;
  approveOrder?: Maybe<MutationResponse>;
  cancelDeactivateService?: Maybe<OrderConfirmation>;
  /** Move subscription between customers that share the same agreement */
  cancelMoveSubscription?: Maybe<OrderConfirmation>;
  cancelPayment: CancelPaymentPayload;
  /** Change existing cost sharing profile */
  changeCostSharingProfile?: Maybe<CostSharingProfile>;
  changeCostSharingSolutionSettings?: Maybe<CostSharingSolutionSettings>;
  changeDataPackage?: Maybe<OrderConfirmation>;
  changeExpenseItems: ChangeExpenseItemsResponse;
  changeFamilyBucketData?: Maybe<OrderConfirmation>;
  changeFamilyBucketSubscription?: Maybe<BatchConfirmation>;
  changeOffering?: Maybe<OrderConfirmation>;
  changeSipTrunk?: Maybe<OrderConfirmation>;
  changeSubscription?: Maybe<MobileSubscription>;
  deactivateCostSharingUser?: Maybe<OrderConfirmation>;
  deactivateService?: Maybe<DeactivateServiceOrderConfirmation>;
  /** Delete cost sharing profile */
  deleteCostSharingProfile?: Maybe<CostSharingProfile>;
  lockNumbers?: Maybe<Array<Maybe<ReservedNumber>>>;
  /** Method used for reserving a number when ordering a new subscription. */
  lockReservedNumbers?: Maybe<Array<Maybe<ReservedNumber>>>;
  /** Move subscription between customers that share the same agreement */
  moveSubscription?: Maybe<OrderConfirmation>;
  orderPaymentCallback: OrderPaymentCallbackPayload;
  orderSimCard?: Maybe<OrderConfirmation>;
  purchaseExtraDataPackage: PurchaseExtraDataPackagePayload;
  rejectOrder?: Maybe<MutationResponse>;
  /** Method used for cancelling a locked/reserved number in the order process. */
  releaseNumbers: Scalars['Boolean']['output'];
  resendEmbeddedSimEmail: Scalars['Boolean']['output'];
  sendReceipts: SendResult;
  submitBatch?: Maybe<BatchConfirmation>;
  submitMobileOrder?: Maybe<OrderConfirmation>;
  updateSimCard?: Maybe<MobileSubscription>;
  useAutoPackage?: Maybe<OrderConfirmation>;
};


export type MutationActivateSimCardArgs = {
  input: ActivateSimCardInput;
};


export type MutationAddBarringArgs = {
  input: AddBarringInput;
};


export type MutationAddCostControlArgs = {
  input: AddCostControlInput;
};


export type MutationAddCostSharingProfileArgs = {
  input: CostSharingAddProfileInput;
};


export type MutationAddDataRolloverArgs = {
  input: AddDataRolloverInput;
};


export type MutationAddDataUsageNotificationArgs = {
  input: AddDataUsageNotificationInput;
};


export type MutationAddExtraDataPackageArgs = {
  input: AddExtraDataPackageInput;
};


export type MutationAddFamilyBucketSubscriptionArgs = {
  input: AddFamilyBucketSubscriptionInput;
};


export type MutationAddFamilyBucketUserArgs = {
  input: AddFamilyBucketUserInput;
};


export type MutationAddMbnUserArgs = {
  input: AddMbnUserInput;
};


export type MutationAddMobileSubscriptionArgs = {
  input: AddMobileSubscriptionInput;
};


export type MutationAddSimCardArgs = {
  input: AddSimCardInput;
};


export type MutationAddUnlimitedMinutesArgs = {
  input: AddUnlimitedMinutesInput;
};


export type MutationApproveOrderArgs = {
  input: ApproveOrderInput;
};


export type MutationCancelDeactivateServiceArgs = {
  input: CancelDeactivateServiceInput;
};


export type MutationCancelMoveSubscriptionArgs = {
  input: MoveSubscriptionCancellationInput;
};


export type MutationCancelPaymentArgs = {
  input: CancelPaymentInput;
};


export type MutationChangeCostSharingProfileArgs = {
  input: CostSharingChangeProfileInput;
};


export type MutationChangeCostSharingSolutionSettingsArgs = {
  input: CostSharingSolutionSettingsInput;
};


export type MutationChangeDataPackageArgs = {
  input: ChangeDataPackageInput;
};


export type MutationChangeExpenseItemsArgs = {
  input: ChangeExpenseInput;
};


export type MutationChangeFamilyBucketDataArgs = {
  input: ChangeFamilyBucketDataInput;
};


export type MutationChangeFamilyBucketSubscriptionArgs = {
  input: ChangeFamilyBucketSubscriptionInput;
};


export type MutationChangeOfferingArgs = {
  input: ChangeOfferingInput;
};


export type MutationChangeSipTrunkArgs = {
  input: ChangeSipTrunkInput;
};


export type MutationChangeSubscriptionArgs = {
  input: ChangeSubscriptionInput;
};


export type MutationDeactivateCostSharingUserArgs = {
  input: DeactivateCostSharingUserInput;
};


export type MutationDeactivateServiceArgs = {
  input: DeactivateServiceInput;
};


export type MutationDeleteCostSharingProfileArgs = {
  input: CostSharingDeleteProfileInput;
};


export type MutationLockNumbersArgs = {
  input: LockNumbersInput;
};


export type MutationLockReservedNumbersArgs = {
  input: LockReservedNumbersInput;
};


export type MutationMoveSubscriptionArgs = {
  input: MoveSubscriptionInput;
};


export type MutationOrderPaymentCallbackArgs = {
  input: OrderPaymentCallbackInput;
};


export type MutationOrderSimCardArgs = {
  input: OrderSimCardInput;
};


export type MutationPurchaseExtraDataPackageArgs = {
  input: PurchaseExtraDataPackageInput;
};


export type MutationRejectOrderArgs = {
  input: RejectOrderInput;
};


export type MutationReleaseNumbersArgs = {
  input: ReleaseNumbersInput;
};


export type MutationResendEmbeddedSimEmailArgs = {
  input: ResendEmbeddedSimCardEmailInput;
};


export type MutationSendReceiptsArgs = {
  contractProductIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sendTo?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationSubmitBatchArgs = {
  input: SubmitBatchInput;
};


export type MutationSubmitMobileOrderArgs = {
  input: SubmitMobileOrderInput;
};


export type MutationUpdateSimCardArgs = {
  input: UpdateSimCardInput;
};


export type MutationUseAutoPackageArgs = {
  input: UseAutoPackageInput;
};

export type MutationResponse = {
  __typename: 'MutationResponse';
  approvalId: Scalars['Int']['output'];
};

export type NotFoundOrNoAccessError = Error & {
  __typename: 'NotFoundOrNoAccessError';
  message: Scalars['String']['output'];
};

export type NotImplementedError = Error & {
  __typename: 'NotImplementedError';
  message: Scalars['String']['output'];
};

export type NotificationSettings = {
  __typename: 'NotificationSettings';
  email: Scalars['Boolean']['output'];
  sms: Scalars['Boolean']['output'];
};

export type NotificationSettingsInput = {
  email: Scalars['Boolean']['input'];
  sms: Scalars['Boolean']['input'];
};

export type NotificationSettingsSortInput = {
  email?: InputMaybe<SortEnumType>;
  sms?: InputMaybe<SortEnumType>;
};

export type NumberInformationForInternalPorting = {
  __typename: 'NumberInformationForInternalPorting';
  msisdn?: Maybe<Scalars['String']['output']>;
  priorityVoice?: Maybe<Scalars['Boolean']['output']>;
  releaseSubscription?: Maybe<ReleaseSubscription>;
};

export type NumberSeriesRange = {
  __typename: 'NumberSeriesRange';
  count: Scalars['Int']['output'];
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
};

export enum NumberType {
  Internalporting = 'INTERNALPORTING',
  Newnumber = 'NEWNUMBER',
  Porting = 'PORTING',
  Userchange = 'USERCHANGE'
}

export enum NumberValueType {
  Goldnumber = 'GOLDNUMBER',
  Novalue = 'NOVALUE',
  Numberseries = 'NUMBERSERIES',
  Standardnumber = 'STANDARDNUMBER',
  Wishnumbers = 'WISHNUMBERS'
}

export type Offering = {
  __typename: 'Offering';
  bindingLength: BindingLength;
  bindingMonths: Scalars['Int']['output'];
  contractCategory: OfferingContractCategory;
  contractPriceTypeId: Scalars['Short']['output'];
  contractType: ContractType;
  customerId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** Returns a set of available products for a given offering. */
  products?: Maybe<Array<IOfferingProduct>>;
  selfServiceEnabled: Scalars['Boolean']['output'];
};


export type OfferingProductsArgs = {
  filter?: InputMaybe<OfferingProductFilterInput>;
  order?: InputMaybe<Array<IOfferingProductSortInput>>;
};

export enum OfferingContractCategory {
  Familybucket = 'FAMILYBUCKET',
  M2M = 'M2M',
  Mbnnordicom = 'MBNNORDICOM',
  Mobile = 'MOBILE',
  Mobilebroadband = 'MOBILEBROADBAND',
  Other = 'OTHER'
}

export type OfferingFilterInput = {
  contractCategories?: InputMaybe<Array<OfferingContractCategory>>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceChange?: InputMaybe<Scalars['Boolean']['input']>;
  selfService?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferingProduct = {
  category: ApiProductCategory;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  flagUsageModify: Scalars['Boolean']['output'];
  flagUsageNew: Scalars['Boolean']['output'];
  freeOccurrences: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isMandatory: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  maxOccurrences?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  offeringId: Scalars['ID']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
};

export type OfferingProductFilterInput = {
  categories?: InputMaybe<Array<ApiProductCategory>>;
  hasFlagUsageModify?: InputMaybe<Scalars['Boolean']['input']>;
  hasFlagUsageNew?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreFlagUsageNew?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OrderConfirmation = {
  __typename: 'OrderConfirmation';
  orderId: Scalars['Uuid']['output'];
};

export type OrderDetail = {
  __typename: 'OrderDetail';
  /** Locally calculated value which returns the newest date in any of the other fields within the OrderDetail */
  _lastUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use CompanyDetail.Approval */
  approval?: Maybe<Approval>;
  batchId?: Maybe<Scalars['Uuid']['output']>;
  currentProductId?: Maybe<Scalars['ID']['output']>;
  currentProductName?: Maybe<Scalars['String']['output']>;
  currentProductPrice?: Maybe<Scalars['Decimal']['output']>;
  currentProductSize?: Maybe<Scalars['Decimal']['output']>;
  currentProductSizeType?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['ID']['output'];
  expectedActivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Uuid']['output'];
  isPaidByUser?: Maybe<Scalars['Boolean']['output']>;
  newContractProductExpectedActivationDate?: Maybe<Scalars['DateTime']['output']>;
  newContractProductId?: Maybe<Scalars['Int']['output']>;
  orderStatusMessage?: Maybe<Scalars['String']['output']>;
  orderTypeId: OrderType;
  receipt?: Maybe<Receipt>;
  registrationDateTime?: Maybe<Scalars['DateTime']['output']>;
  registrationPerson?: Maybe<Person>;
  registrationPersonId?: Maybe<Scalars['Int']['output']>;
  requestedProductId?: Maybe<Scalars['ID']['output']>;
  requestedProductName?: Maybe<Scalars['String']['output']>;
  requestedProductPrice?: Maybe<Scalars['Decimal']['output']>;
  requestedProductPriceId?: Maybe<Scalars['Int']['output']>;
  requestedProductSize?: Maybe<Scalars['Decimal']['output']>;
  requestedProductSizeType?: Maybe<Scalars['String']['output']>;
  status: OrderStatusId;
  subscription?: Maybe<MobileSubscription>;
  /** @deprecated Temporary hack, see API-166 */
  tempChangeDataPackageImmediately: Scalars['Boolean']['output'];
  /** @deprecated Temporary hack, see API-166 */
  tempMsisdn?: Maybe<Scalars['String']['output']>;
  /** @deprecated Temporary hack, see API-166 */
  tempSubscriptionName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Temporary hack, see API-166 */
  tempUserName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type OrderDetailConnection = {
  __typename: 'OrderDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderDetailEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<OrderDetail>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderDetailEdge = {
  __typename: 'OrderDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderDetail>;
};

export type OrderDetailFilterInput = {
  and?: InputMaybe<Array<OrderDetailFilterInput>>;
  batchId?: InputMaybe<StringFilterTypeFilterInput>;
  id?: InputMaybe<StringFilterTypeFilterInput>;
  or?: InputMaybe<Array<OrderDetailFilterInput>>;
  orderTypeId?: InputMaybe<ComparableOrderTypeOperationFilterInput>;
  registrationDateTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  status?: InputMaybe<ComparableOrderStatusIdOperationFilterInput>;
};

export type OrderDetailSortInput = {
  registrationDateTime?: InputMaybe<SortEnumType>;
};

export type OrderEvent = {
  __typename: 'OrderEvent';
  description?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  orderEventType: OrderEventTypeId;
  orderTypeId: Scalars['Int']['output'];
  requestedProductSize?: Maybe<Scalars['Decimal']['output']>;
  requestedProductSizeType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusChanged: Scalars['Boolean']['output'];
  statusId: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
};

/** A connection to a list of items. */
export type OrderEventConnection = {
  __typename: 'OrderEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderEventEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<OrderEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderEventEdge = {
  __typename: 'OrderEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderEvent>;
};

export enum OrderEventTypeId {
  Activated = 'ACTIVATED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Deactivated = 'DEACTIVATED',
  Declined = 'DECLINED',
  Ordered = 'ORDERED'
}

export type OrderPaymentCallbackError = ECommerceError | FieldRequiredError | InvalidArgumentError | InvalidVippsIdError | NotFoundOrNoAccessError | NotImplementedError;

export type OrderPaymentCallbackInput = {
  paymentId: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
};

export type OrderPaymentCallbackPayload = {
  __typename: 'OrderPaymentCallbackPayload';
  errors?: Maybe<Array<OrderPaymentCallbackError>>;
  paymentDetails?: Maybe<PaymentDetails>;
};

export type OrderSimCardInput = {
  address: SimCardAddressInput;
  cardType?: InputMaybe<SimCardType2>;
  delayActivation?: InputMaybe<Scalars['Boolean']['input']>;
  embeddedSim?: InputMaybe<Scalars['Boolean']['input']>;
  embeddedSimEmail?: InputMaybe<Scalars['String']['input']>;
  iccid?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  simWithoutPin?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrderStatusId {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pendingapproval = 'PENDINGAPPROVAL',
  Pendingdelivery = 'PENDINGDELIVERY',
  Registered = 'REGISTERED'
}

export enum OrderType {
  Activatesimcard = 'ACTIVATESIMCARD',
  Addbarringproduct = 'ADDBARRINGPRODUCT',
  Addcostcontrol = 'ADDCOSTCONTROL',
  Addcostsharinguser = 'ADDCOSTSHARINGUSER',
  Adddatarollover = 'ADDDATAROLLOVER',
  Adddatausagenotification = 'ADDDATAUSAGENOTIFICATION',
  Addextradatapackage = 'ADDEXTRADATAPACKAGE',
  Addfamilybucketsubscription = 'ADDFAMILYBUCKETSUBSCRIPTION',
  Addfamilybucketuser = 'ADDFAMILYBUCKETUSER',
  Addmbnuser = 'ADDMBNUSER',
  Addmobileinsurance = 'ADDMOBILEINSURANCE',
  Addmobilesubscription = 'ADDMOBILESUBSCRIPTION',
  Addroamlikeathomeextension = 'ADDROAMLIKEATHOMEEXTENSION',
  Addsimcard = 'ADDSIMCARD',
  Addunlimitedminutes = 'ADDUNLIMITEDMINUTES',
  Canceldeactivateservice = 'CANCELDEACTIVATESERVICE',
  Canceldeactivatesubscription = 'CANCELDEACTIVATESUBSCRIPTION',
  Cancelmovesubscription = 'CANCELMOVESUBSCRIPTION',
  Cancelreleasesubscription = 'CANCELRELEASESUBSCRIPTION',
  Changecostsharinguser = 'CHANGECOSTSHARINGUSER',
  Changedatapackage = 'CHANGEDATAPACKAGE',
  Changedirectoryinformation = 'CHANGEDIRECTORYINFORMATION',
  Changefamilybucketdata = 'CHANGEFAMILYBUCKETDATA',
  Changefamilybucketsubscription = 'CHANGEFAMILYBUCKETSUBSCRIPTION',
  Changemobilesubscriptionuser = 'CHANGEMOBILESUBSCRIPTIONUSER',
  Changeoffering = 'CHANGEOFFERING',
  Changesiptrunksettings = 'CHANGESIPTRUNKSETTINGS',
  Deactivatecostsharinguser = 'DEACTIVATECOSTSHARINGUSER',
  Deactivateservice = 'DEACTIVATESERVICE',
  Deactivatesubscription = 'DEACTIVATESUBSCRIPTION',
  Movesubscription = 'MOVESUBSCRIPTION',
  Releasesubscription = 'RELEASESUBSCRIPTION',
  Replacesimcard = 'REPLACESIMCARD',
  Setfamilybucketdatausagenotificationrecipients = 'SETFAMILYBUCKETDATAUSAGENOTIFICATIONRECIPIENTS',
  Updatealias = 'UPDATEALIAS',
  Updatereleasesubscription = 'UPDATERELEASESUBSCRIPTION',
  Useautopackage = 'USEAUTOPACKAGE'
}

export type OutdatedPriceError = Error & {
  __typename: 'OutdatedPriceError';
  message: Scalars['String']['output'];
  newPrice: Scalars['Decimal']['output'];
  productId: Scalars['ID']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PaidBy {
  Company = 'COMPANY',
  Private = 'PRIVATE'
}

export type PaymentDetails = {
  __typename: 'PaymentDetails';
  id: Scalars['ID']['output'];
  lastState?: Maybe<TransitionState>;
  order?: Maybe<OrderDetail>;
  orderID: Scalars['ID']['output'];
  /** @deprecated Use id instead */
  paymentID: Scalars['ID']['output'];
  product?: Maybe<ProductInfo>;
  productID: Scalars['ID']['output'];
  states?: Maybe<Array<Maybe<TransitionState>>>;
  subscriptionId: Scalars['Int']['output'];
};

export enum PaymentMethod {
  Mobile = 'MOBILE',
  Vipps = 'VIPPS'
}

export enum PaymentOperation {
  Cancelled = 'CANCELLED',
  Captured = 'CAPTURED',
  Initate = 'INITATE',
  Refund = 'REFUND',
  Reserve = 'RESERVE',
  Unknown = 'UNKNOWN',
  Unspecified = 'UNSPECIFIED',
  Void = 'VOID'
}

export enum PaymentStatus {
  Failed = 'FAILED',
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PartialRefund = 'PARTIAL_REFUND',
  Refund = 'REFUND'
}

export type PaymentStatusOperationFilterInput = {
  eq?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  neq?: InputMaybe<PaymentStatus>;
  nin?: InputMaybe<Array<PaymentStatus>>;
};

export type PcrfDataUsage = {
  __typename: 'PcrfDataUsage';
  boost?: Maybe<TimeGroup>;
  consumptionGroups?: Maybe<Array<Maybe<ConsumptionGroup>>>;
  domestic?: Maybe<DomesticGroup>;
  familyId?: Maybe<Scalars['String']['output']>;
  msisdn?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  rcc?: Maybe<RccGroup>;
  selected?: Maybe<ConsumptionGroup>;
  surfAbroad?: Maybe<Array<Maybe<SurfAbroadGroup>>>;
};

export type Person = {
  __typename: 'Person';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  orders?: Maybe<Array<OrderDetail>>;
  userName: Scalars['String']['output'];
};

export type PersonFilterInput = {
  active?: InputMaybe<BoolFilterTypeFilterInput>;
  and?: InputMaybe<Array<PersonFilterInput>>;
  id?: InputMaybe<StringFilterTypeFilterInput>;
  or?: InputMaybe<Array<PersonFilterInput>>;
  userName?: InputMaybe<StringFilterTypeFilterInput>;
};

export type PersonRole = {
  __typename: 'PersonRole';
  active: Scalars['Boolean']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDerived: Scalars['Boolean']['output'];
  person?: Maybe<Person>;
  roleType: PersonRoleType;
};

export enum PersonRoleType {
  Admin = 'ADMIN',
  Approver = 'APPROVER',
  Costsharing = 'COSTSHARING'
}

export type PersonalDirectoryEntry = IStreetAddress & {
  __typename: 'PersonalDirectoryEntry';
  firstName: Scalars['String']['output'];
  houseLetter?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  postalPlace?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
};

export type PersonalDirectoryInput = {
  address?: InputMaybe<StreetAddressInput>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
};

export type Porting = {
  __typename: 'Porting';
  expectedPortingDate?: Maybe<Scalars['DateTime']['output']>;
  newAlias?: Maybe<Scalars['String']['output']>;
  portingError?: Maybe<PortingError>;
  state: PortingState;
  type: PortingTypeEnum;
};

export enum PortingError {
  Error = 'ERROR',
  Missingapproval = 'MISSINGAPPROVAL'
}

export type PortingInSecondaryState = {
  __typename: 'PortingInSecondaryState';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  portingError?: Maybe<PortingError>;
  priority?: Maybe<Scalars['Int']['output']>;
  state: SecondaryState;
};

export enum PortingState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Ordered = 'ORDERED',
  Scheduled = 'SCHEDULED',
  Unauthorized = 'UNAUTHORIZED'
}

export enum PortingTypeEnum {
  Internalporting = 'INTERNALPORTING',
  Portingin = 'PORTINGIN',
  Userchange = 'USERCHANGE'
}

export type PreconditionNotMetError = Error & {
  __typename: 'PreconditionNotMetError';
  message: Scalars['String']['output'];
};

export type Price = {
  __typename: 'Price';
  /** @deprecated No longer supported. */
  amount?: Maybe<Money>;
  amountDecimal: Scalars['Decimal']['output'];
  amountDecimalIncVAT: Scalars['Decimal']['output'];
  amountDecimalVAT: Scalars['Decimal']['output'];
  /** @deprecated No longer supported. */
  amountIncVAT?: Maybe<Money>;
  /** @deprecated No longer supported. */
  amountVAT?: Maybe<Money>;
};

export type PriceFilterInput = {
  amount?: InputMaybe<MoneyFilterInput>;
  amountDecimal?: InputMaybe<ComparableDecimalOperationFilterInput>;
  amountDecimalIncVAT?: InputMaybe<ComparableDecimalOperationFilterInput>;
  amountDecimalVAT?: InputMaybe<ComparableDecimalOperationFilterInput>;
  amountIncVAT?: InputMaybe<MoneyFilterInput>;
  amountVAT?: InputMaybe<MoneyFilterInput>;
  and?: InputMaybe<Array<PriceFilterInput>>;
  or?: InputMaybe<Array<PriceFilterInput>>;
};

export type PrivateCost = {
  __typename: 'PrivateCost';
  mobileSubscriptionId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  receipt?: Maybe<Receipt>;
  receiptId?: Maybe<Scalars['String']['output']>;
  type: CostMapping;
  when: Scalars['DateTime']['output'];
};

export type ProductInfo = {
  __typename: 'ProductInfo';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum ProductSetId {
  Addsubscription = 'ADDSUBSCRIPTION',
  Atlasannulproductregistration = 'ATLASANNULPRODUCTREGISTRATION',
  Atlaseditnumberstructureproducts = 'ATLASEDITNUMBERSTRUCTUREPRODUCTS',
  Atlasproductmigration = 'ATLASPRODUCTMIGRATION',
  Bosschangesubscription = 'BOSSCHANGESUBSCRIPTION',
  Bossnewsubscription = 'BOSSNEWSUBSCRIPTION',
  Cataloginformationparentproduct = 'CATALOGINFORMATIONPARENTPRODUCT',
  Celladeactivateproduct = 'CELLADEACTIVATEPRODUCT',
  Cellaeditproduct = 'CELLAEDITPRODUCT',
  Cellaproductofferoverride = 'CELLAPRODUCTOFFEROVERRIDE',
  Changeadditionalproducsmyzone = 'CHANGEADDITIONALPRODUCSMYZONE',
  Changesubscription = 'CHANGESUBSCRIPTION',
  Customerweb = 'CUSTOMERWEB',
  Dinbedriftedit = 'DINBEDRIFTEDIT',
  Distributorweb = 'DISTRIBUTORWEB',
  Dittphoneroedit = 'DITTPHONEROEDIT',
  Establishmentfee = 'ESTABLISHMENTFEE',
  Express = 'EXPRESS',
  Groupingproduct = 'GROUPINGPRODUCT',
  Inactivecustomerignoreproducts = 'INACTIVECUSTOMERIGNOREPRODUCTS',
  James = 'JAMES',
  Legalresellerimportproducts = 'LEGALRESELLERIMPORTPRODUCTS',
  Legalresellersyncproducts = 'LEGALRESELLERSYNCPRODUCTS',
  Link = 'LINK',
  Linkprovider = 'LINKPROVIDER',
  Linkprovidercreateorder = 'LINKPROVIDERCREATEORDER',
  Linkproviderupgradetosubscription = 'LINKPROVIDERUPGRADETOSUBSCRIPTION',
  Linkshowfixed = 'LINKSHOWFIXED',
  Linkshowmobile = 'LINKSHOWMOBILE',
  Mbnproductsync = 'MBNPRODUCTSYNC',
  Midasaddproducts = 'MIDASADDPRODUCTS',
  Midaschangesubscription = 'MIDASCHANGESUBSCRIPTION',
  Midasdeactivateproduct = 'MIDASDEACTIVATEPRODUCT',
  Midasregularproducts = 'MIDASREGULARPRODUCTS',
  Midasupdatestate = 'MIDASUPDATESTATE',
  Numberaddressproduct = 'NUMBERADDRESSPRODUCT',
  Numberaddressproductnordicom = 'NUMBERADDRESSPRODUCTNORDICOM',
  Orderentry = 'ORDERENTRY',
  Ventelopublicweb = 'VENTELOPUBLICWEB',
  Webshop = 'WEBSHOP'
}

export enum ProductState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type ProductUnionType = AutoPackage | AutoPackageProduct | Barring | BarringProduct | CostControl | CostControlProduct | CostSharingUserProduct | CostSharingUserService | DataPackage | DataPackageProduct | DataRollover | DataRolloverProduct | DataUsageNotification | DataUsageNotificationProduct | ExtraDataPackage | ExtraDataPackageProduct | FamilyBucketData | FamilyBucketDataProduct | FamilyBucketUpgradeFee | FamilyBucketUpgradeFeeProduct | FamilyBucketUser | FamilyBucketUserProduct | GeneralProduct | HiddenProduct | MbnUser | MbnUserProduct | MobileInsurance | MobileInsuranceProduct | RoamLikeAtHomeExtension | RoamLikeAtHomeExtensionProduct | SimCard | SimCardOrder | SimCardProduct | SipTrunkCallForwardNumber | SipTrunkCallForwardProfile | SipTrunkNumberSeries | SubscriptionProduct | TrafficIncludedOnSubscription | TrafficIncludedOnSubscriptionProduct | UnlimitedMinutes | UnlimitedMinutesProduct;

export type ProfileRuleIdEnumOperationFilterInput = {
  eq?: InputMaybe<CostSharingProfileRuleType>;
  in?: InputMaybe<Array<CostSharingProfileRuleType>>;
  neq?: InputMaybe<CostSharingProfileRuleType>;
  nin?: InputMaybe<Array<CostSharingProfileRuleType>>;
};

/** A connection to a list of items. */
export type ProfilesConnection = {
  __typename: 'ProfilesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProfilesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CostSharingProfile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProfilesEdge = {
  __typename: 'ProfilesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CostSharingProfile>;
};

export enum ProviderKind {
  Vipps = 'VIPPS'
}

export type ProviderKindOperationFilterInput = {
  eq?: InputMaybe<ProviderKind>;
  in?: InputMaybe<Array<ProviderKind>>;
  neq?: InputMaybe<ProviderKind>;
  nin?: InputMaybe<Array<ProviderKind>>;
};

export type PurchaseExtraDataPackageError = FieldRequiredError | InvalidArgumentError | NotImplementedError | OutdatedPriceError;

export type PurchaseExtraDataPackageInput = {
  callbackUrl: Scalars['String']['input'];
  isApp: Scalars['Boolean']['input'];
  paymentMethod: PaymentMethod;
  price: Scalars['Decimal']['input'];
  productId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type PurchaseExtraDataPackagePayload = {
  __typename: 'PurchaseExtraDataPackagePayload';
  errors?: Maybe<Array<PurchaseExtraDataPackageError>>;
  purchaseRequest?: Maybe<PurchaseRequest>;
};

export type PurchaseRequest = {
  __typename: 'PurchaseRequest';
  orderID: Scalars['ID']['output'];
  paymentID: Scalars['ID']['output'];
  paymentMethod: PaymentMethod;
  paymentURI?: Maybe<Scalars['String']['output']>;
  productID: Scalars['ID']['output'];
};

export type Query = {
  __typename: 'Query';
  administratorsByCustomer?: Maybe<Array<Person>>;
  /** Gets the set of customers that belong to the same customer agreement as the given customer. */
  agreementMembersByCustomerId?: Maybe<FilteredCustomerLiteSet>;
  agreementsByCustomer?: Maybe<Array<Agreement>>;
  app?: Maybe<AppState>;
  approvals?: Maybe<ApprovalConnection>;
  /** Returns MbnSolutions related to subscriptions for the requested customer. These solutions are selectable for other subscriptions on the same customer. */
  availableMbnSolutionsForCustomer?: Maybe<Array<MbnSolution>>;
  checkVippsPayment?: Maybe<PaymentDetails>;
  contract?: Maybe<ContractUnionType>;
  contracts?: Maybe<ContractUnionTypeConnection>;
  contractsByCustomer?: Maybe<ContractUnionTypeConnection>;
  costSharingCountries: Array<CostSharingCountry>;
  costSharingProfile?: Maybe<CostSharingProfile>;
  countries?: Maybe<Array<Country>>;
  customer?: Maybe<Customer>;
  /** Returns a lite version of Customer with only the most basic information, but with less restrictions on access. */
  customerLite?: Maybe<CustomerLite>;
  customerProducts?: Maybe<Array<CustomerProduct>>;
  customerSet?: Maybe<CustomerSet>;
  customerTree?: Maybe<Array<Maybe<Customer>>>;
  customerTreeNode?: Maybe<Array<Maybe<StrictCustomerNode>>>;
  customers?: Maybe<Array<Customer>>;
  /** Returns a set of expenses for the specified period and with aggregated expense values. */
  expensesByPeriod?: Maybe<CostSharingExpensePeriode>;
  /** Returns a set of expenses with aggregated fields for expenses relevant to Bedrifts Portalen users. Including expenses for the current month if date is past ReportingDeadline. Including expenses for the two last month if date is prior to ReportingDeadline. */
  expensesForBedriftsPortalen?: Maybe<ExpenseSet>;
  familyBucketSubscription?: Maybe<FamilyBucketSubscription>;
  familyBucketSubscriptions?: Maybe<FamilyBucketSubscriptionConnection>;
  findCustomers?: Maybe<CustomerLiteConnection>;
  findCustomers2?: Maybe<CustomerConnection>;
  findMobileSubscriptions?: Maybe<MobileSubscriptionLiteConnection>;
  /** Cost Sharing is enabled on this mobile subscription */
  hasEmployee: Scalars['Boolean']['output'];
  hasFeatureAccess?: Maybe<Scalars['Boolean']['output']>;
  maintenanceMessages?: Maybe<Array<Maybe<MaintenanceMessage>>>;
  mbnSolutionsByCustomer?: Maybe<Array<MbnSolution>>;
  me: Me;
  mobileSubscription?: Maybe<MobileSubscription>;
  mobileSubscriptions?: Maybe<MobileSubscriptionConnection>;
  /** @deprecated Use GetMobileSubscriptionsByCustomer2 where input parameters are collected into one query class */
  mobileSubscriptionsByCustomer?: Maybe<MobileSubscriptionConnection>;
  /** Get subscriptions owned by customer (or sub customer) */
  mobileSubscriptionsByCustomer2?: Maybe<MobileSubscriptionConnection>;
  myOrders?: Maybe<OrderDetailConnection>;
  numberInformationForInternalPorting?: Maybe<NumberInformationForInternalPorting>;
  ordersByCustomer?: Maybe<OrderDetailConnection>;
  outstandingPayments: Array<PaymentDetails>;
  /** Returning orders paid by user */
  privateOrdersForSubscriptions?: Maybe<OrderDetailConnection>;
  reservedNumbersByCustomerId?: Maybe<ReservedNumberConnection>;
  sipTrunkSubscription?: Maybe<SipTrunkSubscription>;
  subscriptionProfile?: Maybe<SubscriptionProfile>;
  validateNumberForNewSubscription?: Maybe<ValidationResult>;
  warehouseSimCardByIccid?: Maybe<SimCard>;
  whoAmI?: Maybe<Scalars['String']['output']>;
  workdays?: Maybe<Array<Scalars['DateTime']['output']>>;
  zipCodes?: Maybe<Array<ZipCodeData>>;
};


export type QueryAdministratorsByCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryAgreementMembersByCustomerIdArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryAgreementsByCustomerArgs = {
  customerId: Scalars['ID']['input'];
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  validForSubscription?: InputMaybe<Scalars['String']['input']>;
};


export type QueryApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ApprovalSortInput>>;
  where?: InputMaybe<ApprovalFilterInput>;
};


export type QueryAvailableMbnSolutionsForCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCheckVippsPaymentArgs = {
  paymentId: Scalars['ID']['input'];
};


export type QueryContractArgs = {
  id: Scalars['ID']['input'];
  where?: InputMaybe<IContractFilterInput>;
};


export type QueryContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IContractFilterInput>;
};


export type QueryContractsByCustomerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contractTypes?: InputMaybe<Array<ApiContractType>>;
  customerId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  includeCustomers?: InputMaybe<Array<CustomerRelation>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IContractFilterInput>;
};


export type QueryCostSharingProfileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCountriesArgs = {
  where?: InputMaybe<CountryFilterInput>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerLiteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerProductsArgs = {
  customerId: Scalars['ID']['input'];
  filter?: InputMaybe<CustomerProductFilterInput>;
  order?: InputMaybe<Array<CustomerProductSortInput>>;
};


export type QueryCustomerSetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerTreeArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryCustomerTreeNodeArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryCustomersArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryExpensesByPeriodArgs = {
  input: CostSharingExpensesByPeriodInput;
  mobileSubscriptionId: Scalars['ID']['input'];
  order?: InputMaybe<Array<ExpensePeriodSortInput>>;
};


export type QueryExpensesForBedriftsPortalenArgs = {
  mobileSubscriptionId: Scalars['ID']['input'];
};


export type QueryFamilyBucketSubscriptionArgs = {
  id: Scalars['ID']['input'];
  where?: InputMaybe<FamilyBucketSubscriptionFilterInput>;
};


export type QueryFamilyBucketSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FamilyBucketSubscriptionFilterInput>;
};


export type QueryFindCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindCustomers2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindMobileSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MobileSubscriptionLiteSortInput>>;
  userQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHasEmployeeArgs = {
  mobileSubscriptionId: Scalars['ID']['input'];
};


export type QueryHasFeatureAccessArgs = {
  customerId: Scalars['ID']['input'];
  feature: Feature;
};


export type QueryMaintenanceMessagesArgs = {
  ignoreBefore?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryMbnSolutionsByCustomerArgs = {
  customerId: Scalars['ID']['input'];
  includeSubCustomers?: Scalars['Boolean']['input'];
};


export type QueryMobileSubscriptionArgs = {
  id: Scalars['ID']['input'];
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};


export type QueryMobileSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MobileSubscriptionSortInput>>;
  userQuery?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};


export type QueryMobileSubscriptionsByCustomerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contractTypes?: InputMaybe<Array<MobileSubscriptionContractType>>;
  customerId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  includeNewlyDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MobileSubscriptionSortInput>>;
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};


export type QueryMobileSubscriptionsByCustomer2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MobileSubscriptionSortInput>>;
  queryParams?: InputMaybe<MobileSubscriptionsByCustomerParamsInput>;
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};


export type QueryMyOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrderDetailSortInput>>;
  where?: InputMaybe<OrderDetailFilterInput>;
};


export type QueryNumberInformationForInternalPortingArgs = {
  customerId: Scalars['ID']['input'];
  number: Scalars['String']['input'];
};


export type QueryOrdersByCustomerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  includeSubCustomers?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderDetailFilterInput>;
};


export type QueryPrivateOrdersForSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrderDetailSortInput>>;
  where?: InputMaybe<OrderDetailFilterInput>;
};


export type QueryReservedNumbersByCustomerIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReservedNumberFilterInput>;
};


export type QuerySipTrunkSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryValidateNumberForNewSubscriptionArgs = {
  customerId: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWarehouseSimCardByIccidArgs = {
  customerId: Scalars['ID']['input'];
  iccid?: InputMaybe<Scalars['String']['input']>;
  simCardType?: InputMaybe<SimCardType2>;
};


export type QueryWorkdaysArgs = {
  fromMonth?: InputMaybe<Scalars['Int']['input']>;
  fromYear?: InputMaybe<Scalars['Int']['input']>;
  numberOfMonths?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryZipCodesArgs = {
  zipInput?: InputMaybe<ZipCodeDataInput>;
};

export type RccGroup = {
  __typename: 'RccGroup';
  activated?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  isSelected: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  unlimited: Scalars['Boolean']['output'];
  usage?: Maybe<RccUsage>;
  zones?: Maybe<Array<Maybe<WorldZoneGroup>>>;
};

export type RccUsage = {
  __typename: 'RccUsage';
  limitNOK: Scalars['Decimal']['output'];
  remainingNOK: Scalars['Decimal']['output'];
  reservedAmount: Scalars['Decimal']['output'];
  temporaryLimitNOK: Scalars['Decimal']['output'];
  usedNOK: Scalars['Decimal']['output'];
};

export type Receipt = {
  __typename: 'Receipt';
  /** Used as OrderId, ContractProductId of the 'invoice' contractProduct this purchase is associated with */
  contractProductId: Scalars['Int']['output'];
  current?: Maybe<Price>;
  id?: Maybe<Scalars['String']['output']>;
  kind: ProviderKind;
  msisdn?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Price>;
  /** A binary of this purchase as pdf */
  payload?: Maybe<Scalars['String']['output']>;
  pdfFile?: Maybe<ReceiptPdf>;
  productName?: Maybe<Scalars['String']['output']>;
  /** Date and time of purchase */
  purchased?: Maybe<Scalars['DateTime']['output']>;
  /** Sum of all refunds */
  refundTotal: Price;
  refunds?: Maybe<Array<Maybe<Refund>>>;
  status: PaymentStatus;
  subscriptionId: Scalars['Int']['output'];
};

/** A connection to a list of items. */
export type ReceiptConnection = {
  __typename: 'ReceiptConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ReceiptEdge>>;
  firstReceiptDate?: Maybe<Scalars['DateTime']['output']>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Receipt>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReceiptEdge = {
  __typename: 'ReceiptEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Receipt>;
};

export type ReceiptFilterInput = {
  and?: InputMaybe<Array<ReceiptFilterInput>>;
  /** Used as OrderId, ContractProductId of the 'invoice' contractProduct this purchase is associated with */
  contractProductId?: InputMaybe<ComparableInt32OperationFilterInput>;
  current?: InputMaybe<PriceFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  kind?: InputMaybe<ProviderKindOperationFilterInput>;
  or?: InputMaybe<Array<ReceiptFilterInput>>;
  original?: InputMaybe<PriceFilterInput>;
  /** A binary of this purchase as pdf */
  payload?: InputMaybe<StringOperationFilterInput>;
  productName?: InputMaybe<StringOperationFilterInput>;
  /** Date and time of purchase */
  purchased?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  /** Sum of all refunds */
  refundTotal?: InputMaybe<PriceFilterInput>;
  refunds?: InputMaybe<ListFilterInputTypeOfRefundFilterInput>;
  status?: InputMaybe<PaymentStatusOperationFilterInput>;
  subscriptionId?: InputMaybe<ComparableInt32OperationFilterInput>;
};

/** Receipt in pdf format */
export type ReceiptPdf = {
  __typename: 'ReceiptPdf';
  /** Mime-type */
  contentType?: Maybe<Scalars['String']['output']>;
  /** Base64-encoded string containing the binary file */
  data?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  /** Store as attachment or open in browser */
  isAttachment?: Maybe<Scalars['Boolean']['output']>;
};

export type Refund = {
  __typename: 'Refund';
  amount?: Maybe<Price>;
  kind: ProviderKind;
  status: PaymentStatus;
  when?: Maybe<Scalars['DateTime']['output']>;
};

export type RefundFilterInput = {
  amount?: InputMaybe<PriceFilterInput>;
  and?: InputMaybe<Array<RefundFilterInput>>;
  kind?: InputMaybe<ProviderKindOperationFilterInput>;
  or?: InputMaybe<Array<RefundFilterInput>>;
  status?: InputMaybe<PaymentStatusOperationFilterInput>;
  when?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type RejectOrderInput = {
  approvalId: Scalars['Int']['input'];
  responseMessage?: InputMaybe<Scalars['String']['input']>;
};

export type RelatedSubscription = {
  __typename: 'RelatedSubscription';
  customerId: Scalars['ID']['output'];
  customerPath: Scalars['String']['output'];
  establishmentPrice: Scalars['Decimal']['output'];
  price: Scalars['Decimal']['output'];
  subscription?: Maybe<MobileSubscription>;
  subscriptionId: Scalars['ID']['output'];
};


export type RelatedSubscriptionSubscriptionArgs = {
  where?: InputMaybe<MobileSubscriptionFilterInput>;
};

/** A connection to a list of items. */
export type RelatedSubscriptionConnection = {
  __typename: 'RelatedSubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RelatedSubscriptionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<RelatedSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelatedSubscriptionEdge = {
  __typename: 'RelatedSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RelatedSubscription>;
};

export type ReleaseNumbersInput = {
  numbers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReleaseSubscription = {
  __typename: 'ReleaseSubscription';
  approvalExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivateAfterExpirationDate: Scalars['Boolean']['output'];
  futureDeactivationDateTime?: Maybe<Scalars['DateTime']['output']>;
  status: ReleaseSubscriptionStatus;
  subscriptionId: Scalars['ID']['output'];
};

export type ReleaseSubscriptionCancellationInput = {
  subscriptionId: Scalars['ID']['input'];
};

export type ReleaseSubscriptionInput = {
  deactivateAfterPortingEnd?: InputMaybe<Scalars['Boolean']['input']>;
  portingEnd?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type ReleaseSubscriptionSecondaryState = {
  __typename: 'ReleaseSubscriptionSecondaryState';
  approvalExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivateAfterExpirationDate?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  futureDeactivationDateTime?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  state: SecondaryState;
};

export enum ReleaseSubscriptionStatus {
  Approved = 'APPROVED',
  Expired = 'EXPIRED',
  Pendingcancellationorder = 'PENDINGCANCELLATIONORDER',
  Pendingorder = 'PENDINGORDER',
  Portoutinprogress = 'PORTOUTINPROGRESS'
}

export type ResendEmbeddedSimCardEmailInput = {
  simCardContractProductId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};

export type ReservedNumber = {
  __typename: 'ReservedNumber';
  lockKey: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  numberValueType: NumberValueType;
  productPrice?: Maybe<Scalars['Decimal']['output']>;
  productPriceId?: Maybe<Scalars['Int']['output']>;
  source: LockNumberSource;
};

/** A connection to a list of items. */
export type ReservedNumberConnection = {
  __typename: 'ReservedNumberConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ReservedNumberEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ReservedNumber>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReservedNumberEdge = {
  __typename: 'ReservedNumberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReservedNumber>;
};

export type ReservedNumberFilterInput = {
  and?: InputMaybe<Array<ReservedNumberFilterInput>>;
  number?: InputMaybe<StringFilterTypeFilterInput>;
  or?: InputMaybe<Array<ReservedNumberFilterInput>>;
};

export type RoamLikeAtHomeExtension = IProduct & IService & {
  __typename: 'RoamLikeAtHomeExtension';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type RoamLikeAtHomeExtensionProduct = IProduct & {
  __typename: 'RoamLikeAtHomeExtensionProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type Roaming = {
  __typename: 'Roaming';
  /** Get roaming prices for a specific country. */
  roamingPricesByCountryName: RoamingPrices;
};


export type RoamingRoamingPricesByCountryNameArgs = {
  countryName: Scalars['String']['input'];
  includeVat?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoamingPrices = {
  __typename: 'RoamingPrices';
  /** Name of country where the phone is roaming */
  countryName?: Maybe<Scalars['String']['output']>;
  /** Data roaming price per MB */
  dataRoaming?: Maybe<TrafficPrice>;
  /** Price incoming MMS in local country */
  mmsIncomingInLocalCountry?: Maybe<TrafficPrice>;
  /** Price outgoing MMS to countries in EU */
  mmsOutgoingToEu?: Maybe<TrafficPrice>;
  /** Price outgoing MMS to home country, i.e. Norway */
  mmsOutgoingToHomeCountry?: Maybe<TrafficPrice>;
  /** Price outgoing MMS to local country */
  mmsOutgoingToLocalCountry?: Maybe<TrafficPrice>;
  /** Price outgoing MMS to nordic countries */
  mmsOutgoingToNordicCountry?: Maybe<TrafficPrice>;
  /** Price outgoing MMS to countries in rest of Europe */
  mmsOutgoingToRestOfEu?: Maybe<TrafficPrice>;
  /** Price outgoing MMS to countries in rest of world */
  mmsOutgoingToRestOfWorld?: Maybe<TrafficPrice>;
  /** Mobile Country Code (MMC) identifies the country where the phone is roaming */
  mobileCountryCode?: Maybe<Scalars['String']['output']>;
  /** Mobile Network Code (MNC) identifies the network in the given country where the phone is roaming */
  mobileNetworkCode?: Maybe<Scalars['String']['output']>;
  /** Price incoming SMS in local country */
  smsIncomingInLocalCountry?: Maybe<TrafficPrice>;
  /** Price outgoing SMS to countries in EU */
  smsOutgoingToEu?: Maybe<TrafficPrice>;
  /** Price outgoing SMS to home country, i.e. Norway */
  smsOutgoingToHomeCountry?: Maybe<TrafficPrice>;
  /** Price outgoing SMS in local country */
  smsOutgoingToLocalCountry?: Maybe<TrafficPrice>;
  /** Price outgoing SMS to nordic countries */
  smsOutgoingToNordicCountry?: Maybe<TrafficPrice>;
  /** Price outgoing SMS to countries in rest of Europe */
  smsOutgoingToRestOfEu?: Maybe<TrafficPrice>;
  /** Price outgoing SMS to countries in rest of world */
  smsOutgoingToRestOfWorld?: Maybe<TrafficPrice>;
  /** Price incoming voice in local country */
  voiceIncomingLocalCountry?: Maybe<TrafficPrice>;
  /** Price incoming voice startup in local country */
  voiceIncomingStartupLocalCountry?: Maybe<TrafficPrice>;
  /** Price outgoing voice startup in local country */
  voiceOutgoingStartupLocalCountry?: Maybe<TrafficPrice>;
  /** Price outgoing voice startup to countries in rest of world */
  voiceOutgoingStartupToRestOfWorld?: Maybe<TrafficPrice>;
  /** Price outgoing voice to countries in EU */
  voiceOutgoingToEu?: Maybe<TrafficPrice>;
  /** Price outgoing voice to home country, i.e. to Norway */
  voiceOutgoingToHomeCountry?: Maybe<TrafficPrice>;
  /** Price outgoing voice in local country */
  voiceOutgoingToLocalCountry?: Maybe<TrafficPrice>;
  /** Price outgoing voice to Nordic countries */
  voiceOutgoingToNordicCountry?: Maybe<TrafficPrice>;
  /** Price outgoing voice to countries in rest of Europe */
  voiceOutgoingToRestOfEu?: Maybe<TrafficPrice>;
  /** Price outgoing voice to countries in rest of world */
  voiceOutgoingToRestOfWorld?: Maybe<TrafficPrice>;
};

export enum SecondaryState {
  Activating = 'ACTIVATING',
  Deactivated = 'DEACTIVATED',
  Deactivating = 'DEACTIVATING',
  Missingapproval = 'MISSINGAPPROVAL',
  Movecompleted = 'MOVECOMPLETED',
  Moveordered = 'MOVEORDERED',
  Movescheduled = 'MOVESCHEDULED',
  Portincancelled = 'PORTINCANCELLED',
  Portincompleted = 'PORTINCOMPLETED',
  Portinerror = 'PORTINERROR',
  Portinordered = 'PORTINORDERED',
  Portinscheduled = 'PORTINSCHEDULED',
  Portoutinprogress = 'PORTOUTINPROGRESS',
  Subscriptionbarred = 'SUBSCRIPTIONBARRED',
  Subscriptionreleased = 'SUBSCRIPTIONRELEASED',
  Transmissionauthorized = 'TRANSMISSIONAUTHORIZED',
  Transmissioncompleted = 'TRANSMISSIONCOMPLETED',
  Transmissionunauthorized = 'TRANSMISSIONUNAUTHORIZED',
  Transmissionwaiting = 'TRANSMISSIONWAITING',
  Userchangescheduled = 'USERCHANGESCHEDULED'
}

export type SendResult = {
  __typename: 'SendResult';
  errorMessage?: Maybe<Scalars['String']['output']>;
  sent: Scalars['Boolean']['output'];
};

export type SimCard = IProduct & IService & {
  __typename: 'SimCard';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  customerId?: Maybe<Scalars['String']['output']>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  deviceInfo?: Maybe<DeviceInfo>;
  embeddedSim: Scalars['Boolean']['output'];
  embeddedSimAcQrCodeUrl?: Maybe<Scalars['String']['output']>;
  embeddedSimAcSmDpAddress?: Maybe<Scalars['String']['output']>;
  embeddedSimAcToken?: Maybe<Scalars['String']['output']>;
  embeddedSimEmail?: Maybe<Scalars['String']['output']>;
  embeddedSimIsReadyForActivation: Scalars['Boolean']['output'];
  embeddedSimReplacementPrice: Scalars['Decimal']['output'];
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  iccid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imsi?: Maybe<Scalars['String']['output']>;
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  msisdn?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentRegistrationDate?: Maybe<Scalars['String']['output']>;
  pin1?: Maybe<Scalars['String']['output']>;
  pin2?: Maybe<Scalars['String']['output']>;
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  puk1?: Maybe<Scalars['String']['output']>;
  puk2?: Maybe<Scalars['String']['output']>;
  replacementPrice?: Maybe<Scalars['Decimal']['output']>;
  replacementSimId?: Maybe<Scalars['Int']['output']>;
  simCardName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
  status?: Maybe<Scalars['Int']['output']>;
  type: SimCardType2;
  usage: Scalars['Float']['output'];
};

export type SimCardAddress = {
  __typename: 'SimCardAddress';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  zipCodeId: Scalars['Int']['output'];
};

export type SimCardAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  zipCodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type SimCardOrder = IProduct & {
  __typename: 'SimCardOrder';
  category: ApiProductCategory;
  confirmed?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  delayActivation: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  msisdn?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  type: SimCardType2;
};

export type SimCardProduct = IProduct & {
  __typename: 'SimCardProduct';
  cardType: SimCardType2;
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export enum SimCardType2 {
  Datacard = 'DATACARD',
  Subscription = 'SUBSCRIPTION',
  Twin = 'TWIN'
}

export type SipTrunkCallForwardNumber = IProduct & IService & {
  __typename: 'SipTrunkCallForwardNumber';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  fromDateTime: Scalars['DateTime']['output'];
  fromNumber?: Maybe<Scalars['String']['output']>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  source: CallForwardSource;
  state: ApiProductState;
  toDateTime?: Maybe<Scalars['DateTime']['output']>;
  toNumber?: Maybe<Scalars['String']['output']>;
  trigger: CallForwardTrigger;
};

export type SipTrunkCallForwardProfile = IProduct & IService & {
  __typename: 'SipTrunkCallForwardProfile';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  callForwardNumbers?: Maybe<Array<Maybe<SipTrunkCallForwardProfileNumber>>>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isCallForwardingActive: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type SipTrunkCallForwardProfileNumber = {
  __typename: 'SipTrunkCallForwardProfileNumber';
  fromNumber?: Maybe<Scalars['String']['output']>;
  isCallForwardingActive: Scalars['Boolean']['output'];
  mainNumber?: Maybe<Scalars['String']['output']>;
  toNumber?: Maybe<Scalars['String']['output']>;
};

export type SipTrunkContract = IContract & {
  __typename: 'SipTrunkContract';
  contractId: Scalars['Int']['output'];
  contractType: ApiContractType;
  customerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainContractProductId?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<SipTrunkSubscription>;
};

export type SipTrunkMbnNumber = {
  __typename: 'SipTrunkMbnNumber';
  /** ContractProductId for the SipTrunkMbnNumber with ProductId = 24520. */
  id?: Maybe<Scalars['ID']['output']>;
  /** List of connected MBN numbers in context of a SipTrunk. */
  number?: Maybe<Scalars['String']['output']>;
};

export type SipTrunkMbnSolution = {
  __typename: 'SipTrunkMbnSolution';
  /** ContractProductId for MbnSolution. */
  id: Scalars['ID']['output'];
  /** List of connected MBN phone numbers in context of a SipTrunk. */
  mbnNumbers?: Maybe<Array<Maybe<SipTrunkMbnNumber>>>;
  /** Count of connected MBN phone numbers in context of a SipTrunk. */
  mbnNumbersCount?: Maybe<Scalars['Int']['output']>;
  /** Name of Mbn solution. */
  name?: Maybe<Scalars['String']['output']>;
};

export type SipTrunkNumber = {
  __typename: 'SipTrunkNumber';
  /** Contains call forward information if call forwarding is enabled. Null if call forward isn't enabled. */
  callForward?: Maybe<SipTrunkNumberCallForward>;
  /** Customer Number Id. */
  id: Scalars['ID']['output'];
  /** Main number of this SipTrunkNumber. */
  mainNumber?: Maybe<Scalars['String']['output']>;
  /** List of profiles this number is a member of. */
  memberOfProfiles?: Maybe<Array<Maybe<SipTrunkNumberToProfile>>>;
  /** Number of this SipTrunkNumber. */
  number?: Maybe<Scalars['String']['output']>;
};

export type SipTrunkNumberCallForward = {
  __typename: 'SipTrunkNumberCallForward';
  /** Call forward from date time. */
  fromDateTime: Scalars['DateTime']['output'];
  /** Call forward id. This is the ContractProductId for the SipTrunkCallForwardNumber ContractProduct. */
  id: Scalars['ID']['output'];
  /** Call forward source. */
  source: CallForwardSource;
  /** Call forward to date time, this is an optional parameter. */
  toDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Call forward to number. */
  toNumber: Scalars['String']['output'];
  /** Call forward trigger. */
  trigger: CallForwardTrigger;
};

export type SipTrunkNumberCallForwardSortInput = {
  /** Call forward from date time. */
  fromDateTime?: InputMaybe<SortEnumType>;
  /** Call forward id. This is the ContractProductId for the SipTrunkCallForwardNumber ContractProduct. */
  id?: InputMaybe<SortEnumType>;
  /** Call forward source. */
  source?: InputMaybe<SortEnumType>;
  /** Call forward to date time, this is an optional parameter. */
  toDateTime?: InputMaybe<SortEnumType>;
  /** Call forward to number. */
  toNumber?: InputMaybe<SortEnumType>;
  /** Call forward trigger. */
  trigger?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type SipTrunkNumberConnection = {
  __typename: 'SipTrunkNumberConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SipTrunkNumberEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<SipTrunkNumber>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SipTrunkNumberEdge = {
  __typename: 'SipTrunkNumberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SipTrunkNumber>;
};

export type SipTrunkNumberFilterInput = {
  /** Filter by forward trigger, return all if array is empty. */
  callForwardSources?: InputMaybe<Array<CallForwardSource>>;
  /** Filter by forward source, return all if array is empty. */
  callForwardTriggers?: InputMaybe<Array<CallForwardTrigger>>;
  /** Filter by forward from date time, return all if null. */
  forwardFromDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by forward to date time, return all if null. */
  forwardToDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by forward to numbers, return all if array is empty. */
  forwardToNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter by main numbers, return all if array is empty. */
  mainNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter by numbers, return all if array is empty. */
  numbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SipTrunkNumberSeries = IProduct & IService & {
  __typename: 'SipTrunkNumberSeries';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  /** Get the main number for this number series. */
  mainNumber: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** Get ranges of numbers as strings for this number series. */
  numberSeries: Array<Maybe<Scalars['String']['output']>>;
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  /** Get ranges of numbers for this number series. */
  ranges: Array<Maybe<NumberSeriesRange>>;
  state: ApiProductState;
};

export type SipTrunkNumberSortInput = {
  /** Contains call forward information if call forwarding is enabled. Null if call forward isn't enabled. */
  callForward?: InputMaybe<SipTrunkNumberCallForwardSortInput>;
  /** Customer Number Id. */
  id?: InputMaybe<SortEnumType>;
  /** Main number of this SipTrunkNumber. */
  mainNumber?: InputMaybe<SortEnumType>;
  /** Number of this SipTrunkNumber. */
  number?: InputMaybe<SortEnumType>;
};

export type SipTrunkNumberToProfile = {
  __typename: 'SipTrunkNumberToProfile';
  /** Id (ContractProductId) of profile this number is a member of. */
  id: Scalars['Int']['output'];
  /** Name of profile this number is a member of. */
  profileName?: Maybe<Scalars['String']['output']>;
};

export type SipTrunkSubscription = IProduct & IService & {
  __typename: 'SipTrunkSubscription';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  /** The number of channels in the SIP trunk. */
  channels: Scalars['Int']['output'];
  contractCategory?: Maybe<OfferingContractCategory>;
  contractPriceType: Scalars['Short']['output'];
  contractPriceTypeId: Scalars['Short']['output'];
  contractType: ContractType;
  customer?: Maybe<Customer>;
  customerAgreementOfferingId: Scalars['ID']['output'];
  customerId: Scalars['ID']['output'];
  customerLite?: Maybe<CustomerLite>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  demo: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** The IP address of the SIP trunk. */
  ipAddress: Scalars['String']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  /** True if underlaying channels are delivered by Telia. */
  isTeliaSamband?: Maybe<Scalars['Boolean']['output']>;
  lastUpdated: Scalars['DateTime']['output'];
  /** List of Mbn solution that is connected to this SipTrunk. */
  mbnSolutions: Array<Maybe<SipTrunkMbnSolution>>;
  monthlyPrice: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  /** Search and filter numbers associated with the SIP trunk. The numbers include call forwarding and profile information. */
  numbers?: Maybe<SipTrunkNumberConnection>;
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  /** @deprecated Use name */
  productName?: Maybe<Scalars['String']['output']>;
  /** Returns a set of products present or available for the given subscription */
  products?: Maybe<Array<ProductUnionType>>;
  registrationDate?: Maybe<Scalars['String']['output']>;
  /** Sambandsnummer is the external identifier for this SIP trunk. */
  sambandsnummer?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};


export type SipTrunkSubscriptionNumbersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SipTrunkNumberFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SipTrunkNumberSortInput>>;
};


export type SipTrunkSubscriptionProductsArgs = {
  categories?: InputMaybe<Array<InputMaybe<ApiProductCategory>>>;
  order?: InputMaybe<Array<IProductSortInput>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  states?: InputMaybe<Array<InputMaybe<ApiProductState>>>;
};

/** A connection to a list of items. */
export type SolutionsConnection = {
  __typename: 'SolutionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SolutionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CostSharingSolution>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SolutionsEdge = {
  __typename: 'SolutionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CostSharingSolution>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Speed = {
  __typename: 'Speed';
  downSpeed: Scalars['Decimal']['output'];
  upSpeed: Scalars['Decimal']['output'];
};

export type StreetAddressInput = {
  houseLetter?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  streetName: Scalars['String']['input'];
};

export type StrictCustomerNode = {
  __typename: 'StrictCustomerNode';
  children?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Scalars['String']['output']>;
};

export type StringFilterTypeFilterInput = {
  and?: InputMaybe<Array<StringFilterTypeFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  or?: InputMaybe<Array<StringFilterTypeFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitBatchInput = {
  addBarring?: InputMaybe<Array<AddBarringInput>>;
  addCostControl?: InputMaybe<Array<AddCostControlInput>>;
  addCostSharingUser?: InputMaybe<Array<AddCostSharingUserInput>>;
  addDataRollover?: InputMaybe<Array<AddDataRolloverInput>>;
  addExtraDataPackage?: InputMaybe<Array<AddExtraDataPackageInput>>;
  addFamilyBucketUser?: InputMaybe<Array<AddFamilyBucketUserInput>>;
  addMbnUser?: InputMaybe<Array<AddMbnUserInput>>;
  addMobileInsurance?: InputMaybe<Array<AddMobileInsuranceInput>>;
  addRoamLikeAtHome?: InputMaybe<Array<InputMaybe<AddRoamLikeAtHomeExtensionInput>>>;
  addUnlimitedMinutes?: InputMaybe<Array<AddUnlimitedMinutesInput>>;
  changeCostSharingUser?: InputMaybe<Array<ChangeCostSharingUserInput>>;
  changeDataPackage?: InputMaybe<Array<ChangeDataPackageInput>>;
  deactivateCostSharingUser?: InputMaybe<Array<InputMaybe<DeactivateCostSharingUserInput>>>;
  deactivateProduct?: InputMaybe<DeactivateProductInput>;
  deactivateService?: InputMaybe<Array<DeactivateServiceInput>>;
};

export type SubmitMobileOrderInput = {
  deactivateSubscriptionCancellationInput?: InputMaybe<DeactivateSubscriptionCancellationInput>;
  deactivateSubscriptionInput?: InputMaybe<DeactivateSubscriptionInput>;
  directoryInformationChange?: InputMaybe<ChangeDirectoryInformationInput>;
  releaseSubscriptionCancellationInput?: InputMaybe<ReleaseSubscriptionCancellationInput>;
  releaseSubscriptionInput?: InputMaybe<ReleaseSubscriptionInput>;
};

export type SubscriptionCostSet = {
  __typename: 'SubscriptionCostSet';
  /** Returns costs */
  costs?: Maybe<Array<Maybe<CompanyCost>>>;
  /** Returns costs */
  costsIncludingPrivate?: Maybe<Array<Maybe<ICost>>>;
  customerIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  customerSet?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  from: Scalars['DateTime']['output'];
  mappingType: CostMapping;
  paidBy?: Maybe<Array<PaidBy>>;
  subscriptionId: Scalars['Int']['output'];
  /** Sum of costs */
  sum?: Maybe<Price>;
  to: Scalars['DateTime']['output'];
};

export type SubscriptionCostSetInterval = {
  __typename: 'SubscriptionCostSetInterval';
  costSets?: Maybe<Array<Maybe<SubscriptionCostSet>>>;
  customerId?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['Int']['output'];
  whens?: Maybe<Array<Scalars['DateTime']['output']>>;
};

export type SubscriptionProduct = IProduct & IService & {
  __typename: 'SubscriptionProduct';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type SubscriptionProfile = {
  __typename: 'SubscriptionProfile';
  contentServices?: Maybe<CostSharingContentServices>;
  description: Scalars['String']['output'];
  expenses: SubscriptionProfileExpenses;
  extraDataDomestic: CostSharingExtraData;
  extraDataInternational: CostSharingExtraData;
  /** Id is a composite key from SubscriptionId and ProfileId */
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  /** Whether or not employees are disallowed reporting on content services expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyContentServicesExpenses: Scalars['Boolean']['output'];
  /** Whether or not employees are disallowed reporting on extradata expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyExtraDataExpenses: Scalars['Boolean']['output'];
  /** Whether or not employees are disallowed reporting on traffic expenses (work or private, employer pays or employee pays). I.e. TRUE means that employees CANNOT claim as 'work expense', FALSE means the employee CAN make such claims. */
  readOnlyTrafficExpenses: Scalars['Boolean']['output'];
  subscriptionId: Scalars['Int']['output'];
  trafficRules?: Maybe<CostSharingTrafficRules>;
};

export type SubscriptionProfileExpenses = {
  __typename: 'SubscriptionProfileExpenses';
  /** Content services so far this year */
  contentServices: Scalars['Decimal']['output'];
  /** By how much content services exceeds the limit so far this year (zero, or positive). */
  contentServicesAboveLimit: Scalars['Decimal']['output'];
  /** Extra data packages for domestic use, so far this month */
  extraDataDomestic: Scalars['Decimal']['output'];
  /** By how much extra data packages for domestic use exceeds the limit so far this month (zero, or positive) */
  extraDataDomesticAboveLimit: Scalars['Decimal']['output'];
  /** Extra data packages for international use, so far this month */
  extraDataInternational: Scalars['Decimal']['output'];
  /** By how much extra data packages for international use exceeds the limit so far this month (zero, or positive) */
  extraDataInternationalAboveLimit: Scalars['Decimal']['output'];
  /** Free amount that is covered by company, when none of the above rules applies, so far this month. */
  freeAmount: Scalars['Decimal']['output'];
  /** By how much Free amount exceeds the limit, so far this month or year (zero, or positive). */
  freeAmountAboveLimit: Scalars['Decimal']['output'];
  /** Traffic to foreign countries, so far this month. */
  trafficToAbroad: Scalars['Decimal']['output'];
  /** By how much traffic to foreign countries exceeds the limit so far this month (zero, or positive). */
  trafficToAbroadAboveLimit: Scalars['Decimal']['output'];
  /** Traffic to or from (roaming) foreign county (always covered by company), so far this month */
  trafficToOrFromCountries: Scalars['Decimal']['output'];
  /** Traffic when roaming (when in foreign counties), so far this month. */
  trafficWhenAbroad: Scalars['Decimal']['output'];
  /** By how much traffic when abroad exceeds the limit so far this month (zero, or positive). */
  trafficWhenAbroadAboveLimit: Scalars['Decimal']['output'];
  /** Traffic when roaming or in foreign countries, so far this month */
  trafficWhenOrToAbroad: Scalars['Decimal']['output'];
  /** By how much traffic when roaming in or calling to foreign countries exceeds the limit so far this month (zero, or positive). */
  trafficWhenOrToAbroadAboveLimit: Scalars['Decimal']['output'];
};

export type SubscriptionStatusSecondaryState = {
  __typename: 'SubscriptionStatusSecondaryState';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  state: SecondaryState;
};

export type SurfAbroadGroup = {
  __typename: 'SurfAbroadGroup';
  activated?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  isQueued: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  usage?: Maybe<ByteUsage>;
  zone: SurfAbroadZone;
};

export enum SurfAbroadZone {
  Africa = 'AFRICA',
  America = 'AMERICA',
  Asia_1 = 'ASIA_1',
  Asia_2 = 'ASIA_2',
  Europe = 'EUROPE',
  EuUsaSwi = 'EU_USA_SWI',
  Oceania = 'OCEANIA',
  Tampnet = 'TAMPNET',
  Usa = 'USA',
  World = 'WORLD'
}

export type ThrottleSpeed = {
  __typename: 'ThrottleSpeed';
  /** Returns the speed numerically as kb */
  kB: Scalars['Long']['output'];
  /** Returns the speed numerically as Mbit */
  mbit: Scalars['Long']['output'];
  /** Returns the speed in a readable form appended with KB or MBit depending on if the speed is >= 1000kb */
  toString: Scalars['String']['output'];
};

export type Throttling = {
  __typename: 'Throttling';
  /** If subscription is throttled */
  active: Scalars['Boolean']['output'];
  /** Down speed if subscription is throttled */
  down: ThrottleSpeed;
  /** up speed if subscription is throttled */
  up: ThrottleSpeed;
};

export type TimeGroup = {
  __typename: 'TimeGroup';
  activated?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  isSelected: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type TrafficIncludedOnSubscription = IProduct & IService & {
  __typename: 'TrafficIncludedOnSubscription';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type TrafficIncludedOnSubscriptionProduct = IProduct & {
  __typename: 'TrafficIncludedOnSubscriptionProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type TrafficPrice = {
  __typename: 'TrafficPrice';
  /** True if prices are including VAT */
  incVat: Scalars['Boolean']['output'];
  /** The price for the traffic unit */
  price: Scalars['Decimal']['output'];
  /** The unit for the price */
  unit: TrafficPriceUnit;
};

export enum TrafficPriceUnit {
  Mb = 'MB',
  Messge = 'MESSGE',
  Minute = 'MINUTE',
  Startup = 'STARTUP'
}

export type TransitionState = {
  __typename: 'TransitionState';
  amount: Scalars['Int']['output'];
  operation: PaymentOperation;
  rawOperation?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  time: Scalars['DateTime']['output'];
};

export type UnlimitedMinutes = IProduct & IService & {
  __typename: 'UnlimitedMinutes';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  category: ApiProductCategory;
  contractModelKey?: Maybe<ContractProductModelDataLoaderKey>;
  deactivationDate?: Maybe<Scalars['DateTime']['output']>;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  futureDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  state: ApiProductState;
};

export type UnlimitedMinutesProduct = IProduct & {
  __typename: 'UnlimitedMinutesProduct';
  category: ApiProductCategory;
  deactivationRule?: Maybe<Deactivation>;
  description?: Maybe<Scalars['String']['output']>;
  establishmentPrice?: Maybe<Scalars['Decimal']['output']>;
  excludedBy?: Maybe<Array<Maybe<IProduct>>>;
  excludedByProductIds?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  productId: Scalars['Int']['output'];
  state: ApiProductState;
  subscriptionId: Scalars['Int']['output'];
};

export type UpdateSimCardInput = {
  contractProductId: Scalars['Int']['input'];
  embeddedSimEmail?: InputMaybe<Scalars['String']['input']>;
  newName?: InputMaybe<Scalars['String']['input']>;
};

export type Usage = {
  __typename: 'Usage';
  included: Scalars['Boolean']['output'];
  volumeMb: Scalars['Decimal']['output'];
};

export type UsageSet = {
  __typename: 'UsageSet';
  entries?: Maybe<Array<Maybe<Usage>>>;
  sumAbove: Scalars['Decimal']['output'];
  sumBelow: Scalars['Decimal']['output'];
  total: Scalars['Decimal']['output'];
  when: Scalars['DateTime']['output'];
};

export type UsageSetMobileSubscription = {
  __typename: 'UsageSetMobileSubscription';
  customerId?: Maybe<Scalars['String']['output']>;
  customerIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  mobileSubscription?: Maybe<MobileSubscription>;
  mobileSubscriptionId: Scalars['Int']['output'];
  number?: Maybe<Scalars['String']['output']>;
  total: Scalars['Decimal']['output'];
  totalExcluded: Scalars['Decimal']['output'];
  totalIncluded: Scalars['Decimal']['output'];
};

export type UseAutoPackageInput = {
  productId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type ValidationResult = {
  __typename: 'ValidationResult';
  errorMessage?: Maybe<Scalars['String']['output']>;
  msisdn?: Maybe<Scalars['String']['output']>;
  requireInternalPorting: Scalars['Boolean']['output'];
  requirePorting: Scalars['Boolean']['output'];
  valid: Scalars['Boolean']['output'];
};

export type WorldZoneGroup = {
  __typename: 'WorldZoneGroup';
  isBarred: Scalars['Boolean']['output'];
  overusage?: Maybe<ByteUsage>;
  zone: BarringZone;
};

export type ZipCodeData = {
  __typename: 'ZipCodeData';
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  countryName?: Maybe<Scalars['String']['output']>;
  countyCode: Scalars['String']['output'];
  countyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  zipCode: Scalars['String']['output'];
};

export type ZipCodeDataInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** This CostSharingProfile represent a ContractProduct instance as known in SubscriptionService. This service object is used for billing and structure in Boss database. This service object only provides a limited set of parameters. A twin object with complete profile features is available in CostSharing as CostSharingProfile */
export type Subscription_CostSharingProfile = {
  __typename: 'subscription_CostSharingProfile';
  /** This is the primary key on CostSharingProfile, as defined in Subscription and Boss database. */
  contractProductId: Scalars['ID']['output'];
  /** Represents ContractProductId for Services */
  id: Scalars['ID']['output'];
  profileName: Scalars['String']['output'];
  /** This is the primary key on CostSharingProfile, as defined in CostSharing and portal database. */
  taxAndSalaryProfileId: Scalars['ID']['output'];
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CancelPaymentError": [
      "ECommerceError",
      "FieldRequiredError",
      "InvalidArgumentError",
      "InvalidVippsIDError",
      "NotFoundOrNoAccessError",
      "PreconditionNotMetError"
    ],
    "ContractUnionType": [
      "CostSharingContract",
      "FamilyBucketContract",
      "GeneralContract",
      "MbnNordicomContract",
      "MobileBroadbandContract",
      "MobileContract",
      "SipTrunkContract"
    ],
    "Error": [
      "ECommerceError",
      "FieldRequiredError",
      "InvalidArgumentError",
      "InvalidVippsIDError",
      "NotFoundOrNoAccessError",
      "NotImplementedError",
      "OutdatedPriceError",
      "PreconditionNotMetError"
    ],
    "GenericSecondaryState": [
      "InternalPortingSecondaryState",
      "MoveSecondaryState",
      "PortingInSecondaryState",
      "ReleaseSubscriptionSecondaryState",
      "SubscriptionStatusSecondaryState"
    ],
    "IContract": [
      "CostSharingContract",
      "FamilyBucketContract",
      "GeneralContract",
      "MbnNordicomContract",
      "MobileBroadbandContract",
      "MobileContract",
      "SipTrunkContract"
    ],
    "ICost": [
      "CompanyCost",
      "PrivateCost"
    ],
    "IExpenseSet": [
      "CostSharingExpensePeriode",
      "ExpenseSet"
    ],
    "IOfferingProduct": [
      "AutoPackageOfferingProduct",
      "DataPackageOfferingProduct",
      "ExtraDataPackageOfferingProduct",
      "FamilyBucketDataOfferingProduct",
      "GeneralOfferingProduct"
    ],
    "IProduct": [
      "AutoPackage",
      "AutoPackageOfferingProduct",
      "AutoPackageProduct",
      "Barring",
      "BarringProduct",
      "CostControl",
      "CostControlProduct",
      "CostSharingSubscription",
      "CostSharingUserProduct",
      "CostSharingUserService",
      "DataPackage",
      "DataPackageOfferingProduct",
      "DataPackageProduct",
      "DataRollover",
      "DataRolloverProduct",
      "DataUsageNotification",
      "DataUsageNotificationProduct",
      "ExtraDataPackage",
      "ExtraDataPackageOfferingProduct",
      "ExtraDataPackageProduct",
      "FamilyBucketData",
      "FamilyBucketDataOfferingProduct",
      "FamilyBucketDataProduct",
      "FamilyBucketSubscription",
      "FamilyBucketUpgradeFee",
      "FamilyBucketUpgradeFeeProduct",
      "FamilyBucketUser",
      "FamilyBucketUserProduct",
      "GeneralOfferingProduct",
      "GeneralProduct",
      "HiddenProduct",
      "MbnUser",
      "MbnUserProduct",
      "MobileInsurance",
      "MobileInsuranceProduct",
      "MobileSubscription",
      "RoamLikeAtHomeExtension",
      "RoamLikeAtHomeExtensionProduct",
      "SimCard",
      "SimCardOrder",
      "SimCardProduct",
      "SipTrunkCallForwardNumber",
      "SipTrunkCallForwardProfile",
      "SipTrunkNumberSeries",
      "SipTrunkSubscription",
      "SubscriptionProduct",
      "TrafficIncludedOnSubscription",
      "TrafficIncludedOnSubscriptionProduct",
      "UnlimitedMinutes",
      "UnlimitedMinutesProduct"
    ],
    "IService": [
      "AutoPackage",
      "Barring",
      "CostControl",
      "CostSharingSubscription",
      "CostSharingUserService",
      "DataPackage",
      "DataRollover",
      "DataUsageNotification",
      "ExtraDataPackage",
      "FamilyBucketData",
      "FamilyBucketSubscription",
      "FamilyBucketUpgradeFee",
      "FamilyBucketUser",
      "MbnUser",
      "MobileInsurance",
      "MobileSubscription",
      "RoamLikeAtHomeExtension",
      "SimCard",
      "SipTrunkCallForwardNumber",
      "SipTrunkCallForwardProfile",
      "SipTrunkNumberSeries",
      "SipTrunkSubscription",
      "SubscriptionProduct",
      "TrafficIncludedOnSubscription",
      "UnlimitedMinutes"
    ],
    "IStreetAddress": [
      "CompanyDirectoryEntry",
      "PersonalDirectoryEntry"
    ],
    "OfferingProduct": [
      "AutoPackageOfferingProduct",
      "DataPackageOfferingProduct",
      "ExtraDataPackageOfferingProduct",
      "FamilyBucketDataOfferingProduct",
      "GeneralOfferingProduct"
    ],
    "OrderPaymentCallbackError": [
      "ECommerceError",
      "FieldRequiredError",
      "InvalidArgumentError",
      "InvalidVippsIDError",
      "NotFoundOrNoAccessError",
      "NotImplementedError"
    ],
    "ProductUnionType": [
      "AutoPackage",
      "AutoPackageProduct",
      "Barring",
      "BarringProduct",
      "CostControl",
      "CostControlProduct",
      "CostSharingUserProduct",
      "CostSharingUserService",
      "DataPackage",
      "DataPackageProduct",
      "DataRollover",
      "DataRolloverProduct",
      "DataUsageNotification",
      "DataUsageNotificationProduct",
      "ExtraDataPackage",
      "ExtraDataPackageProduct",
      "FamilyBucketData",
      "FamilyBucketDataProduct",
      "FamilyBucketUpgradeFee",
      "FamilyBucketUpgradeFeeProduct",
      "FamilyBucketUser",
      "FamilyBucketUserProduct",
      "GeneralProduct",
      "HiddenProduct",
      "MbnUser",
      "MbnUserProduct",
      "MobileInsurance",
      "MobileInsuranceProduct",
      "RoamLikeAtHomeExtension",
      "RoamLikeAtHomeExtensionProduct",
      "SimCard",
      "SimCardOrder",
      "SimCardProduct",
      "SipTrunkCallForwardNumber",
      "SipTrunkCallForwardProfile",
      "SipTrunkNumberSeries",
      "SubscriptionProduct",
      "TrafficIncludedOnSubscription",
      "TrafficIncludedOnSubscriptionProduct",
      "UnlimitedMinutes",
      "UnlimitedMinutesProduct"
    ],
    "PurchaseExtraDataPackageError": [
      "FieldRequiredError",
      "InvalidArgumentError",
      "NotImplementedError",
      "OutdatedPriceError"
    ]
  }
};
      export default result;
    